import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useModal } from 'stores/globalModal';
import { Flow } from 'stores/flows';
import { EmptyState } from 'components';
import { createFlowGlobalModalProps } from '../../../modals';

interface FlowTableEmptyStateProps {
  onCreateFlow: (flow: Flow) => void;
}

export const testIds = {
  wrapper: 'flow-table-empty-state',
  button: 'flow-table-empty-state-button',
};

export const FlowTableEmptyState = ({ onCreateFlow }: FlowTableEmptyStateProps) => {
  const { t } = useTranslation();
  const { open } = useModal();

  const onClickCreateFlow = () => open(createFlowGlobalModalProps({ onCreate: onCreateFlow }));

  return (
    <EmptyState
      title={t('flowsPage.table.empty.title')}
      message={t('flowsPage.table.empty.message')}
      py='xl'
      data-testid={testIds.wrapper}
    >
      <Button onClick={onClickCreateFlow} data-testid={testIds.button}>
        {t('flowsPage.table.empty.button')}
      </Button>
    </EmptyState>
  );
};
