import { ReactNode } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
  AppLayout,
  ErrorLayout,
  FlowLayout,
  FlowsLayout,
  MainLayout,
  WizardLayout,
  WizardStep,
  wizardSteps,
} from 'layouts';
import {
  FlowPage,
  FlowSettingsPage,
  FlowsPage,
  LabelingPage,
  PoliciesPage,
  PolicyPage,
  ReportedEventsPage,
  SkeletonPage,
  UserPage,
  UsersPage,
  GroupsPage,
  GroupPage,
} from 'pages';
import { SpyProvider } from 'services/espionage';
import { UserMgmtLayout } from 'layouts/UserMgmtLayout/UserMgmtLayout';
import { TrainingLayout } from 'layouts/TrainingLayout/TrainingLayout';
import { AiPlatformLayout } from 'layouts/AiPlatformLayout/AiPlatformLayout';
import { TrainingPage, TrainingPipelinePage } from 'pages/aiPlatform';
import { DataGenerationLayout } from 'layouts/DataGenerationLayout/DataGenerationLayout';
import { DataGenerationPage } from 'pages/aiPlatform/DataGenerationPage/DataGenerationPage';
import { ROUTES } from './routes.config';

const WIZARD_STEP = (step: WizardStep) => ROUTES.WIZARD_STEP(undefined, undefined, step);

const wizardPages: Partial<Record<WizardStep, ReactNode>> = {
  [WizardStep.SETTINGS]: <FlowSettingsPage />,
  [WizardStep.LABELING]: <LabelingPage />,
  [WizardStep.EVENTS]: <ReportedEventsPage />,
};

export const protectedRouter = createBrowserRouter([
  {
    element: <SpyProvider />,
    children: [
      {
        element: <AppLayout />,
        errorElement: <ErrorLayout />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: ROUTES.CUSTOMERS,
                element: <SkeletonPage />,
              },
            ],
          },
          {
            element: <FlowsLayout key={ROUTES.FLOWS()} />,
            children: [
              {
                element: <MainLayout />,
                children: [
                  {
                    path: ROUTES.FLOWS(),
                    element: <FlowsPage />,
                  },
                ],
              },
              {
                element: <FlowLayout />,
                children: [
                  {
                    element: <MainLayout />,
                    children: [
                      { path: ROUTES.FLOW(), element: <FlowPage /> },
                      {
                        path: ROUTES.WIZARD(),
                        element: <WizardLayout />,
                        children: [
                          ...wizardSteps.map(({ step }) => ({
                            path: WIZARD_STEP(step),
                            element: wizardPages[step],
                          })),
                          { path: '*', element: <Navigate to={ROUTES.FLOW()} /> },
                        ],
                      },
                      {
                        element: <AiPlatformLayout />,
                        children: [
                          {
                            element: <TrainingLayout />,
                            children: [
                              {
                                path: ROUTES.AI_PLATFORM().TRAINING,
                                element: <TrainingPage />,
                              },
                              {
                                path: ROUTES.AI_PLATFORM().TRAINING_PIPELINE(),
                                element: <TrainingPipelinePage />,
                              },
                            ],
                          },
                          {
                            element: <DataGenerationLayout />,
                            children: [
                              {
                                path: ROUTES.AI_PLATFORM().DATA_GENERATION,
                                element: <DataGenerationPage />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            element: <UserMgmtLayout />,
            children: [
              {
                path: ROUTES.USER_MGMT().USERS,
                element: <UsersPage />,
              },
              {
                path: ROUTES.USER_MGMT().USER(),
                element: <UserPage />,
              },
              {
                path: ROUTES.USER_MGMT().GROUPS,
                element: <GroupsPage />,
              },
              {
                path: ROUTES.USER_MGMT().GROUP(),
                element: <GroupPage />,
              },
              {
                path: ROUTES.USER_MGMT().POLICIES,
                element: <PoliciesPage />,
              },
              {
                path: ROUTES.USER_MGMT().POLICY(),
                element: <PolicyPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: ROUTES.ROOT, element: <Navigate to={ROUTES.CUSTOMERS} /> },
  { path: '*', element: <Navigate to={ROUTES.ROOT} /> },
]);
