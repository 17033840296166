import i18n from 'services/i18n';
import { ValidationOopsie } from '../wizard.types';
import { EventDefType, EventDefValidation, ReportedEventDef, ReportedEventsData } from './eventDefs.types';
import { Label, LabelId } from '../labels';
import { isSelectionType } from './eventDefs.utils';

/** Validate that all events have names */
function validateNoMissingEventNames(events: ReportedEventDef[], labels: Record<LabelId, Label>): ValidationOopsie[] {
  return Object.values(labels).reduce<ValidationOopsie[]>((acc, label) => {
    const eventsWithNoName = events.filter((event) => event.labelId === label.id && !event.name);
    const count = eventsWithNoName.length;
    if (count)
      acc.push({
        text: i18n.t('wizard.steps.reportedEvents.validations.eventsMissingNames', { count, label: label.name }),
        queryParams: { labelId: label.id },
      });
    return acc;
  }, []);
}

/** Validate that no events have the same name, within a label */
function validateNoDuplicateNamesWithinLabel(
  events: ReportedEventDef[],
  labels: Record<LabelId, Label>,
): ValidationOopsie[] {
  const labelIds = Object.keys(labels);
  const labelToEventNameSetMap = labelIds.reduce<Record<LabelId, Set<string>>>((acc, labelId) => {
    acc[labelId] = new Set();
    return acc;
  }, {});

  const errors = new Map<string, ValidationOopsie>();

  events.forEach(({ name, labelId }) => {
    if (!name) return; // empty names are handled elsewhere
    const nameSet = labelToEventNameSetMap[labelId];
    const nameExists = nameSet?.has(name);
    if (!nameExists) nameSet?.add(name);
    else {
      const labelName = labels[labelId].name;
      const text = i18n.t('wizard.steps.reportedEvents.validations.duplicateNameInLabel', { name, label: labelName });
      errors.set(text, { text, queryParams: { labelId } });
    }
  });

  return Array.from(errors.values());
}

function getSynonymToNameMap(events: { name: string; synonyms?: string[] }[]) {
  return events.reduce<Record<string, Set<string>>>((acc, event) => {
    if (!event.synonyms?.length) return acc;
    event.synonyms.forEach((synonym) => {
      const lowerCaseSynonym = synonym.toLowerCase();
      acc[lowerCaseSynonym] = acc[lowerCaseSynonym] ?? new Set();
      acc[lowerCaseSynonym].add(event.name);
    });
    return acc;
  }, {});
}

function findDuplicateSynonymsWithEventNames(events: ReportedEventDef[]) {
  const synonymsToEventMap = getSynonymToNameMap(events);
  return Object.entries(synonymsToEventMap).filter(([_, eventNames]) => eventNames.size > 1);
}

function getDuplicateSynonymAcrossEventsError(
  synonym: string,
  eventNames: string[],
  labelId: LabelId,
): ValidationOopsie {
  const concatenatedNames = eventNames.join(', ');
  return {
    text: i18n.t('wizard.steps.reportedEvents.validations.duplicateSynonymAcrossEvents', {
      synonym,
      names: concatenatedNames,
    }),
    queryParams: { labelId },
  };
}

/** Validate that no event has duplicate synonyms, within a label */
function validateNoDuplicateSynonymsWithinLabel(
  events: ReportedEventDef[],
  labels: Record<LabelId, Label>,
): ValidationOopsie[] {
  const labelIds = Object.keys(labels);
  return labelIds.reduce<ValidationOopsie[]>((acc, labelId) => {
    const labelEvents = events.filter((event) => event.labelId === labelId);
    const synonymsWithMoreThanOneEvent = findDuplicateSynonymsWithEventNames(labelEvents);
    const errors = synonymsWithMoreThanOneEvent.map(([synonym, eventNames]) =>
      getDuplicateSynonymAcrossEventsError(synonym, Array.from(eventNames), labelId),
    );
    return acc.concat(errors);
  }, []);
}

function validateSelectionEventsMinimumValues(event: ReportedEventDef): ValidationOopsie[] {
  if (!isSelectionType(event.valueType)) return [];
  const hasSufficientValues = (event.values ?? []).length >= 2;
  if (hasSufficientValues) return [];
  return [
    {
      text: i18n.t('wizard.steps.reportedEvents.validations.noMinimumValues', { name: event.name }),
      queryParams: { labelId: event.labelId, eventDefId: event.id },
    },
  ];
}

function validateNoDuplicateValueNames(event: ReportedEventDef): ValidationOopsie[] {
  if (!isSelectionType(event.valueType)) return [];
  const valueNames = (event.values ?? []).map((value) => value.name);
  const valueCount = getStringCount(valueNames);
  const duplicateValueNames = Object.entries(valueCount)
    .filter(([_, count]) => count > 1)
    .map(([name]) => name);
  return duplicateValueNames.map((value) => ({
    text: i18n.t('wizard.steps.reportedEvents.validations.duplicateValueNames', { name: event.name, value }),
    queryParams: { labelId: event.labelId, eventDefId: event.id },
  }));
}

function isMissingNumericValidationValues(validation: EventDefValidation) {
  const { expectedDef } = validation;
  const isSingleBound = 'value' in expectedDef;
  if (isSingleBound && expectedDef.value !== null) return false;
  const isRange = 'min' in expectedDef && 'max' in expectedDef;
  if (isRange && expectedDef.min !== null && expectedDef.max !== null) return false;
  return true;
}

function validateNoNumericValidationEmptyValues(event: ReportedEventDef): ValidationOopsie[] {
  const canHaveNumericValidations =
    event.valueType === EventDefType.NUMBER ||
    event.valueType === EventDefType.DATE ||
    event.valueType === EventDefType.TIME_OF_DAY;
  if (!canHaveNumericValidations) return [];
  const validations = event.validations ?? [];
  return validations.reduce<ValidationOopsie[]>((acc, validation) => {
    const isMissingValues = isMissingNumericValidationValues(validation);
    if (isMissingValues)
      acc.push({
        text: i18n.t('wizard.steps.reportedEvents.validations.missingNumericValidationValues', { name: event.name }),
        queryParams: { labelId: event.labelId, eventDefId: event.id },
      });
    return acc;
  }, []);
}

function getStringCount(synonyms: string[]) {
  return synonyms.reduce<Record<string, number>>((acc, synonym) => {
    acc[synonym] = (acc[synonym] ?? 0) + 1;
    return acc;
  }, {});
}

function findDuplicateSynonyms(synonyms: string[]) {
  const synonymsCount = getStringCount(synonyms);
  return Object.keys(synonymsCount).filter((synonym) => synonymsCount[synonym] > 1);
}

function validateNoDuplicateSynonymsWithinEvent(event: ReportedEventDef): ValidationOopsie[] {
  const synonyms = event.synonyms ?? [];
  const lowerCaseSynonyms = synonyms.map((synonym) => synonym.toLowerCase());
  const duplicateSynonyms = findDuplicateSynonyms(lowerCaseSynonyms);
  return duplicateSynonyms.map((synonym) => ({
    text: i18n.t('wizard.steps.reportedEvents.validations.duplicateSynonymsWithinEvent', {
      synonym,
      name: event.name,
    }),
    queryParams: { labelId: event.labelId, eventDefId: event.id },
  }));
}

function validateNoDuplicateSynonymsWithinValues(event: ReportedEventDef): ValidationOopsie[] {
  if (!isSelectionType(event.valueType)) return [];
  const values = event.values ?? [];
  return values.reduce<ValidationOopsie[]>((acc, value) => {
    const synonyms = value.synonyms ?? [];
    const lowerCaseSynonyms = synonyms.map((synonym) => synonym.toLowerCase());
    const duplicateSynonyms = findDuplicateSynonyms(lowerCaseSynonyms);
    const errors = duplicateSynonyms.map((synonym) => ({
      text: i18n.t('wizard.steps.reportedEvents.validations.duplicateSynonymsWithinValue', {
        synonym,
        name: event.name,
        value: value.name,
      }),
      queryParams: { labelId: event.labelId, eventDefId: event.id },
    }));
    return acc.concat(errors);
  }, []);
}

function validateNoDuplicateSynonymsAcrossValues(event: ReportedEventDef): ValidationOopsie[] {
  if (!isSelectionType(event.valueType)) return [];
  const synonymsToValueMap = getSynonymToNameMap(event.values ?? []);
  const synonymsWithMoreThanOneValue = Object.entries(synonymsToValueMap).filter(
    ([_, valueNames]) => valueNames.size > 1,
  );
  return synonymsWithMoreThanOneValue.map(([synonym, valueNames]) => ({
    text: i18n.t('wizard.steps.reportedEvents.validations.duplicateSynonymAcrossValues', {
      synonym,
      name: event.name,
      values: Array.from(valueNames).join(', '),
    }),
    queryParams: { labelId: event.labelId, eventDefId: event.id },
  }));
}

function isPartiallyFilledForm(event: ReportedEventDef): ValidationOopsie[] | false {
  const partiallyFilledForm =
    (event.startCommandWords?.length && !event.endCommandWords?.length) ||
    (!event.startCommandWords?.length && event.endCommandWords?.length);

  if (partiallyFilledForm) {
    return [
      {
        text: i18n.t('wizard.steps.reportedEvents.validations.missingNumericValidationValues', { name: event.name }),
        queryParams: { labelId: event.labelId, eventDefId: event.id },
      },
    ];
  }

  return false;
}

function hasDuplications(event: ReportedEventDef): ValidationOopsie[] | false {
  const fieldsValuesSet = new Set(event.endCommandWords?.map((v) => v.toLowerCase()));
  const duplicates = event.startCommandWords?.filter((command: string) => fieldsValuesSet.has(command.toLowerCase()));

  if (duplicates?.length) {
    return [
      {
        text: i18n.t('wizard.steps.reportedEvents.validations.duplicateCommandWords', {
          name: event.name,
          words: duplicates.join(', '),
        }),
        queryParams: { labelId: event.labelId, eventDefId: event.id },
      },
    ];
  }

  return false;
}

function validateTextCommands(event: ReportedEventDef): ValidationOopsie[] {
  if (event.valueType !== EventDefType.FREE_TEXT) return [];

  return isPartiallyFilledForm(event) || hasDuplications(event) || [];
}

/** For each event, validate:
 * - no duplicate synonyms
 * - if selection event, minimum 2 values
 * - if selection event, no duplicate values
 * - if selection event, no duplicate synonyms within values
 * - if selection event, no duplicate synonyms across values
 * - if numeric or date event, no empty validation values
 * - if free text event, no duplicate values
 * - if free text event, both or none command words
 */
function validateWithinEvents(events: ReportedEventDef[]): ValidationOopsie[] {
  return events
    .flatMap((event) => [
      validateNoDuplicateSynonymsWithinEvent(event),
      validateSelectionEventsMinimumValues(event),
      validateNoDuplicateValueNames(event),
      validateNoNumericValidationEmptyValues(event),
      validateNoDuplicateSynonymsWithinValues(event),
      validateNoDuplicateSynonymsAcrossValues(event),
      validateTextCommands(event),
    ])
    .flatMap((x) => x);
}

function isMissingMainEvent(events: ReportedEventDef[], labelId: string) {
  const hasAnyEvents = events.some((event) => event.labelId === labelId);
  if (!hasAnyEvents) return false;
  const hasMainEvent = events.find((event) => event.labelId === labelId && event.isMainEvent);
  if (hasMainEvent) return false;
  return true;
}

/** Validate that any label that has at least one event, has a main event */
function validateMainEvents(events: ReportedEventDef[], labels: Record<LabelId, Label>): ValidationOopsie[] {
  const labelIds = Object.keys(labels);
  return labelIds.reduce<ValidationOopsie[]>((acc, labelId) => {
    if (!isMissingMainEvent(events, labelId)) return acc;
    const { name } = labels[labelId];
    acc.push({
      text: i18n.t('wizard.steps.reportedEvents.validations.missingMainEvent', { label: name }),
      queryParams: { labelId },
    });
    return acc;
  }, []);
}

export function validateEventDefs(data: ReportedEventsData, labels: Record<LabelId, Label>): ValidationOopsie[] {
  const eventArray = Object.values(data);
  return [
    validateNoMissingEventNames(eventArray, labels),
    validateNoDuplicateNamesWithinLabel(eventArray, labels),
    validateNoDuplicateSynonymsWithinLabel(eventArray, labels),
    validateWithinEvents(eventArray),
    validateMainEvents(eventArray, labels),
  ].flatMap((x) => x);
}
