import { MRT_TableOptions } from 'mantine-react-table';
import { User } from 'stores/auth';
import { EntityTableRowActions } from './components';
import classes from './EntityTable.module.css';
import { Group } from '@jargonic/customer-types';
import { isUser } from './EntitiesPanel.utils';

export const policyTableDefs = {
  getRowId: (row) => isUser(row) ? row.userId : row.id,
  enableSorting: false,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableRowActions: true,
  positionActionsColumn: 'last',
  layoutMode: 'grid',
  mantineTableBodyCellProps: { py: 'xs' },
  mantineTableHeadCellProps: { p: 0 },
  displayColumnDefOptions: {
    'mrt-row-actions': { header: '', grow: false },
  },
  mantinePaperProps: { className: classes.paper },
  mantineTableContainerProps: { className: classes.container },
  renderRowActions: (props) => <EntityTableRowActions {...props} />,
} satisfies Partial<MRT_TableOptions<User | Group>>;
