import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import i18n from 'services/i18n';
import { AiDataTextSample } from 'stores/aiPlatform/aiDataSources/aiDataSources.types';

export function getCellId(sampleId: string, columnId: string, isLoading = false): string {
  return `${sampleId}-${columnId}${isLoading ? '-skeleton' : ''}`;
}

export const useColumns = (samples: AiDataTextSample[]): MRT_ColumnDef<AiDataTextSample>[] =>
  useMemo(() => {
    const baseColumns: MRT_ColumnDef<AiDataTextSample>[] = [
      {
        accessorKey: 'sentence',
        header: i18n.t('dataGenerationPage.sampleTable.text.header'),
        size: 600,
        grow: false,
        Cell: CellView,
      },
    ];

    const labelColumns = Object.keys(samples[0].labels).map((key) => ({
      accessorFn: (val: AiDataTextSample) => val.labels[key],
      header: key,
      size: 200,
      grow: false,
      Cell: CellView,
    }));

    return [...baseColumns, ...labelColumns];
  }, [samples]);

const CellView: MRT_ColumnDef<AiDataTextSample>['Cell'] = ({ cell }) => (
  <div data-testid={getCellId(cell.row.id, cell.column.id)}>{cell.getValue<string>()}</div>
);
