import { Flex } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { Group } from 'stores/userMgmt';
import { GroupTableSearch, testIds as searchTestIds } from './GroupTableSearch';
import { GroupTableCreateAction, testIds as createTestIds } from './GroupTableCreateAction';
import { GroupTableState } from '../../GroupTable.types';

type GroupTableActionsProps = { table: MRT_TableInstance<Group> };

export const testIds = {
  search: searchTestIds,
  create: createTestIds,
};

export const GroupTableActions = ({ table }: GroupTableActionsProps) => {
  const { globalFilter, onSearch, onCreateGroup } = table.getState() as GroupTableState;

  return (
    <Flex justify='space-between' p='md' align='center'>
      <GroupTableSearch value={globalFilter} onChange={onSearch} />
      <GroupTableCreateAction groups={table.options.data} onCreateGroup={onCreateGroup} />
    </Flex>
  );
};
