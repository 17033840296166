/* eslint-disable no-redeclare */
import Papa, { type ParseResult } from 'papaparse';

export function parseNumber(value: string | number | undefined, defaultZero?: true): number;
export function parseNumber(value: string | number | undefined, defaultZero?: false): number | undefined;
export function parseNumber(value: string | number | undefined, defaultZero = true) {
  const defaultValue = defaultZero ? 0 : undefined;
  if (value === undefined) return defaultValue;
  if (typeof value === 'string' && !value) return defaultValue;
  const parsed = Number(value);
  return Number.isNaN(parsed) ? defaultValue : parsed;
}

type ThingWithOrder = { order: number };
/** Sorting predicate to sort an array by order, in place */
export function orderPredicate(a: ThingWithOrder, b: ThingWithOrder) {
  return a.order - b.order;
}

export function groupBy<T extends Record<any, any>, K extends keyof T>(arr: T[], key: K) {
  return arr.reduce<Record<T[K], T[]>>(
    (acc, item) => {
      const itemKey = item[key];
      const keyGroup = acc[itemKey] ?? (acc[itemKey] = []);
      keyGroup.push(item);
      return acc;
    },
    {} as Record<T[K], T[]>,
  );
}

/** Promise wrapper to Papaparse */
export function parseCSV<T extends Record<string, string>>(file: File): Promise<ParseResult<T>> {
  return new Promise((resolve, reject) => {
    Papa.parse<T>(file, {
      header: true,
      complete(results) {
        resolve(results);
      },
      error(err) {
        reject(err);
      },
      skipEmptyLines: 'greedy',
    });
  });
}

export function compareArrays<T extends number | string>(original: T[], updated: T[]) {
  const originalSet = new Set(original);
  const updatedSet = new Set(updated);
  const added = updated.filter((item) => !originalSet.has(item));
  const removed = original.filter((item) => !updatedSet.has(item));
  const isDiff = added.length > 0 || removed.length > 0;
  return { isDiff, added, removed };
}

/** Generate an array of numbers from 0 to n - 1 */
export function times(n: number) {
  return Array.from({ length: n }, (_, i) => i);
}

/**
 * Transforms an array of objects into an object keyed by a specified property.
 *
 * @param {T[]} array - The array of objects to transform.
 * @param {K} key - The key to use for the resulting object.
 *
 * @returns {{ [P in T[K]]?: T }} - The resulting object keyed by the specified property.
 *
 * @example
 * // Input
 * const users = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 3, name: 'Charlie' }
 * ];
 * const key = 'id';
 *
 * // Output
 * const result = keyBy(users, key);
 * // {
 * //   1: { id: 1, name: 'Alice' },
 * //   2: { id: 2, name: 'Bob' },
 * //   3: { id: 3, name: 'Charlie' }
 * // }
 */
export function keyBy<T extends object, K extends keyof T>(array: T[], key: K) {
  return array.reduce(
    (map, item) => ({ ...map, [item[key] as PropertyKey]: item }),
    {} as T[K] extends PropertyKey ? { [P in T[K]]: T } : never,
  );
}

export function timeStringToMinutes(timeString: string): number | undefined {
  if (!timeString) return undefined;
  if (!timeString.includes(':')) return undefined;
  const [hours, minutes] = timeString.split(':').map(Number);

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) return undefined;

  const result = hours * 60 + minutes;
  return Number.isNaN(result) ? undefined : result;
}

export function minutesToTimeString(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}
