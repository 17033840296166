import {
  ReportedEventDefinition as ApiReportedEventDefinition,
  ReportedEventDefinitionId,
  ReportedEventDefValueId,
  ReportedEventDefValue as ApiReportedEventDefValue,
  UserValueType as EventDefType,
  NumericValidationType,
  ListOfValuesValidationType,
  SingleBoundValidationParams,
  RangeValidationParams,
  ListOfValuesValidationParams as ListValidationParams,
  RangeValidation,
  SingleBoundValidation,
  ListOfValuesValidation as EventDefListValidation,
  ValidationId as EvenDefValidationId,
  CommonValueType,
  BindingValue,
} from '@jargonic/event-definition-types';
import { LabelId } from '../labels';

type PartialSingleBoundValidationParams = Record<keyof SingleBoundValidationParams, number | null>;
export type PartialSingleBoundValidation = Omit<SingleBoundValidation, 'expectedDef'> & {
  expectedDef: PartialSingleBoundValidationParams;
};

type PartialRangeValidationParams = Record<keyof RangeValidationParams, number | null>;
export type PartialRangeValidation = Omit<RangeValidation, 'expectedDef'> & {
  expectedDef: PartialRangeValidationParams;
};

type EventDefNumericValidation = PartialRangeValidation | PartialSingleBoundValidation;
type EventDefValidation = EventDefNumericValidation | EventDefListValidation;

export {
  type ReportedEventDefinitionId,
  type ApiReportedEventDefinition,
  type ReportedEventDefValueId,
  type ApiReportedEventDefValue,
  type SingleBoundValidationParams,
  type RangeValidationParams,
  type ListValidationParams,
  type PartialSingleBoundValidationParams,
  type PartialRangeValidationParams,
  type PartialRangeValidation as RangeValidation,
  type PartialSingleBoundValidation as SingleBoundValidation,
  type EventDefNumericValidation,
  type EventDefListValidation,
  type EvenDefValidationId,
  type EventDefValidation,
};

export enum EventDefElement {
  BUTTONS = 'ButtonsEvent',
  DROPDOWN = 'DropdownEvent',
  STRING = 'TextEvent',
  APPENDABLE_STRING = 'AppendTextEvent',
  NUMERIC = 'NumericEvent',
  DATE = 'DateEvent',
  TIME_OF_DAY = 'TimeOfDayEvent',
}

export interface ReportedEventDefValue extends ApiReportedEventDefValue {
  valid?: boolean;
}

export interface ReportedEventDef
  extends Omit<
    ApiReportedEventDefinition,
    | 'decimalPrecision'
    | 'flowId'
    | 'parentEvent'
    | 'containerTypeId'
    | 'UI'
    | 'values'
    | 'validations'
    | 'mandatoryFor'
    | 'validationFor'
    | 'exposeFor'
  > {
  parentId?: ReportedEventDefinitionId;
  labelId: string;
  title: string;
  placeholder?: string;
  elementType: EventDefElement;
  isMainEvent: boolean;
  decimalPrecision: number;
  valueType: EventDefType;
  values?: ReportedEventDefValue[];
  validations?: EventDefValidation[];
  mandatory?: boolean;
  validated?: boolean;
  exposed?: boolean;
  bindingValues?: BindingValue[];
}

export type ReportedEventsData = Record<ReportedEventDefinitionId, ReportedEventDef>;
export { EventDefType, NumericValidationType, ListOfValuesValidationType, CommonValueType };

/** [Store Action] Possible fields to update when using `updateEventDef` */
export type UpdatableEventDefFields = Partial<Omit<ReportedEventDef, 'labelId' | 'parentId' | 'type' | 'isMainEvent'>>;

/** [Store Action] Possible actions to call when using `updateEventValue` */
export type UpdateValuePayload =
  | { action: 'delete'; payload: Required<Pick<ReportedEventDefValue, 'id'>> }
  | {
      action: 'create';
      payload?: string;
    }
  | {
      action: 'update';
      payload: Partial<ReportedEventDefValue> & Required<Pick<ReportedEventDefValue, 'id'>>;
    }
  | {
      action: 'reorder';
      payload: ReportedEventDefValueId[];
    };

/** [Store Action] Possible actions to call when using `updateValidation` */
export type UpdateValidationPayload =
  | { action: 'delete'; payload: EvenDefValidationId }
  | { action: 'create'; payload: NumericValidationType }
  | { action: 'update'; payload: Partial<EventDefNumericValidation> & Required<Pick<EventDefNumericValidation, 'id'>> };

export type ReportedEventsQueryParams = { labelId?: LabelId; eventDefId?: ReportedEventDefinitionId };

export enum EventValueCSVColumnName {
  NAME = 'name',
  SYNONYMS = 'synonyms',
  VALID = 'valid',
}

export enum EventValueCSVResponse {
  OK,
  ERROR,
  MISSING_NAME,
  DUPLICATE_NAME,
}

export type EventValueCSVParsedPayload = Partial<Record<EventValueCSVColumnName, string>>;
