import { User } from '@jargonic/customer-types';
import { GetDataGenerationQuery } from '../aiPlatformBackendTypes';

export type AiDataSourceId = string;

export enum AiDataSourceSamplesType {
  VOICE = 'VOICE',
  TEXT = 'TEXT',
  BOTH = 'BOTH',
}

export enum AiDataSourceStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum AiDataSourceStep {
  GET_FLOW_SETTINGS = 'GET_FLOW_SETTINGS',
  GENERATE_AND_STORE_TEXT_SAMPLES = 'GENERATE_AND_STORE_TEXT_SAMPLES',
  GENERATE_AND_STORE_SPEECH_SAMPLES = 'GENERATE_AND_STORE_SPEECH_SAMPLES',
  PROVISIONING_DATA_FILTERING_NOTEBOOK = 'PROVISIONING_DATA_FILTERING_NOTEBOOK',
  DATA_GENERATION_DONE = 'DATA_GENERATION_DONE',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export interface AiDataSource {
  id: AiDataSourceId;
  createdBy: User;
  createdAt?: number;
  endedAt?: number;
  status: AiDataSourceStatus;
  step: AiDataSourceStep;
  samplesType: AiDataSourceSamplesType;
  isSamplesGeneration: boolean;
}

export interface AiDataTextSample {
  id: string;
  sentence: string;
  labels: Record<string, string>;
  // isLoading: boolean;
}

export type ApiAiDataSource = GetDataGenerationQuery['getDataGeneration'];
export interface AiDataSourceSubscriptionOptions {
  unsubscribeOnFinish?: boolean;
  onComplete?: () => void;
}
