import { Button, Divider, Flex, Group, Modal, Stack, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { ReportedEventDefValue } from 'stores/wizard';
import { useEffect } from 'react';
import {
  ValueNameInput,
  ValueSynonymsInput,
  ValueValidityToggle,
  nameInputTestIds,
  synonymsTestIds,
  validityTestIds,
} from './components';

interface ValueSettingsModalProps {
  eventValue: ReportedEventDefValue;
  opened: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (payload: Partial<ReportedEventDefValue>) => boolean;
}

export const testIds = {
  modal: 'value-settings-modal',
  title: 'value-settings-modal-title',
  cancelButton: 'value-settings-modal-cancel-button',
  applyButton: 'value-settings-modal-apply-button',
  deleteButton: {
    button: 'value-settings-modal-delete-button',
    icon: 'value-settings-modal-delete-button-icon',
  },
  name: nameInputTestIds,
  validity: validityTestIds,
  synonyms: synonymsTestIds,
};

export const ValueSettingsModal = ({ eventValue, opened, onClose, onDelete, onSubmit }: ValueSettingsModalProps) => {
  const { t } = useTranslation();
  const form = useForm<ReportedEventDefValue>({
    initialValues: eventValue,
  });

  const onApply = (values: ReportedEventDefValue) => {
    const response = onSubmit(values);
    if (!response)
      form.setErrors({ name: t('wizard.steps.reportedEvents.typeSettings.types.select.duplicateValueError') });
    else onClose();
  };

  useEffect(() => {
    if (opened) form.setValues(eventValue);
  }, [opened]);

  return (
    <Modal.Root centered opened={opened} onClose={onClose} data-testid={testIds.modal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Title order={5} data-testid={testIds.title}>
            {t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.title')}
          </Title>
          <ActionButton label={t('common.delete')} onClick={onDelete} data-testid={testIds.deleteButton.button}>
            <IconTrash data-testid={testIds.deleteButton.icon} />
          </ActionButton>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit(onApply)}>
            <Stack gap='lg'>
              <Flex gap='xl'>
                <ValueNameInput className='grow' {...form.getInputProps('name')} />
                <ValueValidityToggle
                  value={form.values.valid}
                  onChange={(value) => form.setFieldValue('valid', value)}
                />
              </Flex>
              <Divider />
              <ValueSynonymsInput {...form.getInputProps('synonyms')} />
              <Group justify='end'>
                <Button variant='subtle' onClick={onClose} data-testid={testIds.cancelButton}>
                  {t('common.cancel')}
                </Button>
                <Button type='submit' data-testid={testIds.applyButton}>
                  {t('common.apply')}
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
