export const getDataGenerationQuery = `
query GetDataGeneration($executionId: ID!) {
    getDataGeneration(executionId: $executionId) {
      currentStep {
        stepName
        executionId
      }
      executionId
      state
      startTimestamp
      endTimestamp
      triggeredBy
      sampleExecutionId
    }
  }
`;

export const listDataGenerationsQuery = `
  query ListDataGenerations($listDataGenerationsInput: ListDataGenerationsInput!) {
    listDataGenerations(listDataGenerationsInput: $listDataGenerationsInput) {
      dataGenerations {
        executionId
        startTimestamp
        state
        triggeredBy
        onlyTextSamples
        onlyVoiceSamples
        sampleExecutionId
      }
      paginationToken
    }
  }
`;
