import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { IconDots, IconTrash, IconTrashOff } from '@tabler/icons-react';
import { ActionButton } from 'components';

import { User } from '@flow/flow-backend-types';
import { RemoveEntityModal, testIds as removeModalTestIds } from './RemoveEntityModal';
import { EntityTableState } from '../../EntityTable.types';
import { Group } from '@jargonic/customer-types';
import { isUser } from '../../EntitiesPanel.utils';

export const testIds = {
  getButtonTestId: (id: string) => `entity-table-row-actions-button-${id}`,
  icon: 'entity-table-row-actions-icon',
  options: {
    remove: {
      button: 'entity-table-row-actions-remove',
      modal: removeModalTestIds,
    },
  },
};

export const EntityTableRowActions: NonNullable<MRT_TableOptions<User | Group>['renderRowActions']> = ({
  row,
  table,
}) => {
  const { t } = useTranslation();
  const [removeEntityModalOpened, { open: openRemoveEntityModal, close: closeRemoveEntityModal }] = useDisclosure();
  const { onRemove, policy } = table.getState() as EntityTableState;
  const entityName = isUser(row.original) ? `${row.original.givenName} ${row.original.familyName}` : row.original.name;

  const onRemoveEntity = async () => {
    const response = await onRemove(policy.id, isUser(row.original) ? row.original.userId : row.original.id);

    if (response)
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.entities.table.actions.remove.success', { entity: entityName, policy: policy.name }),
        message: null,
        icon: <IconTrash />,
      });
    else
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.entities.table.actions.remove.error'),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openRemoveEntityModal} data-testid={testIds.options.remove.button}>
            {t('common.remove')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <RemoveEntityModal
        opened={removeEntityModalOpened}
        onClose={closeRemoveEntityModal}
        entityName={entityName}
        policyName={policy.name}
        onRemove={onRemoveEntity}
      />
    </>
  );
};
