import { Card, Flex, Title, useComputedColorScheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Container,
  EvenDefValidationId,
  EventDefNumericValidation,
  EventDefType,
  NumericValidationType,
} from 'stores/wizard';
import i18n from 'services/i18n';
import { ValidationItemInput, testIds as componentTestIds, ValidationMapping } from './components';

interface validationItemProps {
  containerPool: Container[];
  type: EventDefType.NUMBER | EventDefType.DATE | EventDefType.TIME_OF_DAY;
  validation: EventDefNumericValidation;
  onChange: (values: Partial<EventDefNumericValidation>) => void;
  onDelete: () => void;
}

export const testIds = {
  getCardTestId: (validationId: EvenDefValidationId) => `validation-card-${validationId}`,
  title: 'validation-title',
  delete: {
    button: 'delete-button',
    icon: 'delete-icon',
  },
  ...componentTestIds,
};

const { GREATER_THEN, LESS_THEN, IS_BETWEEN, IS_NOT_BETWEEN } = NumericValidationType;
export const validationTitles: Record<NumericValidationType, string> = {
  [GREATER_THEN]: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.greaterThan'),
  [LESS_THEN]: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.lessThan'),
  [IS_BETWEEN]: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.between'),
  [IS_NOT_BETWEEN]: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.notBetween'),
};

export const ValidationItem = ({ containerPool, validation, type, onChange, onDelete }: validationItemProps) => {
  const { t } = useTranslation();
  const colorScheme = useComputedColorScheme('light');

  return (
    <Card bg={colorScheme === 'light' ? 'gray.0' : 'dark.8'} data-testid={testIds.getCardTestId(validation.id)}>
      <Flex align='center' gap='tn' mb='md'>
        <Title className='grow' order={5} data-testid={testIds.title}>
          {validationTitles[validation.validationType]}
        </Title>
        <ValidationMapping
          containers={containerPool}
          selectedIds={validation.containerIds}
          onChange={(containerIds) => onChange({ containerIds })}
        />
        <ActionButton label={t('common.delete')} onClick={onDelete} data-testid={testIds.delete.button}>
          <IconTrash data-testid={testIds.delete.icon} />
        </ActionButton>
      </Flex>
      <ValidationItemInput type={type} validation={validation} onChange={onChange} />
    </Card>
  );
};
