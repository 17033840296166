import { Flex, Stack, Text } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { User } from 'stores/auth';
import { useTranslation } from 'react-i18next';
import { EntityTableSearch, testIds as searchTestIds } from './EntityTableSearch';
import { EntityTableManageAction, testIds as manageActionTestIds } from './EntityTableManageAction';
import { EntityTableState } from '../../EntityTable.types';
import { Group } from '@jargonic/customer-types';

type UserTableActionsProps = { table: MRT_TableInstance<User | Group> };

export const testIds = {
  search: searchTestIds,
  manage: manageActionTestIds,
  count: 'entity-table-count',
};

export const EntityTableActions = ({ table }: UserTableActionsProps) => {
  const { t } = useTranslation();
  const { globalFilter, onSearch, users, groups, policy, onChangeEntities } = table.getState() as EntityTableState;
  const { data } = table.options;

  return (
    <Stack gap='xs' p='md'>
      <Flex justify='space-between' align='center'>
        <EntityTableSearch value={globalFilter} onChange={onSearch} />
        <EntityTableManageAction users={users} policy={policy} groups={groups} onChangeEntities={onChangeEntities} />
      </Flex>
      <Text size='xs' c='gray.5' data-testid={testIds.count}>
        {t('userMgmt.policies.policyDetails.panels.entities.table.entityCount', { count: data.length })}
      </Text>
    </Stack>
  );
};
