import { useMemo } from 'react';
import { AiModelType } from '../aiModels';
import { useAiDataSourcesStore } from './aiDataSources.store';
import { AiDataSource, AiDataSourceId, AiDataSourceSamplesType, AiDataTextSample } from './aiDataSources.types';

export function useAiDataSource(id: AiDataSourceId): AiDataSource | undefined {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return aiDataSources[id];
}

export function useAiDataSourcesAvailability(): boolean {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return Object.keys(aiDataSources).length > 0;
}

export function useLatestAiDataSourceByType(type: AiModelType | undefined): AiDataSource | undefined {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return useMemo(
    () =>
      type &&
      Object.values(aiDataSources)
        .filter(({ samplesType }) =>
          [
            AiDataSourceSamplesType.BOTH,
            type === AiModelType.NLP ? AiDataSourceSamplesType.TEXT : AiDataSourceSamplesType.VOICE,
          ].includes(samplesType),
        )
        .sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0))[0],
    [aiDataSources, type],
  );
}

// FIXME - switch mock data to real data (https://aiola.atlassian.net/browse/RDM-6655)
export function useLastAiDataGenSamples(sampleAmount: number): AiDataTextSample[] {
  return Array.from({ length: sampleAmount }, (_x, index) => ({
    id: `${index}`,
    sentence: `${index}-tbd in next task`,
    labels: { label: '' },
  }));
}
