import { Divider, Paper } from '@mantine/core';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  AiDataSource,
  AiDataSourceStatus,
  AiDataSourceStep,
  AiDataTextSample,
  useAiDataSourcesStore,
} from 'stores/aiPlatform';
import { DataGenTextSamplesTable } from '../DataGenSamplesTable/DataGenTextSamplesTable';
import { DataGenerationCardHeader, testIds as headerTestIds } from './DataGenerationCardHeader';
import { DataGenerationProgressbar, testIds as progressTestIds } from './DataGenerationProgressbar';

interface DataGenerationCardProps {
  aiDataSource: AiDataSource;
  samples: AiDataTextSample[];
}

export const testIds = {
  header: headerTestIds,
  progress: progressTestIds,
};

export const DataGenerationCard = ({ aiDataSource, samples }: DataGenerationCardProps) => {
  const { subscribeToAiDataSource, fetchSingleAiDataSource } = useAiDataSourcesStore([
    'subscribeToAiDataSource',
    'fetchSingleAiDataSource',
  ]);

  const isInitRef = useRef(false);
  const percent = useMemo(() => statusToPercent(aiDataSource.step), [aiDataSource]);
  const elementRef = useRef(null);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    if (elementRef.current) {
      setHeight((elementRef.current as HTMLElement).offsetHeight - 80); // TODO - use custom footer in the table and then remove this magic number
    }
  }, []);

  useEffect(() => {
    if (!isInitRef.current) {
      fetchSingleAiDataSource(aiDataSource.id);
      isInitRef.current = true;
    }
    const unsubscribeFromAiDataSource = subscribeToAiDataSource(aiDataSource.id, { unsubscribeOnFinish: true });
    return () => {
      unsubscribeFromAiDataSource();
    };
  }, [aiDataSource, subscribeToAiDataSource]);

  const renderProgressBar = aiDataSource.status === AiDataSourceStatus.IN_PROGRESS;
  return (
    <Paper
      withBorder
      radius={10}
      pt={25}
      pl={25}
      pr={25}
      mih={0}
      style={{ display: 'flex', flexDirection: 'column' }}
      ref={elementRef}
    >
      <DataGenerationCardHeader aiDataSource={aiDataSource} />
      <Divider my='sm' />
      {renderProgressBar && <DataGenerationProgressbar percent={percent} />}

      <DataGenTextSamplesTable
        samples={samples}
        isLoading={aiDataSource.status !== AiDataSourceStatus.COMPLETED}
        maxTableHeight={height}
      />
    </Paper>
  );
};

const statusToPercentMap: Record<AiDataSourceStep, number> = {
  [AiDataSourceStep.GET_FLOW_SETTINGS]: 25,
  [AiDataSourceStep.GENERATE_AND_STORE_TEXT_SAMPLES]: 50,
  [AiDataSourceStep.GENERATE_AND_STORE_SPEECH_SAMPLES]: 50,
  [AiDataSourceStep.PROVISIONING_DATA_FILTERING_NOTEBOOK]: 90,
  [AiDataSourceStep.DATA_GENERATION_DONE]: 100,
  [AiDataSourceStep.UNEXPECTED_ERROR]: 5,
};

function statusToPercent(step: AiDataSourceStep): number {
  return statusToPercentMap[step] ?? statusToPercentMap.UNEXPECTED_ERROR;
}
