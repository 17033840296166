import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconLicense, IconLicenseOff } from '@tabler/icons-react';
import { Group } from 'stores/userMgmt';
import { NiceResponse } from 'consts';
import { CreateGroupModal, testIds as createTestIds } from './CreateGroupModal';

interface GroupTableCreateActionProps {
  groups: Group[];
  onCreateGroup: (name: string) => Promise<Group | undefined>;
}

export const testIds = {
  button: 'group-table-create-action-button',
  createModal: createTestIds,
};

export const GroupTableCreateAction = ({ groups, onCreateGroup }: GroupTableCreateActionProps) => {
  const { t } = useTranslation();
  const [createModalOpened, { open: openCreateModal, close: closeCreateModal }] = useDisclosure();

  const onCreationSuccess = (name: string) =>
    notifications.show({
      title: t('userMgmt.groups.table.actions.create.createSuccess.title', { name }),
      message: null,
      icon: <IconLicense />,
    });

  const onCreationFailure = (name: string) =>
    notifications.show({
      color: 'red',
      title: t('userMgmt.groups.table.actions.create.createError.title', { name }),
      message: t('userMgmt.groups.table.actions.create.createError.message'),
      icon: <IconLicenseOff />,
    });

  const checkForDuplicates = (name: string): boolean => {
    const nameSet = new Set(groups.map((group) => group.name.toLowerCase()));
    return nameSet.has(name.toLowerCase());
  };

  const handleCreateGroup = async (name: string): Promise<NiceResponse> => {
    const response = await onCreateGroup(name);
    if (response) {
      onCreationSuccess(response.name);
      return NiceResponse.OK;
    }
    onCreationFailure(name);
    return NiceResponse.ERROR;
  };

  const onCreate = async (name: string): Promise<NiceResponse> => {
    if (checkForDuplicates(name)) {
      return NiceResponse.DUPLICATE;
    }
    return handleCreateGroup(name);
  };

  return (
    <>
      <Button onClick={openCreateModal} data-testid={testIds.button}>
        {t('userMgmt.groups.table.actions.create.button')}
      </Button>
      <CreateGroupModal opened={createModalOpened} onClose={closeCreateModal} onSubmit={onCreate} />
    </>
  );
};
