// TODO: Get these types from AI-Platform backend
import { UserIdentity } from '@flow/flow-backend-types';

export enum AiModelStatus {
  COMPLETED = 'completed',
  IN_TRAINING = 'inTraining',
  FAILED = 'failed',
  PAUSED = 'paused',
  ABORTED = 'aborted',
}

export enum AiModelType {
  NLP = 'nlp',
  ASR = 'asr',
}

export enum AiDataset {
  TRAINING = 'training',
  TESTING = 'testing',
  EVALUATION = 'evaluation',
}

export enum TrainingStepStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  PAUSED = 'paused',
  FAILED = 'failed',
  PENDING = 'pending',
}

export type AiModelId = string;

export interface AiModel<Type extends AiModelType = AiModelType> {
  id: AiModelId;
  createdAt: number;
  createdBy: UserIdentity;
  status: AiModelStatus;
  type: Type;
  trainingSteps: TrainingSteps;
}

export interface AiModelMetrics {
  precision: number;
  recall: number;
  f1: number;
  support: number;
}

export interface LabelMetrics {
  label: string;
  metrics: AiModelMetrics;
}

export interface IterationResults {
  labels: LabelMetrics[];
}

/** This is a virtual step indicating the status of the process until an actual step has started */
export interface WarmupStep {
  status: TrainingStepStatus;
}

export type DatasetCreationStep =
  | {
      status: TrainingStepStatus.COMPLETED;
      trainingUri: string;
      testingUri: string;
      evaluationUri: string;
    }
  | {
      status:
        | TrainingStepStatus.IN_PROGRESS
        | TrainingStepStatus.PAUSED
        | TrainingStepStatus.FAILED
        | TrainingStepStatus.PENDING;
    };
export type DatasetCreationStepCompleted = Extract<DatasetCreationStep, { status: TrainingStepStatus.COMPLETED }>;

export type TrainingStep =
  | {
      status: TrainingStepStatus.COMPLETED | TrainingStepStatus.IN_PROGRESS | TrainingStepStatus.PAUSED;
      results: IterationResults[];
    }
  | {
      status: TrainingStepStatus.FAILED | TrainingStepStatus.PENDING;
    };
export type TrainingStepCompleted = Extract<TrainingStep, { status: TrainingStepStatus.COMPLETED }>;

export type TestingStep =
  | {
      status: TrainingStepStatus.COMPLETED;
      results: IterationResults;
      failedSamplesCount: number;
      failedSamplesLink?: string;
    }
  | {
      status:
        | TrainingStepStatus.IN_PROGRESS
        | TrainingStepStatus.PAUSED
        | TrainingStepStatus.FAILED
        | TrainingStepStatus.PENDING;
    };
export type TestingStepCompleted = Extract<TestingStep, { status: TrainingStepStatus.COMPLETED }>;

export interface TrainingSteps {
  warmup: WarmupStep;
  dataset: DatasetCreationStep;
  train: TrainingStep;
  test: TestingStep;
}

export interface AiModelSubsciptionOptions {
  onComplete?: () => void;
  unsubscribeOnFinish?: boolean;
}
