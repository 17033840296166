import { Badge, Button, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Container, ContainerId } from 'stores/wizard';
import { ContainerMappingModal, mappingModalTestIds } from '../../../../../ContainerMappingModal';

interface ValidationMappingProps {
  containers: Container[];
  selectedIds: ContainerId[];
  onChange: (selectedIds: ContainerId[]) => void;
}

export const testIds = {
  badge: 'event-def-settings-mapping-badge',
  button: 'event-def-settings-mapping-button',
  icon: 'event-def-settings-mapping-icon',
  tooltip: 'event-def-settings-mapping-tooltip',
  modal: mappingModalTestIds,
};

export const ValidationMapping = ({ containers, selectedIds, onChange }: ValidationMappingProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();
  const count = selectedIds.length;

  return (
    <>
      <Tooltip
        label={t('wizard.steps.reportedEvents.typeSettings.types.number.validation.mapping.tooltip')}
        maw={200}
        multiline
        data-testid={testIds.tooltip}
      >
        <Button
          color='gray'
          leftSection={<IconAdjustmentsHorizontal data-testid={testIds.icon} />}
          variant='outline'
          onClick={open}
          data-testid={testIds.button}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.number.validation.mapping.title')}
          <Badge radius='xl' size='xs' ml='xs' hidden={!count} data-testid={testIds.badge}>
            {count}
          </Badge>
        </Button>
      </Tooltip>
      <ContainerMappingModal
        opened={opened}
        onClose={close}
        containers={containers}
        selectedIds={selectedIds}
        onChange={onChange}
      />
    </>
  );
};
