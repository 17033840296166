import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { UpdateFlowRequest } from '@jargonic/flows-types';
import { FlowId } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { useShallow } from 'zustand/react/shallow';
import { CustomerId } from '@jargonic/customer-types';
import { flowSettingsApi } from 'stores/wizard/flowSettings/flowSettings.api';
import { getDefaultMetadata } from 'stores/wizard/flowSettings/flowSettings.utils';
import { FolderId, folderStore } from 'stores/folders';
import { PartialErrorResponse } from 'consts';
import { CreateFlowPayload, Flow } from './flows.types';
import { flowsApi } from './flows.api';
import { getFlowReorderPayload, getFolderFlowLists } from './flows.utils';
import { createFlowPayloadSupplement } from './flows.const';

interface FlowsState {
  loading: boolean;
  flows: Record<FlowId, Flow>;
}

type UpsertFlowArgs =
  | { customerId: CustomerId; flowId?: never; payload: CreateFlowPayload; action: 'create' }
  | { customerId: CustomerId; flowId: FlowId; payload: Partial<UpdateFlowRequest>; action: 'update' }
  | { customerId: CustomerId; flowId: FlowId; payload?: never; action: 'duplicate' }
  | { customerId: CustomerId; flowId: FlowId; payload?: never; action: 'archive' | 'unarchive' }
  | { customerId: CustomerId; flowId: FlowId; payload?: never; action: 'publish' };

async function upsertFlow(args: UpsertFlowArgs) {
  const { customerId, flowId, payload, action } = args;
  switch (action) {
    case 'create': {
      const flow = await flowsApi.createFlow(customerId, { ...payload, ...createFlowPayloadSupplement });
      if (flow) {
        await flowSettingsApi.updateMetadata(customerId, flow.id, getDefaultMetadata(flow.id));
        if (flow.folderId) await folderStore.getState().fetchFolderById(customerId, flow.folderId);
      }
      return flow;
    }
    case 'update':
      return flowsApi.updateFlow(customerId, flowId, payload);
    case 'duplicate': {
      const flow = await flowsApi.getFlowById(customerId, flowId);
      const folderId = flow?.folderId;
      const duplicatedFlows = await flowsApi.cloneFlow(customerId, flowId);

      if (folderId) {
        await folderStore.getState().fetchFolderById(customerId, folderId);
      }
      return duplicatedFlows;
    }
    case 'archive': {
      const flow = await flowsApi.getFlowById(customerId, flowId);
      const folderId = flow?.folderId;
      const response = await flowsApi.archiveFlow(customerId, flowId);
      if (folderId) {
        await folderStore.getState().fetchFolderById(customerId, folderId);
      }
      return response;
    }
    case 'unarchive':
      return flowsApi.unarchiveFlow(customerId, flowId);

    case 'publish': {
      const publishResponse = await flowsApi.publishFlow(customerId, flowId);
      return publishResponse && flowsApi.getFlowById(customerId, flowId);
    }
    default:
      return undefined;
  }
}

interface FlowsActions {
  fetchFlows: (customerId: string) => Promise<void>;
  upsertFlow: (args: UpsertFlowArgs) => Promise<Flow | undefined>;
  createFromFile: (customerId: string, file: File) => Promise<Flow | PartialErrorResponse | undefined>;
  reorderFlow: (customerId: string, flowId: FlowId, folderId: FolderId | null, index: number) => Promise<boolean>;
  deleteFlow: (customerId: string, flowId: string) => Promise<boolean>;
  /** Locally update flows, without calling the server. */
  updateFlows: (flows: (Partial<Flow> & Required<Pick<Flow, 'id'>>)[]) => void;
  reset: () => void;
}

const initialState: FlowsState = {
  loading: false,
  flows: {},
};

export const flowStore = create(
  immer<FlowsState & FlowsActions>((set, get) => ({
    ...initialState,
    fetchFlows: async (customerId) => {
      set({ loading: true });
      const flowArray = await flowsApi.getFlows(customerId);
      const flows = flowArray.reduce<Record<FlowId, Flow>>((acc, flow) => {
        acc[flow.id] = flow;
        return acc;
      }, {});
      set({ flows, loading: false });
    },
    upsertFlow: async (payload) => {
      const { flows } = get();
      const response = await upsertFlow(payload);
      const isArrayResponse = Array.isArray(response);
      const returnValue = isArrayResponse ? response.find(({ id }) => !flows[id]) : response;
      if (response)
        set((state) => {
          if (isArrayResponse) {
            response.forEach((flow) => {
              state.flows[flow.id] = flow;
            });
          } else state.flows[response.id] = response;
        });
      return returnValue as Flow | undefined;
    },
    createFromFile: async (customerId, file) => {
      const res = await flowsApi.importFlow(customerId, file);
      if (res.statusText === 'error') {
        return res;
      }

      if (res.flowId) {
        const flow = await flowsApi.getFlowById(customerId, res.flowId);
        if (flow) {
          set((state) => {
            state.flows[flow.id] = flow;
          });
        }
        return flow;
      }
      return undefined;
    },
    reorderFlow: async (customerId, flowId, folderId, index) => {
      const payload = getFlowReorderPayload(get().flows, flowId, folderId, index);
      const originalFolderId = get().flows[flowId].folderId;
      const flows = await flowsApi.bulkUpdateFlows(customerId, payload);
      if (flows) {
        get().updateFlows(flows);
        const foldersToUpdate = Array.from(new Set([originalFolderId, folderId])).filter((id): id is FolderId => !!id);
        const updatedFlowLists = getFolderFlowLists(get().flows, foldersToUpdate);
        folderStore.getState().updateFolders(updatedFlowLists);
      }
      return Boolean(flows);
    },
    deleteFlow: async (customerId, flowId) => {
      const response = await flowsApi.deleteFlow(customerId, flowId);
      if (response)
        set((state) => {
          delete state.flows[flowId];
        });
      return response;
    },
    updateFlows: (flows) => {
      set((state) => {
        flows.forEach((flow) => {
          const existingFlow = state.flows[flow.id];
          if (existingFlow) Object.assign(existingFlow, flow);
        });
      });
    },
    reset: () => {
      set({ ...initialState });
    },
  })),
);

export const useFlowStore = createStoreHook<FlowsState & FlowsActions>({ store: flowStore, useShallow });
