import {
  UserValueType,
  BindingValueType,
  ValidationStatus,
  BindingValue,
  SpecificValueBinding,
  ValidationValueBinding,
} from '@jargonic/event-definition-types';
import { Box, MultiSelect } from '@mantine/core';
import { InfoLabel } from 'components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportedEventDefValue, ReportedEventDefValueId } from 'stores/wizard';
import classes from './EventDefBinding.module.css';

interface EventDefBindingProps {
  values: ReportedEventDefValue[];
  selectedIds: BindingValue[];
  eventType: UserValueType;
  isWithValidation: boolean;
  onChange: (values: BindingValue[]) => void;
}

export const testIds = {
  select: 'event-def-binding-select',
  label: 'event-def-binding-label',
};

export const EventDefBinding = ({
  values,
  selectedIds,
  eventType,
  isWithValidation,
  onChange,
}: EventDefBindingProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIds.length === 0) {
      const anyValue: BindingValue = { type: BindingValueType.ANY };
      onChange([anyValue]);
    }
  }, []);

  const groupedOptions = useMemo(() => {
    const options: {
      group: string;
      items: {
        value: BindingValueType | string;
        label: string;
      }[];
    }[] = [
      {
        group: '',
        items: [{ value: BindingValueType.ANY, label: t('common.anyValue') }],
      },
    ];

    if (eventType === UserValueType.FREE_TEXT) {
      return options;
    }

    const validationOptions = {
      group: t('common.validation'),
      items: [
        {
          value: ValidationStatus.VALID,
          label: t('common.valid'),
          disabled: !isWithValidation,
        },
        {
          value: ValidationStatus.NOT_VALID,
          label: t('common.notValid'),
          disabled: !isWithValidation,
        },
      ],
    };
    options.push(validationOptions);

    if (
      eventType === UserValueType.LIST_OF_VALUES_SINGLE_SELECTION ||
      eventType === UserValueType.LIST_OF_VALUES_MULTI_SELECTION
    ) {
      const specificValueOptions = {
        group: t('common.specificValue'),
        items: values.map((value) => ({ value: value.id, label: value.name, key: value.id })),
      };
      options.push(specificValueOptions);
    }

    return options;
  }, []);

  const handleSelectionOnChange = (selected: (ReportedEventDefValueId | string)[]) => {
    // Nothing selected or 'Any' selected after something else
    if (selected.length === 0 || (selected.length > 0 && selected[selected.length - 1] === BindingValueType.ANY)) {
      const anyValue: BindingValue = { type: BindingValueType.ANY };
      onChange([anyValue]);
    } else {
      const filteredSelected = selected
        .filter((value) => value !== BindingValueType.ANY)
        .map((value) => {
          if (value === ValidationStatus.VALID) {
            return { type: BindingValueType.VALIDATION, validationStatus: ValidationStatus.VALID };
          }
          if (value === ValidationStatus.NOT_VALID) {
            return { type: BindingValueType.VALIDATION, validationStatus: ValidationStatus.NOT_VALID };
          }
          return { type: BindingValueType.SPECIFIC, valueId: value as string };
        });

      onChange(filteredSelected);
    }
  };

  const transformBindingValuesToList = (bindingValues: BindingValue[]): string[] =>
    bindingValues.map((value) => {
      if (value.type === BindingValueType.SPECIFIC) {
        return (value as SpecificValueBinding).valueId;
      }
      if (value.type === BindingValueType.VALIDATION) {
        return (value as ValidationValueBinding).validationStatus;
      }
      if (value.type === BindingValueType.ANY) {
        return BindingValueType.ANY;
      }
      return '';
    });

  const valueToShow = transformBindingValuesToList(selectedIds);

  return (
    <Box
      style={{
        gridRowStart: 1,
        gridColumnStart: 4,
        gridColumnEnd: 'span 2',
      }}
    >
      <MultiSelect
        data={groupedOptions}
        label={
          <InfoLabel
            infoText={t('wizard.steps.reportedEvents.generalSettings.binding.tooltip')}
            data-testid={testIds.label}
          >
            {t('wizard.steps.reportedEvents.generalSettings.binding.title')}
          </InfoLabel>
        }
        classNames={classes}
        clearable
        withScrollArea={false}
        value={valueToShow}
        defaultValue={[BindingValueType.ANY]}
        onChange={handleSelectionOnChange}
        data-testid={testIds.select}
      />
    </Box>
  );
};
