import { Box, Divider, NavLink, Text } from '@mantine/core';
import { Link, To } from 'react-router-dom';
import { AiModelType } from 'stores/aiPlatform/aiModels';
import { useTranslation } from 'react-i18next';
import classes from './AiPlatformCommonSidebar.module.css';

interface AiPlatformCommonSidebarProps {
  title: string;
  activeModelType: AiModelType;
  pathname: string;
}

export const testIds = {
  title: 'ai-platform-common-sidebar-title',
  links: {
    nlp: 'ai-platform-common-sidebar-link-nlp',
    asr: 'ai-platform-common-sidebar-link-asr',
  },
};

export const AiPlatformCommonSidebar = ({ title, activeModelType, pathname }: AiPlatformCommonSidebarProps) => {
  const { t } = useTranslation();

  const getPathByType = (type: AiModelType): To => ({
    pathname,
    search: `?modelType=${type}`,
  });

  return (
    <Box component='nav' className={classes.sidebar}>
      <Text fw={600} py={12} px={16} data-testid={testIds.title}>
        {title}
      </Text>
      <Divider my={4} />
      <Box px={4}>
        <NavLink
          label={t('common.modelType.nlp')}
          component={Link}
          to={getPathByType(AiModelType.NLP)}
          className={classes.navLink}
          active={activeModelType === AiModelType.NLP}
          data-testid={testIds.links.nlp}
        />
        <NavLink
          label={t('common.modelType.asr')}
          component={Link}
          to={getPathByType(AiModelType.ASR)}
          className={classes.navLink}
          active={activeModelType === AiModelType.ASR}
          data-testid={testIds.links.asr}
        />
      </Box>
    </Box>
  );
};
