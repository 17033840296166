import { Button, Divider, Group, Input, NumberInput, Radio, SegmentedControl, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle } from '@tabler/icons-react';
import { ModalBase, LanguageSelect, languageSelectTestIds } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGenerationRouteParams } from 'routes/routes.config';
import { useAiDataSourcesStore } from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';

type FormState = {
  customerConfigSource?: string;
  customerConfigPromptsSource?: string;
  predefinedTemplatesSource?: string;
  generationMethod: 'template' | 'llm';
  language: string;
  initialSamples: number;
  samplesNumber: number;
} & (
  | {
      inputSource: 'external';
      customerConfigSource: string;
      customerConfigPromptsSource: string;
      predefinedTemplatesSource?: string;
    }
  | {
      inputSource: 'internal';
    }
);

interface TextDataGenerationSettingsModalProps {
  opened: boolean;
  sampleExecutionId?: string;
  onClose: () => void;
}

export const testIds = {
  wrapper: 'text-data-generation-settings-modal',
  inputSource: 'text-data-generation-settings-modal-input-source',
  customerConfigSource: 'text-data-generation-settings-modal-customer-config-source',
  customerConfigPromptsSource: 'text-data-generation-settings-modal-customer-config-prompts-source',
  predefinedTemplatesSource: 'text-data-generation-settings-modal-predefined-templates-source',
  generationMethod: 'text-data-generation-settings-modal-generation-method',
  language: languageSelectTestIds,
  initialSamples: 'text-data-generation-settings-modal-initial-samples',
  samplesNumber: 'text-data-generation-settings-modal-samples-number',
  cancelButton: 'text-data-generation-settings-modal-cancel-button',
  submitButton: 'text-data-generation-settings-modal-submit-button',
};

export const TextDataGenerationSettingsModal = ({
  opened,
  sampleExecutionId,
  onClose,
}: TextDataGenerationSettingsModalProps) => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<FormState>({
    initialValues: {
      inputSource: 'external',
      generationMethod: 'template',
      language: 'en',
      initialSamples: 30,
      samplesNumber: 3000,
      customerConfigSource: '',
      customerConfigPromptsSource: '',
      predefinedTemplatesSource: '',
    },
    validate: {
      initialSamples: (value) =>
        (value < 30 && t('dataGenerationPage.generateModal.initialSamplesInput.tooLowError', { count: 30 })) ||
        (value > 10000 && t('dataGenerationPage.generateModal.initialSamplesInput.tooHighError', { count: 10000 })),
      samplesNumber: (value) =>
        (value < 30 && t('dataGenerationPage.generateModal.numberOfSamplesInput.tooLowError', { count: 30 })) ||
        (value > 10000 && t('dataGenerationPage.generateModal.numberOfSamplesInput.tooHighError', { count: 10000 })),
    },
    validateInputOnBlur: true,
  });
  const flow = useFlow(flowId);
  const { createAiDataSource } = useAiDataSourcesStore(['createAiDataSource']);

  const onSubmit = async (formState: FormState) => {
    setSubmitting(true);
    // Currently internal input is unsupported
    if (formState.inputSource === 'internal') return;

    // TODO: Temporary fix for this tenant until properly fixed in BE
    const tenantId = customerId.replace(/^aiola$/, 'aiola-dev');
    const {
      generationMethod,
      initialSamples,
      language,
      samplesNumber,
      customerConfigPromptsSource,
      customerConfigSource,
    } = formState;
    const isSampleGeneration = !sampleExecutionId;

    const response = await createAiDataSource({
      flowId,
      flowVersion: flow.activeVersion!,
      tenantId,
      customerConfigPromptsSource,
      customerConfigSource,
      structuredTextGen: generationMethod === 'template',
      language,
      numSamples: isSampleGeneration ? initialSamples : samplesNumber,
      onlyTextSamples: true,
      sampleExecutionId: sampleExecutionId ?? '',
    });
    if (response) {
      onClose();
    } else {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.generateModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }
    setSubmitting(false);
  };

  return (
    <ModalBase
      opened={opened}
      title={t('dataGenerationPage.generateModal.title', { modelType: t('common.modelType.nlp') })}
      onClose={onClose}
      data-testid={testIds.wrapper}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack gap='lg'>
          <Stack gap={0}>
            <Input.Label required>{t('dataGenerationPage.generateModal.nlpConfiguration.title')}</Input.Label>
            <Input.Description my={4}>
              {t('dataGenerationPage.generateModal.nlpConfiguration.description')}
            </Input.Description>
            <SegmentedControl
              data={[
                {
                  value: 'internal',
                  label: t('dataGenerationPage.generateModal.nlpConfiguration.internal'),
                  disabled: true,
                },
                { value: 'external', label: t('dataGenerationPage.generateModal.nlpConfiguration.external') },
              ]}
              {...form.getInputProps('inputSource')}
              data-testid={testIds.inputSource}
            />
          </Stack>

          <TextInput
            label={t('dataGenerationPage.generateModal.customerConfigInput.title')}
            placeholder={t('dataGenerationPage.generateModal.customerConfigInput.placeholder')}
            required
            {...form.getInputProps('customerConfigSource')}
            data-testid={testIds.customerConfigSource}
          />

          <TextInput
            label={t('dataGenerationPage.generateModal.customerConfigPromptsInput.title')}
            placeholder={t('dataGenerationPage.generateModal.customerConfigPromptsInput.placeholder')}
            required
            {...form.getInputProps('customerConfigPromptsSource')}
            data-testid={testIds.customerConfigPromptsSource}
          />

          <TextInput
            label={t('dataGenerationPage.generateModal.predefinedTemplateInput.title')}
            placeholder={t('dataGenerationPage.generateModal.predefinedTemplateInput.placeholder')}
            {...form.getInputProps('predefinedTemplatesSource')}
            data-testid={testIds.predefinedTemplatesSource}
          />
          <Divider />

          <Stack gap='md' variant=''>
            <Radio.Group
              label={t('dataGenerationPage.generateModal.generationMethodInput.title')}
              required
              {...form.getInputProps('generationMethod')}
              data-testid={testIds.generationMethod}
            >
              <Group>
                <Radio
                  value='template'
                  label={t('dataGenerationPage.generateModal.generationMethodInput.templateBased')}
                />
                <Radio value='llm' label={t('dataGenerationPage.generateModal.generationMethodInput.llmBased')} />
              </Group>
            </Radio.Group>
            <LanguageSelect
              initialValue='en-US'
              label={t('dataGenerationPage.generateModal.languageInput.title')}
              placeholder={t('dataGenerationPage.generateModal.languageInput.placeholder')}
              onChange={(value) => form.setFieldValue('language', value)}
              required
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.initialSamplesInput.title')}
              description={t('dataGenerationPage.generateModal.initialSamplesInput.description')}
              required
              {...form.getInputProps('initialSamples', { withError: true })}
              data-testid={testIds.initialSamples}
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.numberOfSamplesInput.title')}
              description={t('dataGenerationPage.generateModal.numberOfSamplesInput.description')}
              required
              {...form.getInputProps('samplesNumber')}
              data-testid={testIds.samplesNumber}
            />
          </Stack>

          <Group justify='end' pt='lg'>
            <Button variant='subtle' onClick={onClose} data-testid={testIds.cancelButton}>
              {t('common.cancel')}
            </Button>
            <Button variant='submit' loading={submitting} type='submit' data-testid={testIds.submitButton}>
              {t('dataGenerationPage.generateModal.submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalBase>
  );
};
