// TODO: All types here will eventually be imported as a package

import { FlowId, Version } from '@flow/flow-backend-types';

export { State as DataSourceState } from './graphql.types';
export * from './graphql.types';

export interface GraphQLResponse<T> {
  data: T;
  errors?: any[];
}

export enum DataGenerationStatus {
  success = 'success',
  failure = 'failure',
}

export type DataGenExecutionId = string;

export enum StartTrainModel {
  both = 'both',
  nlp = 'nlp',
  asr = 'asr',
}

export enum TrainStatus {
  success = 'success',
  failure = 'failure',
}

export type TrainExecutionId = string;

export interface StartTrainRequest {
  executionId: DataGenExecutionId;
  trainingModel: StartTrainModel;
  asrConfig: Record<string, string>;
  nlpConfig: Record<string, string>;
}

export interface StartTrainResponse {
  status: TrainStatus;
  executionId: TrainExecutionId;
  prefectMonitorUrl: string;
}

export interface StartDataGenerationRequest {
  flowId: FlowId;
  flowVersion: Version;
  tenantId: string;
  sampleExecutionId: string;
  customerConfigSource: string;
  customerConfigPromptsSource: string;
  predefinedConfigSource?: string;
  language: string;
  numSamples: number;
  structuredTextGen: boolean;
  onlyTextSamples: boolean;
  externalTextFile?: string;
}

export interface GetSamplesRequest {
  executionId: DataGenExecutionId;
}

export interface StartDataGenerationResponse {
  status: DataGenerationStatus;
  executionId: DataGenExecutionId;
  prefectMonitorUrl: string;
}

export interface GetSamplesResponse {
  textSample: string;
  voiceSample: string;
}
