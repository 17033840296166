import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Loader, Stack, TextInput, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconUserOff } from '@tabler/icons-react';
import { NiceResponse } from 'consts';
import { User } from 'stores/auth';

interface UserPersonalDataProps {
  user: User;
  onChange: (key: keyof User, value: string) => Promise<NiceResponse>;
}

export const testIds = {
  wrapper: 'user-personal-data-panel',
  title: 'user-personal-data-title',
  firstNameInput: 'user-personal-data-first-name-input',
  lastNameInput: 'user-personal-data-last-name-input',
  emailInput: 'user-personal-data-email-input',
};

export const UserPersonalData = ({ user, onChange }: UserPersonalDataProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);

  const handleChange = (key: keyof User) => async (value: string) => {
    setLoading(true);
    const response = await onChange(key, value);
    setLoading(false);
    if (response === NiceResponse.ERROR)
      notifications.show({
        color: 'red',
        title: t('userMgmt.users.userDetails.panels.updateUserError.title'),
        message: t('userMgmt.users.userDetails.panels.updateUserError.message'),
        icon: <IconUserOff />,
      });
    return response;
  };
  const onChangeFirstName: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleChange('givenName')(event.target.value);
  const onChangeLastName: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleChange('familyName')(event.target.value);
  const onChangeEmail: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const response = await handleChange('email')(event.target.value);
    if (response === NiceResponse.DUPLICATE) setDuplicateEmailError(true);
  };
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) =>
    event.key === 'Enter' && event.currentTarget.blur();

  return (
    <Card p='lg' shadow='zero' data-testid={testIds.wrapper}>
      <Stack>
        <Title order={6} data-testid={testIds.title}>
          {t('userMgmt.users.userDetails.panels.details.personalData.title')}
        </Title>
        <Flex gap='md' w='100%'>
          <TextInput
            classNames={{ root: 'grow' }}
            defaultValue={user?.givenName}
            label={t('common.firstName')}
            readOnly={loading}
            disabled={loading}
            onBlur={onChangeFirstName}
            onKeyDown={onKeyDown}
            rightSection={loading ? <Loader size='xs' /> : null}
            data-testid={testIds.firstNameInput}
          />
          <TextInput
            classNames={{ root: 'grow' }}
            defaultValue={user?.familyName}
            label={t('common.lastName')}
            readOnly={loading}
            disabled={loading}
            onBlur={onChangeLastName}
            onKeyDown={onKeyDown}
            rightSection={loading ? <Loader size='xs' /> : null}
            data-testid={testIds.lastNameInput}
          />
        </Flex>
        <TextInput
          label={t('common.email')}
          defaultValue={user?.email}
          readOnly={loading}
          disabled
          type='email'
          error={duplicateEmailError && t('userMgmt.users.userDetails.panels.details.personalData.duplicateEmailError')}
          onBlur={onChangeEmail}
          onKeyDown={onKeyDown}
          data-testid={testIds.emailInput}
        />
      </Stack>
    </Card>
  );
};
