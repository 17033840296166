import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import cn from 'classnames';
import { Flow, FlowStatus, useFlowStore } from 'stores/flows';
import { useFolderStore } from 'stores/folders';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import { useCallback, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { useFlowTableColumns, columnTestIds, useFlowTableData, useFolderExpandedState } from './hooks';
import { flowTableDefs } from './FlowTable.defs';
import classes from './FlowTable.module.css';
import { isArchive, isFlow, isFolder, moveFlowOrFolder } from './FlowTable.utils';
import {
  ActionsCell,
  FlowTableActions,
  FlowTableEmptyState,
  emptyStateTestIds,
  tableActionsTestIds,
  actionsCellTestIds,
} from './components';
import { FlowTableState } from './FlowTable.types';

interface FlowTableProps {
  onSelectFlow: (flow: Flow) => void;
}

export const testIds = {
  getRowTestId: (id: string) => `flow-table-row-${id}`,
  table: 'flow-table-proper',
  column: { ...columnTestIds, actions: actionsCellTestIds },
  actions: tableActionsTestIds,
  emptyState: emptyStateTestIds,
  loader: 'flow-table-loader',
};

const getRowId = (id: string) => `flow-table-row-${id}`;

export const FlowTable = ({ onSelectFlow }: FlowTableProps) => {
  const { customerId } = useParams() as CustomerRouteParams;
  const {
    flows,
    loading: loadingFlows,
    reorderFlow,
    createFromFile,
  } = useFlowStore(['flows', 'loading', 'reorderFlow', 'createFromFile']);
  const { folders, loading: loadingFolders, reorderFolder } = useFolderStore(['folders', 'loading', 'reorderFolder']);
  const columns = useFlowTableColumns();
  const { data, getSubRows } = useFlowTableData(flows, folders);
  const { expanded, toggleFolder } = useFolderExpandedState(folders);
  const [reloading, setReloading] = useState(false);
  const loadingData = loadingFlows || loadingFolders;

  const afterCreateFolder: FlowTableState['afterCreateFolder'] = useCallback(
    (folder) => toggleFolder(folder.id),
    [toggleFolder],
  );

  const onImportFlow: FlowTableState['onImportFlow'] = useCallback(
    async (file) => createFromFile(customerId, file),
    [createFromFile, customerId],
  );

  const tbl = useMantineReactTable({
    data: loadingData ? [] : data,
    columns,
    getSubRows,
    ...flowTableDefs,
    getRowId: (original) => original.id,
    mantineTableBodyRowProps: ({ row }) => {
      const isActiveFlow = isFlow(row.original) && row.original.status !== FlowStatus.ARCHIVED;
      const isNormalFolder = isFolder(row.original) && !isArchive(row.original);
      const isDraggable = isActiveFlow || isNormalFolder;
      return {
        id: getRowId(row.id),
        'data-testid': testIds.getRowTestId(row.id),
        className: cn(classes.row, {
          [classes.flowRow]: isFlow(row.original),
          [classes.draggable]: isDraggable,
          [classes.notDraggable]: !isDraggable,
          [classes.archive]: isArchive(row.original),
        }),
        onClick: () => {
          if (isFlow(row.original)) onSelectFlow(row.original);
          else toggleFolder(row.original.id);
        },
      };
    },
    mantineRowDragHandleProps: ({ table }) => ({
      onDragEnd: async () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (!hoveredRow || !draggingRow) return;
        if (hoveredRow.id === draggingRow.id) return;
        const draggingThing = draggingRow.original;
        const targetThing = hoveredRow.original;
        if (!targetThing) return;
        setReloading(true);
        await moveFlowOrFolder(
          draggingThing,
          targetThing,
          (folderId, index) => reorderFlow(customerId, draggingThing.id, folderId, index),
          (index) => reorderFolder(customerId, draggingThing.id, index),
        );
        setReloading(false);
      },
    }),
    renderToolbarInternalActions: (props) => <FlowTableActions {...props} />,
    mantineTableProps: {
      // @ts-ignore
      'data-testid': testIds.table,
    },
    renderEmptyRowsFallback: () => <FlowTableEmptyState onCreateFlow={onSelectFlow} />,
    renderRowActions: ActionsCell,
    state: {
      isLoading: loadingData,
      expanded,
      afterCreateFlow: onSelectFlow,
      afterCreateFolder,
      onImportFlow,
      onSelectFlow,
    } as FlowTableState,
  });

  return (
    <>
      <MantineReactTable table={tbl} />;
      <LoadingOverlay visible={reloading} pos='absolute' data-testid={testIds.loader} />
    </>
  );
};
