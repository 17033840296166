import { EventDefNumericValidation, EventDefType, NumericValidationType, useEventDefStore } from 'stores/wizard';
import { parseNumber } from 'utils';
import { DefaultValueInput, defaultInputTestIds } from './components';
import classes from './TimeOfDayTypeLayout.module.css';
import { NewValidationSelect, ValidationList, newValidationSelectTestIds, validationListTestIds } from '../components';
import { useValidationContainerPool } from '../hooks';

interface TimeOfDayTypeLayoutProps {
  eventDefId: string;
}

export const testIds = {
  defaultInput: defaultInputTestIds,
  newValidationSelect: newValidationSelectTestIds,
  validationList: validationListTestIds,
};

export const TimeOfDayTypeLayout = ({ eventDefId }: TimeOfDayTypeLayoutProps) => {
  const { data, updateEventDef, updateEventValidation } = useEventDefStore([
    'data',
    'updateEventDef',
    'updateEventValidation',
  ]);
  const eventDef = data[eventDefId];
  const containerPool = useValidationContainerPool(eventDefId);

  if (!eventDef) return null;

  const validations = (eventDef.validations ?? []) as EventDefNumericValidation[];
  const onChangeDefaultValue = (value?: number) => updateEventDef(eventDefId, { default: value?.toString() });
  const onNewValidation = (type: NumericValidationType) =>
    updateEventValidation(eventDefId, { action: 'create', payload: type });
  const onChangeValidation = (validationId: string, values: Partial<EventDefNumericValidation>) =>
    updateEventValidation(eventDefId, { action: 'update', payload: { id: validationId, ...values } });
  const onDeleteValidation = (validationId: string) =>
    updateEventValidation(eventDefId, { action: 'delete', payload: validationId });

  return (
    <>
      <DefaultValueInput
        rootClassName={classes.defaultInput}
        value={parseNumber(eventDef.default, false)}
        disabled={!eventDef.mandatory}
        onChange={onChangeDefaultValue}
      />
      <NewValidationSelect rootClassName={classes.newValidationInput} onNewValidation={onNewValidation} />
      <ValidationList
        containerPool={containerPool}
        validations={validations}
        rootClassName={classes.validationList}
        type={EventDefType.TIME_OF_DAY}
        onChange={onChangeValidation}
        onDelete={onDeleteValidation}
      />
    </>
  );
};
