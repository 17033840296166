import { useTranslation } from 'react-i18next';
import { TimeSingleInput } from 'components';
import { Flex } from '@mantine/core';
import { useSetState } from '@mantine/hooks';

interface TimeRangeInputProps {
  max: number | null;
  min: number | null;
  onChange: (min: number | null, max: number | null) => void;
}

export const testIds = {
  input: {
    startTime: 'time-range-input-start',
    endTime: 'time-range-input-end',
  },
};

export const TimeRangeInput = ({ max, min, onChange }: TimeRangeInputProps) => {
  const { t } = useTranslation();
  const [{ minValue, maxValue }, setValues] = useSetState({ minValue: min, maxValue: max });
  const isMinNull = minValue === null;
  const isMaxNull = maxValue === null;
  const validationError = !isMaxNull && Number(minValue) > maxValue;

  const onChangeMin = (newMinValue: number | null) => {
    setValues({ minValue: newMinValue });
    onChange(newMinValue, maxValue);
  };

  const onChangeMax = (newMaxValue: number | null) => {
    setValues({ maxValue: newMaxValue });
    onChange(minValue, newMaxValue);
  };

  return (
    <Flex gap={20}>
      <TimeSingleInput
        error={isMinNull || validationError}
        value={minValue}
        label={t('wizard.steps.reportedEvents.typeSettings.types.number.validation.time.rangePlaceholder.start')}
        className='grow'
        onChange={onChangeMin}
        data-testid={testIds.input.startTime}
      />
      <TimeSingleInput
        error={isMaxNull || validationError}
        value={maxValue}
        label={t('wizard.steps.reportedEvents.typeSettings.types.number.validation.time.rangePlaceholder.end')}
        className='grow'
        onChange={onChangeMax}
        data-testid={testIds.input.endTime}
      />
    </Flex>
  );
};
