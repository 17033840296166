import { Flex, Box, Stack, Space, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { InfoTip } from 'components';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AiDataSource } from 'stores/aiPlatform';

interface DataSourceSelectOptionProps extends Pick<AiDataSource, 'id' | 'createdBy' | 'createdAt'> {
  placeholder?: boolean;
  checked?: boolean;
}

export const testIds = {
  icon: 'data-source-select-option-icon',
  user: 'data-source-select-option-user',
  date: 'data-source-select-option-date',
  tip: 'data-source-select-option-tip',
  getOptionTestId: (id: string) => `data-source-select-option--${id}`,
};

export const DataSourceSelectOption = ({
  id,
  createdBy,
  createdAt,
  checked,
  placeholder,
}: DataSourceSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Flex align='center' data-testid={testIds.getOptionTestId(id)}>
      {!placeholder && (
        <Box w={18} mx='tn'>
          {checked && <IconCheck size={18} data-testid={testIds.icon} />}
        </Box>
      )}
      <Stack gap={0}>
        <Text size='sm' data-testid={testIds.user}>{`${createdBy.givenName} ${createdBy.familyName}`}</Text>
        <Text size='xs' c='dark.2' data-testid={testIds.date}>
          {dayjs(createdAt).format(CLASSIC_DATE_TIME_FORMAT)}
        </Text>
      </Stack>
      <Space className='grow' />
      <InfoTip data-testid={testIds.tip}>
        {t('trainingPage.trainigModal.dataSourceSelect.version', { version: id })}
      </InfoTip>
    </Flex>
  );
};
