/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type StartDataGenerationInput = {
  executionId: string,
  tenantId: string,
  flowId: string,
  flowVersion: number,
  language: string,
  onlyTextSamples?: boolean | null,
  onlyVoiceSamples?: boolean | null,
  textGenerationConfigUri: string,
  generatedTextSamplesUri: string,
  samplesCount: number,
  generatedSpeechSamplesUri: string,
  sampleExecutionId?: string | null,
  triggeredBy: string,
};

export type DataGeneration = {
  __typename: "DataGeneration",
  executionId: string,
  executionMetadata: ExecutionMetadata,
  onlyTextSamples?: boolean | null,
  onlyVoiceSamples?: boolean | null,
  textGenerationConfigUri: string,
  generatedTextSamplesUri: string,
  samplesCount: number,
  generatedSpeechSamplesUri: string,
  sampleExecutionId?: string | null,
  initialSamplesCount?: number | null,
  dataFilteringNotebookUri?: string | null,
  triggeredBy: string,
  startTimestamp: number,
  endTimestamp?: number | null,
  currentStep?: Step | null,
  lastSuccessfulStep?: Step | null,
  state: State,
  error?: Error | null,
};

export type ExecutionMetadata = {
  __typename: "ExecutionMetadata",
  tenantId: string,
  flowId: string,
  flowVersion: number,
  language: string,
};

export type Step = {
  __typename: "Step",
  executionId: string,
  stepId: string,
  stepName: StepName,
  startTimestamp: number,
  endTimestamp?: number | null,
  state: State,
};

export enum StepName {
  GET_FLOW_SETTINGS = "GET_FLOW_SETTINGS",
  GENERATE_AND_STORE_TEXT_SAMPLES = "GENERATE_AND_STORE_TEXT_SAMPLES",
  GENERATE_AND_STORE_SPEECH_SAMPLES = "GENERATE_AND_STORE_SPEECH_SAMPLES",
  PROVISIONING_DATA_FILTERING_NOTEBOOK = "PROVISIONING_DATA_FILTERING_NOTEBOOK",
  DATA_GENERATION_DONE = "DATA_GENERATION_DONE",
  START_TRAINING = "START_TRAINING",
  PREPROCESS_TEXT_SAMPLES = "PREPROCESS_TEXT_SAMPLES",
  PREPROCESS_SPEECH_SAMPLES = "PREPROCESS_SPEECH_SAMPLES",
  TRAIN_NLP_MODEL = "TRAIN_NLP_MODEL",
  TRAIN_ASR_MODEL = "TRAIN_ASR_MODEL",
  EVALUATE_NLP_MODEL = "EVALUATE_NLP_MODEL",
  EVALUATE_ASR_MODEL = "EVALUATE_ASR_MODEL",
  TRAINING_DONE = "TRAINING_DONE",
}


export enum State {
  STARTED = "STARTED",
  DONE = "DONE",
  FAILED = "FAILED",
}


export type Error = {
  __typename: "Error",
  type: ErrorType,
  message: string,
};

export enum ErrorType {
  ERROR_GETTING_FLOW_SETTINGS = "ERROR_GETTING_FLOW_SETTINGS",
  ERROR_GENERATING_TEXT_SAMPLES = "ERROR_GENERATING_TEXT_SAMPLES",
  ERROR_GENERATING_SPEECH_SAMPLES = "ERROR_GENERATING_SPEECH_SAMPLES",
  ERROR_STORING_TEXT_SAMPLES = "ERROR_STORING_TEXT_SAMPLES",
  ERROR_STORING_SPEECH_SAMPLES = "ERROR_STORING_SPEECH_SAMPLES",
  ERROR_PROVISIONING_DATA_FILTERING_NOTEBOOK = "ERROR_PROVISIONING_DATA_FILTERING_NOTEBOOK",
  ERROR_PREPROCESSING_TEXT_SAMPLES = "ERROR_PREPROCESSING_TEXT_SAMPLES",
  ERROR_PREPROCESSING_SPEECH_SAMPLES = "ERROR_PREPROCESSING_SPEECH_SAMPLES",
  ERROR_TRAINING_NLP_MODEL = "ERROR_TRAINING_NLP_MODEL",
  ERROR_TRAINING_ASR_MODEL = "ERROR_TRAINING_ASR_MODEL",
  ERROR_EVALUATING_NLP_MODEL = "ERROR_EVALUATING_NLP_MODEL",
  ERROR_EVALUATING_ASR_MODEL = "ERROR_EVALUATING_ASR_MODEL",
}


export type AddDataFilteringNotebookUriInput = {
  executionId: string,
  dataFilteringNotebookUri: string,
};

export type RaiseErrorInput = {
  stepId: string,
  errorType: ErrorType,
  errorMessage: string,
};

export type StartTrainingExecutionInput = {
  executionId: string,
  dataGenerationId: string,
  modelsToTrain: Array< ModelType >,
  triggeredBy: string,
};

export enum ModelType {
  NLP = "NLP",
  ASR = "ASR",
}


export type TrainingExecution = {
  __typename: "TrainingExecution",
  executionId: string,
  executionMetadata: ExecutionMetadata,
  dataGeneration?: DataGeneration | null,
  triggeredBy: string,
  trainedModels?:  Array<TrainedModel > | null,
  startTimestamp: number,
  endTimestamp?: number | null,
  currentSteps?:  Array<Step > | null,
  previousSteps?:  Array<Step > | null,
  state: State,
  error?: Error | null,
};

export type TrainedModel = {
  __typename: "TrainedModel",
  modelType: ModelType,
  sagemakerExperimentRunId: string,
  modelArtifactUri: string,
  evaluationResultsUri: string,
};

export type AddTrainedModelInput = {
  executionId: string,
  modelType: ModelType,
  sagemakerExperimentRunId: string,
  modelArtifactUri: string,
  evaluationResultsUri: string,
};

export type StartStepInput = {
  executionId: string,
  stepName: StepName,
};

export type CreateFlowConfigInput = {
  flowId: string,
  flowVersion: number,
  tenantId: string,
  labels: Array< LabelInput >,
  additionalInformation: FlowAdditionalInformationInput,
  spansSubsets: string,
  promptsDefinitions: PromptsDefinitionsInput,
  general: GeneralConfigurationInput,
};

export type LabelInput = {
  labelName: string,
  labelType: LabelType,
  jargonicMapping: string,
  hierarchicalType: string,
  synonyms: Array< string >,
  isEnrichable: boolean,
  isInjectable: boolean,
  isAtEnd: boolean,
  values: Array< string >,
  explanation: string,
  fewShots: Array< FewShotsExampleInput >,
  extractionExamplesPrompt: string,
};

export enum LabelType {
  CONTAINER = "CONTAINER",
  EVENT = "EVENT",
}


export type FewShotsExampleInput = {
  value: string,
  sentence: string,
  output: string,
};

export type FlowAdditionalInformationInput = {
  minValues: number,
  desiredValues: Array< string >,
};

export type PromptsDefinitionsInput = {
  generalSystemPrompt: string,
  enrichmentPrompt: string,
  reportGeneratePrompt: Array< string >,
  chitchatPrompt: string,
  negativeExamplePrompt: string,
  sanityAugmentationPrompt: string,
  asrAugmentationPrompt: string,
};

export type GeneralConfigurationInput = {
  replaceValues: string,
};

export type FlowConfig = {
  __typename: "FlowConfig",
  flowConfigId: string,
  flowId: string,
  flowVersion: number,
  tenantId: string,
  labels:  Array<Label >,
  additionalInformation: FlowAdditionalInformation,
  spansSubsets: string,
  promptsDefinitions: PromptsDefinitions,
  general: GeneralConfiguration,
};

export type Label = {
  __typename: "Label",
  labelName: string,
  labelType: LabelType,
  jargonicMapping: string,
  hierarchicalType: string,
  synonyms: Array< string >,
  isEnrichable: boolean,
  isInjectable: boolean,
  isAtEnd: boolean,
  values: Array< string >,
  explanation: string,
  fewShots:  Array<FewShotsExample >,
  extractionExamplesPrompt: string,
};

export type FewShotsExample = {
  __typename: "FewShotsExample",
  value: string,
  sentence: string,
  output: string,
};

export type FlowAdditionalInformation = {
  __typename: "FlowAdditionalInformation",
  minValues: number,
  desiredValues: Array< string >,
};

export type PromptsDefinitions = {
  __typename: "PromptsDefinitions",
  generalSystemPrompt: string,
  enrichmentPrompt: string,
  reportGeneratePrompt: Array< string >,
  chitchatPrompt: string,
  negativeExamplePrompt: string,
  sanityAugmentationPrompt: string,
  asrAugmentationPrompt: string,
};

export type GeneralConfiguration = {
  __typename: "GeneralConfiguration",
  replaceValues: string,
};

export type UpdateFlowConfigInput = {
  flowId: string,
  flowVersion: number,
  tenantId: string,
  labels?: Array< LabelInput > | null,
  additionalInformation?: FlowAdditionalInformationInput | null,
  spansSubsets?: string | null,
  promptsDefinitions?: PromptsDefinitionsInput | null,
  general?: GeneralConfigurationInput | null,
};

export type PublishModelInput = {
  modelId: string,
  artifactUri: string,
};

export type RegisteredModel = {
  __typename: "RegisteredModel",
  modelId: string,
  publishedAt: number,
  artifactUri: string,
  isPublished: boolean,
  invalidatedAt?: number | null,
};

export type ListDataGenerationsInput = {
  tenantId: string,
  flowId: string,
  flowVersion: number,
  state?: State | null,
  paginationToken?: string | null,
};

export type PaginatedDataGenerations = {
  __typename: "PaginatedDataGenerations",
  dataGenerations?:  Array<DataGeneration > | null,
  paginationToken?: string | null,
};

export type ListTrainingExecutionsInput = {
  tenantId: string,
  flowId: string,
  flowVersion: number,
  modelType?: ModelType | null,
  state?: State | null,
  paginationToken?: string | null,
};

export type PaginatedTrainingExecutions = {
  __typename: "PaginatedTrainingExecutions",
  trainingExecutions?:  Array<TrainingExecution > | null,
  paginationToken?: string | null,
};

export type GetFlowConfigInput = {
  flowId: string,
  flowVersion: number,
  tenantId: string,
};

export type StartDataGenerationMutationVariables = {
  startDataGenerationInput: StartDataGenerationInput,
};

export type StartDataGenerationMutation = {
  startDataGeneration?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type AddDataFilteringNotebookUriMutationVariables = {
  addDataFilteringNotebookUriInput: AddDataFilteringNotebookUriInput,
};

export type AddDataFilteringNotebookUriMutation = {
  addDataFilteringNotebookUri?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type EndDataGenerationMutationVariables = {
  executionId: string,
};

export type EndDataGenerationMutation = {
  endDataGeneration?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type RaiseErrorDataGenerationMutationVariables = {
  raiseErrorInput: RaiseErrorInput,
};

export type RaiseErrorDataGenerationMutation = {
  raiseErrorDataGeneration?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type StartTrainingExecutionMutationVariables = {
  startTrainingExecutionInput: StartTrainingExecutionInput,
};

export type StartTrainingExecutionMutation = {
  startTrainingExecution?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type AddTrainedModelMutationVariables = {
  addTrainedModelInput: AddTrainedModelInput,
};

export type AddTrainedModelMutation = {
  addTrainedModel?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type EndTrainingExecutionMutationVariables = {
  executionId: string,
};

export type EndTrainingExecutionMutation = {
  endTrainingExecution?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type RaiseErrorTrainingExecutionMutationVariables = {
  raiseErrorInput: RaiseErrorInput,
};

export type RaiseErrorTrainingExecutionMutation = {
  raiseErrorTrainingExecution?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type StartStepMutationVariables = {
  startStepInput: StartStepInput,
};

export type StartStepMutation = {
  startStep?:  {
    __typename: "Step",
    executionId: string,
    stepId: string,
    stepName: StepName,
    startTimestamp: number,
    endTimestamp?: number | null,
    state: State,
  } | null,
};

export type EndStepMutationVariables = {
  stepId: string,
};

export type EndStepMutation = {
  endStep?:  {
    __typename: "Step",
    executionId: string,
    stepId: string,
    stepName: StepName,
    startTimestamp: number,
    endTimestamp?: number | null,
    state: State,
  } | null,
};

export type CreateFlowConfigMutationVariables = {
  createFlowConfigInput: CreateFlowConfigInput,
};

export type CreateFlowConfigMutation = {
  createFlowConfig?:  {
    __typename: "FlowConfig",
    flowConfigId: string,
    flowId: string,
    flowVersion: number,
    tenantId: string,
    labels:  Array< {
      __typename: "Label",
      labelName: string,
      labelType: LabelType,
      jargonicMapping: string,
      hierarchicalType: string,
      synonyms: Array< string >,
      isEnrichable: boolean,
      isInjectable: boolean,
      isAtEnd: boolean,
      values: Array< string >,
      explanation: string,
      fewShots:  Array< {
        __typename: "FewShotsExample",
        value: string,
        sentence: string,
        output: string,
      } >,
      extractionExamplesPrompt: string,
    } >,
    additionalInformation:  {
      __typename: "FlowAdditionalInformation",
      minValues: number,
      desiredValues: Array< string >,
    },
    spansSubsets: string,
    promptsDefinitions:  {
      __typename: "PromptsDefinitions",
      generalSystemPrompt: string,
      enrichmentPrompt: string,
      reportGeneratePrompt: Array< string >,
      chitchatPrompt: string,
      negativeExamplePrompt: string,
      sanityAugmentationPrompt: string,
      asrAugmentationPrompt: string,
    },
    general:  {
      __typename: "GeneralConfiguration",
      replaceValues: string,
    },
  } | null,
};

export type UpdateFlowConfigMutationVariables = {
  updateFlowConfigInput: UpdateFlowConfigInput,
};

export type UpdateFlowConfigMutation = {
  updateFlowConfig?:  {
    __typename: "FlowConfig",
    flowConfigId: string,
    flowId: string,
    flowVersion: number,
    tenantId: string,
    labels:  Array< {
      __typename: "Label",
      labelName: string,
      labelType: LabelType,
      jargonicMapping: string,
      hierarchicalType: string,
      synonyms: Array< string >,
      isEnrichable: boolean,
      isInjectable: boolean,
      isAtEnd: boolean,
      values: Array< string >,
      explanation: string,
      fewShots:  Array< {
        __typename: "FewShotsExample",
        value: string,
        sentence: string,
        output: string,
      } >,
      extractionExamplesPrompt: string,
    } >,
    additionalInformation:  {
      __typename: "FlowAdditionalInformation",
      minValues: number,
      desiredValues: Array< string >,
    },
    spansSubsets: string,
    promptsDefinitions:  {
      __typename: "PromptsDefinitions",
      generalSystemPrompt: string,
      enrichmentPrompt: string,
      reportGeneratePrompt: Array< string >,
      chitchatPrompt: string,
      negativeExamplePrompt: string,
      sanityAugmentationPrompt: string,
      asrAugmentationPrompt: string,
    },
    general:  {
      __typename: "GeneralConfiguration",
      replaceValues: string,
    },
  } | null,
};

export type PublishModelMutationVariables = {
  publishModelInput: PublishModelInput,
};

export type PublishModelMutation = {
  publishModel?:  {
    __typename: "RegisteredModel",
    modelId: string,
    publishedAt: number,
    artifactUri: string,
    isPublished: boolean,
    invalidatedAt?: number | null,
  } | null,
};

export type InvalidateModelMutationVariables = {
  modelId: string,
};

export type InvalidateModelMutation = {
  invalidateModel?:  {
    __typename: "RegisteredModel",
    modelId: string,
    publishedAt: number,
    artifactUri: string,
    isPublished: boolean,
    invalidatedAt?: number | null,
  } | null,
};

export type GetDataGenerationQueryVariables = {
  executionId: string,
};

export type GetDataGenerationQuery = {
  getDataGeneration?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type ListDataGenerationsQueryVariables = {
  listDataGenerationsInput: ListDataGenerationsInput,
};

export type ListDataGenerationsQuery = {
  listDataGenerations?:  {
    __typename: "PaginatedDataGenerations",
    dataGenerations?:  Array< {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } > | null,
    paginationToken?: string | null,
  } | null,
};

export type GetTrainingExecutionQueryVariables = {
  executionId: string,
};

export type GetTrainingExecutionQuery = {
  getTrainingExecution?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type ListTrainingExecutionsQueryVariables = {
  listTrainingExecutionsInput: ListTrainingExecutionsInput,
};

export type ListTrainingExecutionsQuery = {
  listTrainingExecutions?:  {
    __typename: "PaginatedTrainingExecutions",
    trainingExecutions?:  Array< {
      __typename: "TrainingExecution",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      dataGeneration?:  {
        __typename: "DataGeneration",
        executionId: string,
        executionMetadata:  {
          __typename: "ExecutionMetadata",
          tenantId: string,
          flowId: string,
          flowVersion: number,
          language: string,
        },
        onlyTextSamples?: boolean | null,
        onlyVoiceSamples?: boolean | null,
        textGenerationConfigUri: string,
        generatedTextSamplesUri: string,
        samplesCount: number,
        generatedSpeechSamplesUri: string,
        sampleExecutionId?: string | null,
        initialSamplesCount?: number | null,
        dataFilteringNotebookUri?: string | null,
        triggeredBy: string,
        startTimestamp: number,
        endTimestamp?: number | null,
        currentStep?:  {
          __typename: "Step",
          executionId: string,
          stepId: string,
          stepName: StepName,
          startTimestamp: number,
          endTimestamp?: number | null,
          state: State,
        } | null,
        lastSuccessfulStep?:  {
          __typename: "Step",
          executionId: string,
          stepId: string,
          stepName: StepName,
          startTimestamp: number,
          endTimestamp?: number | null,
          state: State,
        } | null,
        state: State,
        error?:  {
          __typename: "Error",
          type: ErrorType,
          message: string,
        } | null,
      } | null,
      triggeredBy: string,
      trainedModels?:  Array< {
        __typename: "TrainedModel",
        modelType: ModelType,
        sagemakerExperimentRunId: string,
        modelArtifactUri: string,
        evaluationResultsUri: string,
      } > | null,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentSteps?:  Array< {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } > | null,
      previousSteps?:  Array< {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } > | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } > | null,
    paginationToken?: string | null,
  } | null,
};

export type GetStepQueryVariables = {
  stepId: string,
};

export type GetStepQuery = {
  getStep?:  {
    __typename: "Step",
    executionId: string,
    stepId: string,
    stepName: StepName,
    startTimestamp: number,
    endTimestamp?: number | null,
    state: State,
  } | null,
};

export type GetFlowConfigQueryVariables = {
  getFlowConfigInput: GetFlowConfigInput,
};

export type GetFlowConfigQuery = {
  getFlowConfig?:  {
    __typename: "FlowConfig",
    flowConfigId: string,
    flowId: string,
    flowVersion: number,
    tenantId: string,
    labels:  Array< {
      __typename: "Label",
      labelName: string,
      labelType: LabelType,
      jargonicMapping: string,
      hierarchicalType: string,
      synonyms: Array< string >,
      isEnrichable: boolean,
      isInjectable: boolean,
      isAtEnd: boolean,
      values: Array< string >,
      explanation: string,
      fewShots:  Array< {
        __typename: "FewShotsExample",
        value: string,
        sentence: string,
        output: string,
      } >,
      extractionExamplesPrompt: string,
    } >,
    additionalInformation:  {
      __typename: "FlowAdditionalInformation",
      minValues: number,
      desiredValues: Array< string >,
    },
    spansSubsets: string,
    promptsDefinitions:  {
      __typename: "PromptsDefinitions",
      generalSystemPrompt: string,
      enrichmentPrompt: string,
      reportGeneratePrompt: Array< string >,
      chitchatPrompt: string,
      negativeExamplePrompt: string,
      sanityAugmentationPrompt: string,
      asrAugmentationPrompt: string,
    },
    general:  {
      __typename: "GeneralConfiguration",
      replaceValues: string,
    },
  } | null,
};

export type GetPublishedModelQueryVariables = {
  modelId: string,
};

export type GetPublishedModelQuery = {
  getPublishedModel?:  {
    __typename: "RegisteredModel",
    modelId: string,
    publishedAt: number,
    artifactUri: string,
    isPublished: boolean,
    invalidatedAt?: number | null,
  } | null,
};

export type ListModelsQueryVariables = {
  modelId: string,
};

export type ListModelsQuery = {
  listModels?:  Array< {
    __typename: "RegisteredModel",
    modelId: string,
    publishedAt: number,
    artifactUri: string,
    isPublished: boolean,
    invalidatedAt?: number | null,
  } > | null,
};

export type OnDataGenerationChangeSubscriptionVariables = {
  executionId?: string | null,
};

export type OnDataGenerationChangeSubscription = {
  onDataGenerationChange?:  {
    __typename: "DataGeneration",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    onlyTextSamples?: boolean | null,
    onlyVoiceSamples?: boolean | null,
    textGenerationConfigUri: string,
    generatedTextSamplesUri: string,
    samplesCount: number,
    generatedSpeechSamplesUri: string,
    sampleExecutionId?: string | null,
    initialSamplesCount?: number | null,
    dataFilteringNotebookUri?: string | null,
    triggeredBy: string,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    lastSuccessfulStep?:  {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type OnTrainingExecutionChangeSubscriptionVariables = {
  executionId?: string | null,
};

export type OnTrainingExecutionChangeSubscription = {
  onTrainingExecutionChange?:  {
    __typename: "TrainingExecution",
    executionId: string,
    executionMetadata:  {
      __typename: "ExecutionMetadata",
      tenantId: string,
      flowId: string,
      flowVersion: number,
      language: string,
    },
    dataGeneration?:  {
      __typename: "DataGeneration",
      executionId: string,
      executionMetadata:  {
        __typename: "ExecutionMetadata",
        tenantId: string,
        flowId: string,
        flowVersion: number,
        language: string,
      },
      onlyTextSamples?: boolean | null,
      onlyVoiceSamples?: boolean | null,
      textGenerationConfigUri: string,
      generatedTextSamplesUri: string,
      samplesCount: number,
      generatedSpeechSamplesUri: string,
      sampleExecutionId?: string | null,
      initialSamplesCount?: number | null,
      dataFilteringNotebookUri?: string | null,
      triggeredBy: string,
      startTimestamp: number,
      endTimestamp?: number | null,
      currentStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      lastSuccessfulStep?:  {
        __typename: "Step",
        executionId: string,
        stepId: string,
        stepName: StepName,
        startTimestamp: number,
        endTimestamp?: number | null,
        state: State,
      } | null,
      state: State,
      error?:  {
        __typename: "Error",
        type: ErrorType,
        message: string,
      } | null,
    } | null,
    triggeredBy: string,
    trainedModels?:  Array< {
      __typename: "TrainedModel",
      modelType: ModelType,
      sagemakerExperimentRunId: string,
      modelArtifactUri: string,
      evaluationResultsUri: string,
    } > | null,
    startTimestamp: number,
    endTimestamp?: number | null,
    currentSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    previousSteps?:  Array< {
      __typename: "Step",
      executionId: string,
      stepId: string,
      stepName: StepName,
      startTimestamp: number,
      endTimestamp?: number | null,
      state: State,
    } > | null,
    state: State,
    error?:  {
      __typename: "Error",
      type: ErrorType,
      message: string,
    } | null,
  } | null,
};

export type OnStepChangeSubscriptionVariables = {
  executionId?: string | null,
  stepId?: string | null,
};

export type OnStepChangeSubscription = {
  onStepChange?:  {
    __typename: "Step",
    executionId: string,
    stepId: string,
    stepName: StepName,
    startTimestamp: number,
    endTimestamp?: number | null,
    state: State,
  } | null,
};