import { Badge } from '@mantine/core';
import { AiModelStatus } from 'stores/aiPlatform/aiModels';
import { statusColors, statusLabels } from './AiModelStatusBadge.const';

interface AiModelStatusBadgeProps {
  status: AiModelStatus;
}

export const testIds = {
  badge: 'ai-model-status-badge',
};

export const AiModelStatusBadge = ({ status, ...props }: AiModelStatusBadgeProps) => (
  <Badge variant='light' radius='sm' color={statusColors[status]} data-testid={testIds.badge} {...props}>
    {statusLabels[status]}
  </Badge>
);
