import { config } from 'services/config';
import { hermes } from 'services/hermes';
import {
  GetDataGenerationQuery,
  GetDataGenerationQueryVariables,
  GraphQLResponse,
  ListDataGenerationsInput,
  ListDataGenerationsQuery,
  ListDataGenerationsQueryVariables,
  StartDataGenerationRequest,
  StartDataGenerationResponse,
} from '../../aiPlatformBackendTypes';
import { AiDataSource } from '../aiDataSources.types';
import { dataSourceFromApi } from '../aiDataSources.adapters';
import { getDataGenerationQuery, listDataGenerationsQuery } from './aiDataSources.gql';

export const POLLING_INTERVAL = 5000;

export const aiDataSourcesApi = {
  getAiDataSource: async (executionId: string): Promise<AiDataSource | undefined> => {
    const query = getDataGenerationQuery;
    const variables: GetDataGenerationQueryVariables = {
      executionId,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<GetDataGenerationQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );
    return dataSourceFromApi(data.getDataGeneration) ?? undefined;
  },

  getAiDataSources: async (listDataGenerationsInput: ListDataGenerationsInput): Promise<AiDataSource[] | undefined> => {
    const query = listDataGenerationsQuery;
    const variables: ListDataGenerationsQueryVariables = {
      listDataGenerationsInput,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<ListDataGenerationsQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );

    return data?.listDataGenerations?.dataGenerations
      ?.map(dataSourceFromApi)
      .filter((dataSource): dataSource is AiDataSource => dataSource !== null);
  },

  subscribeToAiDataSource: (id: string, callback: (aiModel: AiDataSource) => void) =>
    setInterval(async () => {
      const dataSource = await aiDataSourcesApi.getAiDataSource(id);
      if (dataSource) callback(dataSource);
    }, POLLING_INTERVAL),
  startDataSourceGeneration: async (input: StartDataGenerationRequest): Promise<StartDataGenerationResponse> => {
    const url = `${config.aipApiHost}/data-generation/start`;
    const { data } = await hermes.post<StartDataGenerationResponse>(url, input);
    return data;
  },
};
