import { useCallback, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import { CreatePolicyPayload, UpdatePolicyPayload, useGroupArray, useUserArray, useUserMgmtStore, useUserMinifier } from 'stores/userMgmt';
import { columns } from './PolicyTable.columns';
import { policyTableDefs } from './PolicyTable.defs';
import { PolicyTableState } from './PolicyTable.types';
import {
  PolicyTableActions,
  PolicyTableEmptyState,
  PolicyTableRowActions,
  actionsTestIds,
  emptyStateTestIds,
  rowActionsTestIds,
} from './components';

export const testIds = {
  getRowTestId: (id: string) => `policy-table-row-${id}`,
  table: 'policy-table',
  emptyState: emptyStateTestIds,
  actions: actionsTestIds,
  rowActions: rowActionsTestIds,
};

export const PolicyTable = () => {
  const { customerId } = useParams() as CustomerRouteParams;
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { groups, policies, upsertPolicy, deletePolicy } = useUserMgmtStore([
    'groups',
    'policies',
    'upsertPolicy',
    'deletePolicy',
  ]);
  const search = params.get('search') || '';
  const data = useMemo(() => Object.values(policies), [policies]);
  const userArray = useUserArray()
  const groupArray = useGroupArray();
  const getMiniUsers = useUserMinifier();

  const onSearch = useCallback((value: string) => {
    setParams({ search: value });
  }, []);

  const onCreatePolicy: PolicyTableState['onCreatePolicy'] = useCallback(
    async (name) => {
      const payload: CreatePolicyPayload = { name, users: [], permissions: [] };
      return upsertPolicy(customerId, { action: 'create', payload });
    },
    [customerId],
  );

  const onMapPolicy: PolicyTableState['onMapPolicy'] = useCallback(
    async (policyId, userIds, groupIds) => {
      const selectedMiniUsers = getMiniUsers(userIds);
      const selectedGroups = groupIds.map(groupId => groups[groupId]);
      const policy = policies[policyId];
      const payload: UpdatePolicyPayload = { ...policy, users: selectedMiniUsers, groups: selectedGroups };
      const response = await upsertPolicy(customerId, { action: 'update', policyId, payload });
      return Boolean(response);
    },
    [customerId, policies],
  );

  const onCreatePermission: PolicyTableState['onCreatePermission'] = useCallback(
    async (policyId, permission) => {
      const policy = policies[policyId];
      const payload: UpdatePolicyPayload = { ...policy, permissions: [...policy.permissions, permission] };
      const response = await upsertPolicy(customerId, { action: 'update', policyId, payload });
      return Boolean(response);
    },
    [customerId, policies],
  );

  const onDeletePolicy: PolicyTableState['onDeletePolicy'] = useCallback(
    async (policyId) => deletePolicy(customerId, policyId),
    [customerId],
  );

  const table = useMantineReactTable({
    data,
    columns,
    ...policyTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: 'cursor-pointer',
      onClick: () => navigate(ROUTES.USER_MGMT(customerId).POLICY(row.id)),
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <PolicyTableEmptyState {...props} />,
    renderTopToolbar: (props) => <PolicyTableActions {...props} />,
    renderRowActions: (props) => <PolicyTableRowActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      globalFilter: search,
      onSearch,
      users: userArray,
      groups: groupArray,
      onCreatePolicy,
      onDeletePolicy,
      onMapPolicy,
      onCreatePermission,
    } as PolicyTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
