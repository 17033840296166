import { useCallback, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import { CreateUserPayload, useUserMgmtStore } from 'stores/userMgmt';
import { columns } from './UserTable.columns';
import { userTableDefs } from './UserTable.defs';
import { UserTableState } from './UserTable.types';
import { UserTableActions, UserTableEmptyState, actionsTestIds, emptyStateTestIds } from './components';

export const testIds = {
  getRowTestId: (id: string) => `user-table-row-${id}`,
  table: 'user-table',
  actions: actionsTestIds,
  emptyState: emptyStateTestIds,
};

export const UserTable = () => {
  const { customerId } = useParams() as CustomerRouteParams;
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { users, upsertUser, createUsersFromFile } = useUserMgmtStore(['users', 'upsertUser', 'createUsersFromFile']);
  const search = params.get('search') || '';
  const data = useMemo(() => Object.values(users), [users]);

  const onSearch = useCallback((value: string) => {
    setParams({ search: value });
  }, []);

  const onCreateUser = useCallback(
    async (payload: CreateUserPayload) => {
      const response = await upsertUser(customerId, { action: 'create', payload });
      return Boolean(response);
    },
    [customerId],
  );

  const onUploadFile = useCallback((file: File) => createUsersFromFile(customerId, file), [customerId]);

  const table = useMantineReactTable({
    data,
    columns,
    ...userTableDefs,
    renderTopToolbar: (props) => <UserTableActions {...props} />,
    renderEmptyRowsFallback: (props) => <UserTableEmptyState {...props} />,
    mantineTableBodyRowProps: ({ row }) => ({
      className: 'cursor-pointer',
      onClick: () => navigate(ROUTES.USER_MGMT(customerId).USER(row.id)),
      'data-testid': testIds.getRowTestId(row.id),
    }),
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: { globalFilter: search, onSearch, onCreateUser, onUploadFile } as UserTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
