import { useCallback, useMemo } from 'react';
import { useUserMgmtStore } from './userMgmt.store';

export function usePolicyArray() {
  const { policies } = useUserMgmtStore(['policies']);
  return useMemo(() => Object.values(policies), [policies]);
}

export function useUserArray() {
  const { users } = useUserMgmtStore(['users']);
  return useMemo(() => Object.values(users), [users]);
}

export function useGroupArray() {
  const { groups } = useUserMgmtStore(['groups']);
  return useMemo(() => Object.values(groups), [groups]);
}

export function useUserMinifier() {
  const { users } = useUserMgmtStore(['users']);
  return useCallback(
    (userIds: string[]) =>
      userIds
        .map((id) => users[id])
        .filter(Boolean)
        .map(({ userId, givenName, familyName }) => ({ userId, givenName, familyName })),
    [users],
  );
}
