import { Box, Flex, Loader } from '@mantine/core';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { AiModelType, useAiDataSourcesStore } from 'stores/aiPlatform';
import { useFlow, useFlowStore } from 'stores/flows';
import { DataGenerationRouteParams, ROUTES } from 'routes/routes.config';
import { AiPlatformCommonSidebar, testIds as sidebarTestIds } from 'layouts/AiPlatformLayout/AiPlatformCommonSidebar';
import { useTranslation } from 'react-i18next';

export const testIds = {
  sidebar: sidebarTestIds,
  loader: 'data-generation-layout-loader',
};

export const DataGenerationLayout = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const [params] = useSearchParams();
  const flow = useFlow(flowId);
  const { loading: dataSourcesLoading } = useAiDataSourcesStore(['loading']);
  const { loading: flowsLoading } = useFlowStore(['loading']);
  const isLoading = !flow || flowsLoading || dataSourcesLoading;
  const activeModelType = params.get('modelType') as AiModelType;

  return (
    <Flex h='100%' w='100%' style={{ overflow: 'hidden' }}>
      <AiPlatformCommonSidebar
        title={t('dataGenerationPage.sidebar.title')}
        activeModelType={activeModelType}
        pathname={ROUTES.AI_PLATFORM(customerId, flowId).DATA_GENERATION}
      />
      <Box flex={1} h='100%'>
        {isLoading ? <Loader type='bars' my='xl' mx='auto' data-testid={testIds.loader} /> : <Outlet />}
      </Box>
    </Flex>
  );
};
