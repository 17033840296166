import { HTMLPropsRef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { AiDataTextSample } from 'stores/aiPlatform';
import { getCellId, useColumns } from './DataGenTextSamplesTable.columns';
import { dataGenTextSamplesTableDefs } from './DataGenTextSamplesTable.defs';

export const testIds = {
  table: 'ai-model-table',
};

export const getRowTestId = (id: string) => `ai-data-gen-table-row-${id}`;

export type DataGenTextSamplesTableProps = {
  samples: AiDataTextSample[];
  isLoading?: boolean;
  maxTableHeight?: number;
};

export const DataGenTextSamplesTable = ({
  samples,
  isLoading = false,
  maxTableHeight,
}: DataGenTextSamplesTableProps) => {
  const columns = useColumns(samples);

  const table = useMantineReactTable({
    data: samples,
    columns,
    ...dataGenTextSamplesTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: '',
      'data-testid': getRowTestId(row.id),
    }),
    mantineTableProps: {
      'data-testid': testIds.table,
    } as HTMLPropsRef<HTMLTableElement>,
    mantineLoadingOverlayProps: () => ({ visible: false, overlayProps: {} }),
    mantineSkeletonProps: ({ column, row }) => ({
      size: 'medium',
      color: 'gray',
      'data-testid': getCellId(row.id, column.id, true),
    }),
    state: { isLoading },
    mantineTableContainerProps: {
      style: { maxHeight: maxTableHeight },
    },
  });

  return <MantineReactTable table={table} />;
};
