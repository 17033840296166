import { ElementProps, Input, Radio, RadioGroupProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Label } from 'stores/wizard';

interface CreateContainerLabelSelectProps
  extends Omit<RadioGroupProps, 'children'>,
    Omit<ElementProps<'div', keyof RadioGroupProps>, 'children'> {
  labels: Label[];
}

export const testIds = {
  getRadioTestId: (labelId: string) => `create-container-menu-radio-${labelId}`,
  radioRegex: /create-container-menu-radio-.*/,
};

export const CreateContainerLabelSelect = ({ labels, ...props }: CreateContainerLabelSelectProps) => {
  const { t } = useTranslation();

  return (
    <Radio.Group label={t('wizard.steps.labeling.containerTable.addItemMenu.labelList.title')} required {...props}>
      {labels.map((label) => (
        <Radio
          key={label.id}
          label={label.name}
          value={label.id}
          c={label.attributes.color}
          color={label.attributes.color}
          mt='sm'
          size='xs'
          data-testid={testIds.getRadioTestId(label.id)}
        />
      ))}
      {labels.length === 0 && (
        <Input.Placeholder fz='xs'>
          {t('wizard.steps.labeling.containerTable.addItemMenu.labelList.placeholder')}
        </Input.Placeholder>
      )}
    </Radio.Group>
  );
};
