import { useSetState } from '@mantine/hooks';
import { Folder, FolderId } from 'stores/folders';

export function useFolderExpandedState(folders: Record<FolderId, Folder>) {
  const [expanded, setExpanded] = useSetState<Record<FolderId, boolean>>(
    Object.values(folders).reduce((acc, folder) => ({ ...acc, [folder.id]: true }), {}),
  );

  const toggleFolder = (folderId: FolderId) => setExpanded({ [folderId]: !expanded[folderId] });

  return {
    expanded,
    toggleFolder,
  };
}
