import { CustomerId } from 'stores/customers';
import { GroupSliceActions, GroupSliceState, UpsertGroupArgs, UserMgmtSlice } from '../userMgmt.slices.types';
import { groupApi } from './groups.api';

export const groupsInitialState: GroupSliceState = {
  fetchedGroups: false,
  loadingGroups: false,
  groups: {},
};

async function upsertGroup(customerId: CustomerId, { action, groupId, payload }: UpsertGroupArgs) {
  switch (action) {
    case 'create': {
      return groupApi.createGroup(customerId, payload);
    }
    case 'update':
      return groupApi.updateGroup(customerId, groupId, payload);
    default:
      return undefined;
  }
}

export const groupSlice: UserMgmtSlice<GroupSliceState, GroupSliceActions> = (set, get) => ({
  ...groupsInitialState,
  fetchGroups: async (customerId) => {
    if (get().fetchedGroups) return true;
    set({ loadingGroups: true });
    const groups = await groupApi.getGroups(customerId);
    if (groups) {
      const groupsMap = groups.reduce((acc, group, index) => {
        const groupId = group.id || `group_${index}`;
        return { ...acc, [groupId]: group };
      }, {});
      set({ groups: groupsMap });
    }
    set({ loadingGroups: false, fetchedGroups: true });
    return !!groups;
  },
  upsertGroup: async (customerId, payload) => {
    const group = await upsertGroup(customerId, payload);
    if (group)
      set(({ groups }) => {
        groups[group.id] = group;
      });
    return group;
  },
  deleteGroup: async (customerId, userId) => {
    const success = await groupApi.deleteGroup(customerId, userId);
    if (success)
      set(({ groups }) => {
        delete groups[userId];
      });
    return success;
  },
});
