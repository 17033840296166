import { UsersParsedCSVPayload } from './slices';
import { CreateUserPayload } from './userMgmt.types';

export function getUsersFromCSVPayload(payload: UsersParsedCSVPayload[]): CreateUserPayload[] {
  return payload
    .filter(({ email, first_name, last_name }) => email && first_name && last_name)
    .map(({ email, first_name, last_name }) => ({
      email,
      givenName: first_name,
      familyName: last_name,
    }));
}

export function assertCSVColumns(names: object, columns: string[] = []): boolean {
  return Object.values(names).every((col) => columns.includes(col));
}
