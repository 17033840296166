import { Button, Flex, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES, TrainingRouteParams } from 'routes/routes.config';
import { AiModelType, isAiModelType, useAiModelStore, useAiDataSourcesAvailability } from 'stores/aiPlatform';
import { FlowStatus, getFlowStatus, useFlow } from 'stores/flows';
import { AiModelTable, testIds as tableTestIds } from './AiModelTable/AiModelTable';
import {
  TrainingModal,
  TrainingDraftNoticeModal,
  startTrainingModalTestIds,
  draftNoticeModalTestIds,
  PageTitle,
} from '../components';

export const testIds = {
  wrapper: 'training-page',
  startTrainingButton: 'start-training-button',
  table: tableTestIds,
  modal: startTrainingModalTestIds,
  draftNoticeModal: draftNoticeModalTestIds,
};

export const TrainingPage = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as TrainingRouteParams;
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const flow = useFlow(flowId);
  const modelType = params.get('modelType') ?? '';
  const [isStartTrainingModalOpen, { open: openStartTrainingModal, close: closeStartTrainingModal }] =
    useDisclosure(false);
  const [isDraftNoticeModalOpen, { open: openDraftNoticeModal, close: closeDraftNoticeModal }] = useDisclosure(false);
  const { startTrainingExec } = useAiModelStore(['startTrainingExec']);
  const isDataSourceAvailable = useAiDataSourcesAvailability();

  if (!isAiModelType(modelType)) {
    return (
      <Navigate
        to={{ pathname: ROUTES.AI_PLATFORM(customerId, flowId).TRAINING, search: `?modelType=${AiModelType.NLP}` }}
        replace
      />
    );
  }

  const handleStartButtonClick = () => {
    if (getFlowStatus(flow) === FlowStatus.DRAFT) {
      openDraftNoticeModal();
    } else {
      openStartTrainingModal();
    }
  };

  const onStartTraining = async (aiDataSourceId: string) => {
    const modelId = await startTrainingExec(aiDataSourceId, modelType);
    if (!modelId)
      notifications.show({
        title: t('trainingPage.startTrainingError.title'),
        message: t('trainingPage.startTrainingError.message'),
        color: 'red',
      });
    else {
      navigate(ROUTES.AI_PLATFORM(customerId, flowId).TRAINING_PIPELINE(modelId));
      closeDraftNoticeModal();
    }
  };

  const pageTitle = t(`trainingPage.pageTitle.${modelType}`);

  return (
    <Stack p='xl' gap='md' data-testid={testIds.wrapper}>
      <Flex align='center' justify='space-between'>
        <PageTitle title={pageTitle} subtitle={flow.name} />
        <Button
          disabled={!isDataSourceAvailable}
          onClick={handleStartButtonClick}
          data-testid={testIds.startTrainingButton}
        >
          {t('trainingPage.startTraining')}
        </Button>
        <TrainingDraftNoticeModal
          open={isDraftNoticeModalOpen}
          onClose={closeDraftNoticeModal}
          onSubmit={() => {
            closeDraftNoticeModal();
            openStartTrainingModal();
          }}
        />
        <TrainingModal
          modelName={t(`common.modelType.${modelType}`)}
          opened={isStartTrainingModalOpen}
          onClose={closeStartTrainingModal}
          onStart={onStartTraining}
        />
      </Flex>
      <AiModelTable />
    </Stack>
  );
};
