import { keyBy } from 'utils';
import { AiModelStatus, TrainingStepStatus, AiModelType, AiModel, TrainingSteps } from './aiModels.types';
import {
  State,
  StepName,
  ModelType,
  Step,
  GetTrainingExecutionQuery,
  StartTrainModel,
} from '../aiPlatformBackendTypes';

export const aiModelTypeToStartTrainModelType: Record<AiModelType, StartTrainModel> = {
  [AiModelType.ASR]: StartTrainModel.asr,
  [AiModelType.NLP]: StartTrainModel.nlp,
};

const stateToModelStatus: Record<State, AiModelStatus> = {
  [State.DONE]: AiModelStatus.COMPLETED,
  [State.STARTED]: AiModelStatus.IN_TRAINING,
  [State.FAILED]: AiModelStatus.FAILED,
};

const stateToTrainingStatus: Record<State, TrainingStepStatus> = {
  [State.DONE]: TrainingStepStatus.COMPLETED,
  [State.STARTED]: TrainingStepStatus.IN_PROGRESS,
  [State.FAILED]: TrainingStepStatus.FAILED,
};

const trainingStepToStepName: Record<keyof Omit<TrainingSteps, 'warmup'>, Record<AiModelType, StepName>> = {
  dataset: {
    [AiModelType.ASR]: StepName.PREPROCESS_SPEECH_SAMPLES,
    [AiModelType.NLP]: StepName.PREPROCESS_TEXT_SAMPLES,
  },
  train: {
    [AiModelType.ASR]: StepName.TRAIN_ASR_MODEL,
    [AiModelType.NLP]: StepName.TRAIN_NLP_MODEL,
  },
  test: {
    [AiModelType.ASR]: StepName.EVALUATE_ASR_MODEL,
    [AiModelType.NLP]: StepName.EVALUATE_NLP_MODEL,
  },
};

const modelTypeMap: Record<ModelType, AiModelType> = {
  [ModelType.ASR]: AiModelType.ASR,
  [ModelType.NLP]: AiModelType.NLP,
};

const getTrainingStepStatus = (
  stepsMap: Record<string, Step>,
  trainingStep: keyof Omit<TrainingSteps, 'warmup'>,
  type: AiModelType,
) => {
  const stepName = trainingStepToStepName[trainingStep][type];
  const state = stepsMap[stepName]?.state;
  return stateToTrainingStatus[state] ?? TrainingStepStatus.PENDING;
};

export const aiModelFromTrainingExecution = (
  execution: GetTrainingExecutionQuery['getTrainingExecution'],
): AiModel | undefined => {
  if (!execution) return undefined;

  const stepsMap = keyBy([...(execution.currentSteps ?? []), ...(execution.previousSteps ?? [])], 'stepName');
  const type: AiModelType = modelTypeMap[execution.trainedModels?.[0]?.modelType ?? ModelType.ASR];

  return {
    id: execution.executionId,
    createdAt: execution.startTimestamp ?? NaN,
    // TODO: construct user identity
    createdBy: {
      userId: execution.triggeredBy,
      givenName: execution.triggeredBy,
      familyName: '',
    },
    status: stateToModelStatus[execution.state],
    type,
    trainingSteps: {
      warmup: {
        status: Object.keys(stepsMap).length ? TrainingStepStatus.COMPLETED : stateToTrainingStatus[execution.state],
      },
      dataset: {
        status: getTrainingStepStatus(stepsMap, 'dataset', type),
        // TODO: Add the following in response
        trainingUri: '',
        testingUri: '',
        evaluationUri: '',
      },
      train: {
        status: getTrainingStepStatus(stepsMap, 'train', type),
        // TODO: Add the following in response
        results: [],
      },
      test: {
        status: getTrainingStepStatus(stepsMap, 'test', type),
        // TODO: Add the following in response
        results: {
          labels: [],
        },
        failedSamplesCount: 0,
      },
    },
  };
};
