import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useCallback, useMemo } from 'react';
import { ContainerId, LabelId, MovePosition, useContainerStore, useEventDefStore, useLabelStore } from 'stores/wizard';
import { notifications } from '@mantine/notifications';
import { IconTransferIn } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { WizardRouteParams } from 'routes/routes.config';
import { tableStaticDefs, tableTestIds } from './ContainerTable.defs';
import {
  ContainerRowActions,
  ContainerTableActions,
  ContainerTableEmptyState,
  actionsTestIds,
  emptyStateTestIds,
  rowActionsTestIds,
} from './components';
import { useContainerTableColumns, columnTestIds } from './hooks';
import {
  DND_ATTR_NAME,
  DndPosition,
  dragOutOfRowHandler,
  getDndPositionFromEvent,
  getRootContainers,
  handleDragOverRow,
} from './ContainerTable.utils';
import classes from './ContainerTable.module.css';
import { ContainerTableState } from './ContainerTable.types';

export const testIds = {
  getRowTestId: (id: string) => `container-table-row-${id}`,
  rowRegex: /container-table-row-.*/,
  table: tableTestIds,
  columns: columnTestIds,
  actions: actionsTestIds,
  emptyState: emptyStateTestIds,
  rowActions: rowActionsTestIds,
};

const getRowId = (id: string) => `container-row-${id}`;

const positionMap: Record<DndPosition, MovePosition> = {
  [DndPosition.TOP]: 'before',
  [DndPosition.BOTTOM]: 'after',
  [DndPosition.MIDDLE]: 'inside',
};

export const ContainerTable = () => {
  const { t } = useTranslation();
  const { flowId } = useParams() as WizardRouteParams;
  const [params, setParams] = useSearchParams();
  const searchTerm = params.get('containerName') ?? '';
  const { labels } = useLabelStore(['labels']);
  const { unmapContainers } = useEventDefStore(['unmapContainers']);
  const { data, createContainers, deleteContainers, updateContainer, repositionContainer } = useContainerStore([
    'data',
    'createContainers',
    'deleteContainers',
    'updateContainer',
    'repositionContainer',
  ]);

  const rootContainers = useMemo(() => getRootContainers(data), [data]);
  const labelsArray = useMemo(() => Object.values(labels), [labels]);
  const columns = useContainerTableColumns((id, values) => updateContainer(id, values));

  const onCreate = useCallback(
    (name: string, labelId: LabelId, parentId?: ContainerId) => {
      createContainers({ names: [name], labelId, flowId, parentId });
    },
    [createContainers, flowId],
  );

  const onSearch = useCallback(
    (value: string) => {
      if (value) params.set('containerName', value);
      else params.delete('containerName');
      setParams(params);
    },
    [params],
  );

  const onDelete = useCallback(
    (ids: ContainerId[]) => {
      const containers = ids.map((id) => data[id]);
      deleteContainers(ids);
      unmapContainers(containers);
    },
    [unmapContainers],
  );

  const table = useMantineReactTable({
    columns,
    data: rootContainers,
    ...tableStaticDefs,
    getSubRows: (row) => row.childrenIds.map((id) => data[id]),
    renderEmptyRowsFallback: (props) => <ContainerTableEmptyState {...props} />,
    // @ts-ignore
    mantineTableBodyRowProps: ({ row }) => ({
      id: getRowId(row.id),
      className: classes.row,
      onDragOver: handleDragOverRow,
      onDragLeave: dragOutOfRowHandler,
      onDragExit: dragOutOfRowHandler,
      'data-testid': testIds.getRowTestId(row.id),
    }),
    mantineRowDragHandleProps: ({ table: tbl }) => ({
      onDragEnd: (e) => {
        const { draggingRow, hoveredRow } = tbl.getState();
        if (!hoveredRow?.id || !draggingRow?.id) return;
        if (hoveredRow.id === draggingRow.id) return;
        const rowElement = document.getElementById(getRowId(hoveredRow.id));
        if (!rowElement) return;
        const dropArea = getDndPositionFromEvent(e, rowElement);
        rowElement.removeAttribute(DND_ATTR_NAME);
        if (!dropArea) return;
        const position = positionMap[dropArea];
        const operationSuccessful = repositionContainer(draggingRow.id, hoveredRow.id, position);
        if (!operationSuccessful)
          notifications.show({
            color: 'violet',
            icon: <IconTransferIn />,
            title: t('wizard.steps.labeling.containerTable.dndError.title'),
            message: t('wizard.steps.labeling.containerTable.dndError.message'),
            autoClose: 3000,
          });
      },
    }),
    renderTopToolbar: ContainerTableActions,
    renderRowActions: (props) => <ContainerRowActions {...props} />,
    initialState: { expanded: true },
    state: {
      globalFilter: searchTerm,
      labels: labelsArray,
      onChange: updateContainer,
      onCreate,
      onDelete,
      onSearch,
    } as ContainerTableState,
  });

  return <MantineReactTable table={table} />;
};
