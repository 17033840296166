import { AiModelType } from './aiModels.types';

export function isAiModelType(maybeAiModelType: string): maybeAiModelType is AiModelType {
  return Object.values(AiModelType).includes(maybeAiModelType as AiModelType);
}

export function getAiModelType(maybeAiModelType: string): AiModelType | undefined {
  if (!isAiModelType(maybeAiModelType)) {
    return undefined;
  }

  return maybeAiModelType;
}
