import { TextExtractionResponse } from '@jargonic/labeling-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';

export const annotationsApi = {
  process: async (customerId: string, blob: Blob): Promise<string[] | undefined> => {
    const url = `${config.jargonicApiUrl}/${customerId}/labeling/extract-text`;
    const formData = new FormData();
    formData.append('files', blob);
    const { data } = await hermes.post<TextExtractionResponse>(url, formData, {
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });
    return data?.data.texts[0];
  },
};
