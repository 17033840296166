import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { User } from 'stores/auth';
import { EntityTableState } from '../EntityTable.types';
import { Group } from '@jargonic/customer-types';

export const testIds = { wrapper: 'entity-table-empty-state' };

export const EntityTableEmptyState: NonNullable<MRT_TableOptions<User | Group>['renderEmptyRowsFallback']> = ({ table }) => {
  const { t } = useTranslation();
  const { globalFilter } = table.getState() as EntityTableState;

  return (
    <EmptyState
      title={
        globalFilter
          ? t('userMgmt.policies.policyDetails.panels.entities.table.emptyState.emptySearch.title')
          : t('userMgmt.policies.policyDetails.panels.entities.table.emptyState.noItems.title')
      }
      message={
        globalFilter ? undefined : t('userMgmt.policies.policyDetails.panels.entities.table.emptyState.noItems.message')
      }
      data-testid={testIds.wrapper}
    />
  );
};
