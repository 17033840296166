import { Box, Flex, Loader } from '@mantine/core';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { AiModelType, useAiModel, useAiModelStore } from 'stores/aiPlatform';
import { useFlow, useFlowStore } from 'stores/flows';
import { ROUTES, TrainingPipelineRouteParams } from 'routes/routes.config';
import { AiPlatformCommonSidebar, testIds as sidebarTestIds } from 'layouts/AiPlatformLayout/AiPlatformCommonSidebar';
import { useTranslation } from 'react-i18next';

export const testIds = {
  sidebar: sidebarTestIds,
  loader: 'training-layout-loader',
};

export const TrainingLayout = () => {
  const { t } = useTranslation();
  const { customerId, flowId, modelId } = useParams() as TrainingPipelineRouteParams;
  const [params] = useSearchParams();
  const flow = useFlow(flowId);
  const { loading: modelsLoading } = useAiModelStore(['loading']);
  const { loading: flowsLoading } = useFlowStore(['loading']);
  const aiModel = useAiModel(modelId);
  const isLoading = !flow || flowsLoading || modelsLoading;
  const activeModelType = aiModel?.type ?? (params.get('modelType') as AiModelType);

  return (
    <Flex h='100%' w='100%'>
      <AiPlatformCommonSidebar
        title={t('trainingPage.sidebar.title')}
        activeModelType={activeModelType}
        pathname={ROUTES.AI_PLATFORM(customerId, flowId).TRAINING}
      />
      <Box flex={1} h='100%'>
        {isLoading ? <Loader type='bars' my='xl' mx='auto' data-testid={testIds.loader} /> : <Outlet />}
      </Box>
    </Flex>
  );
};
