import { ComponentProps, PropsWithChildren, ReactElement } from 'react';
import { Popover } from '@mantine/core';
import { ChildNavLink } from './ChildNavLink';
import classes from '../FlowLayout.module.css';

type ChildNavLinkElement = ReactElement<ComponentProps<typeof ChildNavLink>>;

interface ParentNavLinkMenuProps extends PropsWithChildren {
  links: ChildNavLinkElement | ChildNavLinkElement[];
  disabled?: boolean;
}

export const testIds = {
  menu: 'flow-sidebar-parent-nav-menu',
};

export const ParentNavLinkMenu = ({ children, links, disabled }: ParentNavLinkMenuProps) =>
  disabled ? (
    children
  ) : (
    <Popover position='right-start' offset={10}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown className={classes.navLinkPopover} data-testid={testIds.menu}>
        {links}
      </Popover.Dropdown>
    </Popover>
  );
