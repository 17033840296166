import { Menu } from '@mantine/core';
import { IconDots, IconTrash, IconTrashOff, IconUsersGroup } from '@tabler/icons-react';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'components';
import { Policy } from 'stores/userMgmt';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { compareArrays } from 'utils';
import { PolicyTableState } from '../PolicyTable.types';
import { DeletePolicyModal, PolicyMappingModal, deletePolicyTestIds, policyMappingTestIds } from '../../../components';

export const testIds = {
  getButtonTestId: (id: string) => `policy-table-row-actions-button-${id}`,
  icon: 'policy-table-row-actions-icon',
  options: {
    delete: 'policy-table-row-actions-delete',
    entities: 'policy-table-row-actions-entities',
  },
  deleteModal: deletePolicyTestIds,
  entitiesModal: policyMappingTestIds,
};

export const PolicyTableRowActions: NonNullable<MRT_TableOptions<Policy>['renderRowActions']> = ({ row, table }) => {
  const { t } = useTranslation();
  const { users, groups } = table.getState() as PolicyTableState;
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure();
  const [entitiesModalOpened, { open: openEntitiesModal, close: closeEntitiesModal }] = useDisclosure();

  const onDelete = async () => {
    const { onDeletePolicy } = table.getState() as PolicyTableState;
    const { name } = row.original;
    const response = await onDeletePolicy(row.id);
    if (response) {
      notifications.show({
        title: t('userMgmt.policies.table.rowActions.delete.success.title', { name }),
        message: null,
        icon: <IconTrash />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.table.rowActions.delete.error.title', { name }),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
    }
    return response;
  };

  const onChangeMapping = async (userIds: string[], groupIds: string[]) => {
    const existingUserIds = row.original.users.map((user) => user.userId);
    const existingGroupsIds = row.original.groups.map((group) => group.id);
    const { isDiff, added, removed } = compareArrays([...existingUserIds, ...existingGroupsIds], [...userIds, ...groupIds]);
    if (!isDiff) return true;
    const { onMapPolicy } = table.getState() as PolicyTableState;
    const response = await onMapPolicy(row.id, userIds, groupIds);
    const { name } = row.original;
    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.policies.table.rowActions.entities.success.title', { name }),
        message: t('userMgmt.policies.table.rowActions.entities.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconUsersGroup />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.table.rowActions.entities.error.title', { name }),
        message: t('common.tryAgain'),
        icon: <IconUsersGroup />,
      });
    }
    return response;
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openEntitiesModal} data-testid={testIds.options.entities}>
            {t('common.entities')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={openDeleteModal} data-testid={testIds.options.delete}>
            {t('common.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <DeletePolicyModal
        opened={deleteModalOpened}
        policy={row.original}
        onClose={closeDeleteModal}
        onDelete={onDelete}
      />
      <PolicyMappingModal
        opened={entitiesModalOpened}
        policy={row.original}
        users={users}
        groups={groups}
        onClose={closeEntitiesModal}
        onSubmit={onChangeMapping}
      />
    </>
  );
};
