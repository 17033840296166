import { EventDefNumericValidation, EvenDefValidationId, NumericValidationType, useEventDefStore } from 'stores/wizard';
import { DecimalSelect, DefaultValueInput, decimalSelectTestIds, defaultValueInputTestIds } from './components';
import classes from './NumberTypeLayout.module.css';
import { NewValidationSelect, ValidationList, newValidationSelectTestIds, validationListTestIds } from '../components';
import { useValidationContainerPool } from '../hooks';

interface NumberTypeLayoutProps {
  eventDefId: string;
}

export const testIds = {
  decimalSelect: decimalSelectTestIds,
  defaultValueInput: defaultValueInputTestIds,
  newValidationSelect: newValidationSelectTestIds,
  validationList: validationListTestIds,
};

export const NumberTypeLayout = ({ eventDefId }: NumberTypeLayoutProps) => {
  const { data, updateEventDef, updateEventValidation } = useEventDefStore([
    'data',
    'updateEventDef',
    'updateEventValidation',
  ]);
  const eventDef = data[eventDefId];
  const containerPool = useValidationContainerPool(eventDefId);

  if (!eventDef) return null;

  const validations = (eventDef.validations ?? []) as EventDefNumericValidation[];
  const onChangeDecimalPrecision = (value?: number) => updateEventDef(eventDefId, { decimalPrecision: value });
  const onChangeDefaultValue = (value?: string) => updateEventDef(eventDefId, { default: value });
  const onNewValidation = (type: NumericValidationType) =>
    updateEventValidation(eventDefId, { action: 'create', payload: type });
  const onChangeValidation = (validationId: EvenDefValidationId, values: Partial<EventDefNumericValidation>) =>
    updateEventValidation(eventDefId, { action: 'update', payload: { id: validationId, ...values } });
  const onDeleteValidation = (validationId: EvenDefValidationId) =>
    updateEventValidation(eventDefId, { action: 'delete', payload: validationId });

  return (
    <>
      <DecimalSelect
        rootClassName={classes.decimalInput}
        value={eventDef.decimalPrecision}
        onChange={onChangeDecimalPrecision}
      />
      <DefaultValueInput
        rootClassName={classes.defaultInput}
        value={eventDef.default}
        disabled={!eventDef.mandatory}
        onChange={onChangeDefaultValue}
      />
      <NewValidationSelect rootClassName={classes.newValidationInput} onNewValidation={onNewValidation} />
      <ValidationList
        containerPool={containerPool}
        validations={validations}
        rootClassName={classes.validationList}
        onChange={onChangeValidation}
        onDelete={onDeleteValidation}
      />
    </>
  );
};
