import { MRT_TableOptions } from 'mantine-react-table';
import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';

export const flowTableDefs = {
  enableBottomToolbar: false,
  enableColumnActions: false,
  enableExpandAll: false,
  enableExpanding: true,
  enablePagination: false,
  enableRowActions: true,
  enableRowOrdering: true,
  enableSorting: false,
  enableStickyHeader: true,
  layoutMode: 'grid',
  positionActionsColumn: 'last',
  displayColumnDefOptions: {
    'mrt-row-drag': {
      header: '',
      size: 0,
      minSize: 25,
      mantineTableBodyCellProps: { pr: 0, pl: 'teeny' },
      mantineTableHeadCellProps: { pr: 0, pl: 'teeny' },
    },
    'mrt-row-actions': { header: '', grow: false, size: 100 },
  },
  mantinePaperProps: {
    pos: 'absolute',
    h: '100%',
    w: '100%',
    top: 0,
    left: 0,
  },
  mantineTableContainerProps: {
    pos: 'absolute',
    h: 'calc(100% - var(--mrt-top-toolbar-height) * 1px)',
    w: '100%',
    top: 'calc(var(--mrt-top-toolbar-height) * 1px)',
    left: 0,
  },
  mantineLoadingOverlayProps: {
    pos: 'absolute',
    h: '100%',
    w: '100%',
    top: 0,
    left: 0,
  },
  initialState: { columnVisibility: { 'mrt-row-expand': false } },
  mantineTableBodyCellProps: { p: 'sm' },
} satisfies Partial<MRT_TableOptions<Folder | Flow>>;
