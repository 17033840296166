import { Button, Group } from '@mantine/core';
import { IconFolderPlus, IconPlus } from '@tabler/icons-react';
import { createFlowGlobalModalProps, createFolderGlobalModalProps } from 'pages/FlowsPage/components/modals';
import { useTranslation } from 'react-i18next';
import { Flow } from 'stores/flows';
import { useModal } from 'stores/globalModal';
import { MRT_TableInstance } from 'mantine-react-table';
import { Folder } from 'stores/folders';
import { FlowTableImportAction, testIds as importTestIds } from './FlowTableImportAction';
import { FlowTableState } from '../../FlowTable.types';

type FlowTableActionsProps = { table: MRT_TableInstance<Flow | Folder> };

export const testIds = {
  wrapper: 'flow-table-actions',
  newFolder: {
    button: 'flow-table-actions-new-folder-button',
    icon: 'flow-table-actions-new-folder-icon',
  },
  newFlow: {
    button: 'flow-table-actions-new-flow-button',
    icon: 'flow-table-actions-new-flow-icon',
  },
  import: importTestIds,
};

export const FlowTableActions = ({ table }: FlowTableActionsProps) => {
  const { t } = useTranslation();
  const { open } = useModal();
  const { afterCreateFlow, afterCreateFolder, onImportFlow } = table.getState() as FlowTableState;

  const onClickNewFlow = () => open(createFlowGlobalModalProps({ onCreate: afterCreateFlow }));
  const onClickNewFolder = () =>
    open(
      createFolderGlobalModalProps({
        onCreate: afterCreateFolder,
      }),
    );

  return (
    <Group data-testid={testIds.wrapper}>
      <Button
        leftSection={<IconPlus data-testid={testIds.newFlow.icon} />}
        onClick={onClickNewFlow}
        data-testid={testIds.newFlow.button}
      >
        {t('flowsPage.newFlowButton')}
      </Button>
      <FlowTableImportAction onUpload={onImportFlow} />
      <Button
        variant='outline'
        leftSection={<IconFolderPlus data-testid={testIds.newFolder.icon} />}
        onClick={onClickNewFolder}
        data-testid={testIds.newFolder.button}
      >
        {t('flowsPage.newFolderButton')}
      </Button>
    </Group>
  );
};
