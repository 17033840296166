import { Group } from '@jargonic/customer-types';
import { Avatar, Flex, Title } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import { computerGroupColor } from '@aiola/frontend';

interface GroupCellProps {
  group: Group;
}

export const testIds = {
  wrapper: 'entity-table-group-cell',
  getAvatarTestId: (ig: string) => `entity-table-group-avatar-${ig}`,
  name: 'entity-table-group-name',
};

export const GroupCell = ({ group }: GroupCellProps) => (
  <Flex gap='sm' align='center' data-testid={testIds.wrapper}>
    <Avatar color={computerGroupColor(group)} data-testid={testIds.getAvatarTestId(group.id)}>
      <IconUsers />
    </Avatar>
    <Title order={5} fw={700} lh={1} data-testid={testIds.name}>
      {group.name}
    </Title>
  </Flex>
);
