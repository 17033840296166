import { Paper, Button, Text, Box, Group, ThemeIcon } from '@mantine/core';
import { IconDownload, IconMail } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AiDataSource, AiDataSourceStatus } from 'stores/aiPlatform';
import classes from './DataGenerationActions.module.css';

interface DataGenerationActionsProps {
  aiDataSource: AiDataSource;
}

export const testIds = {
  abort: 'data-generation-actions-abort',
  decline: 'data-generation-actions-decline',
  downloadSamples: 'data-generation-actions-download-samples',
  approveAndGenerate: 'data-generation-actions-approve-and-generate',
  fullGenerationNote: {
    icon: 'data-generation-actions-full-generation-note-icon',
    text: 'data-generation-actions-full-generation-note-text',
  },
};

export const DataGenerationActions = ({ aiDataSource }: DataGenerationActionsProps) => {
  const { t } = useTranslation();
  const { isSamplesGeneration } = aiDataSource;
  const isInProgress = aiDataSource.status === AiDataSourceStatus.IN_PROGRESS;
  const isFullGenerationInProgress = !isSamplesGeneration && isInProgress;
  const isSamplesCompleted = isSamplesGeneration && aiDataSource.status === AiDataSourceStatus.COMPLETED;
  const isFullGenerationCompleted = !isSamplesGeneration && aiDataSource.status === AiDataSourceStatus.COMPLETED;

  if (isFullGenerationCompleted) return null;

  return (
    <Paper className={classes.root}>
      {isInProgress && (
        <Button color='red' data-testid={testIds.abort}>
          {t('dataGenerationPage.actions.abortButton')}
        </Button>
      )}
      {isFullGenerationInProgress && (
        <Group align='center' gap='tiny' mx='auto'>
          <ThemeIcon c='teal.7' variant='white'>
            <IconMail size={20} data-testid={testIds.fullGenerationNote.icon} />
          </ThemeIcon>
          <Text c='gray.6' data-testid={testIds.fullGenerationNote.text}>
            {t('dataGenerationPage.actions.fullGenerationNote')}
          </Text>
        </Group>
      )}
      {isSamplesCompleted && (
        <>
          <Button color='red' data-testid={testIds.decline}>
            {t('dataGenerationPage.actions.declineButton')}
          </Button>
          <Box flex={1} />
          <Button
            variant='transparent'
            color='grey.7'
            leftSection={<IconDownload />}
            data-testid={testIds.downloadSamples}
          >
            {t('dataGenerationPage.actions.downloadSamplesButton')}
          </Button>
          <Button data-testid={testIds.approveAndGenerate}>
            {t('dataGenerationPage.actions.approveAndGenerateButton')}
          </Button>
        </>
      )}
    </Paper>
  );
};
