import { Box, Button, Flex, FocusTrap, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ModalBase, modalBaseTestIds } from 'components';
import { EMAIL_REGEX } from 'consts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserPayload } from 'stores/userMgmt';

interface UserTableManualCreateModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: CreateUserPayload) => Promise<void>;
}

export const testIds = {
  modal: 'user-table-manual-create',
  title: modalBaseTestIds.title,
  firstNameInput: 'user-table-manual-create-first-name-input',
  lastNameInput: 'user-table-manual-create-last-name-input',
  emailInput: 'user-table-manual-create-email-input',
  submit: 'user-table-manual-create-submit',
  cancel: 'user-table-manual-create-cancel',
};

export const UserTableManualCreateModal = ({ opened, onClose, onSubmit }: UserTableManualCreateModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const form = useForm<CreateUserPayload>({
    initialValues: { givenName: '', familyName: '', email: '' },
    validate: {
      email: (value) => !EMAIL_REGEX.test(value) && t('common.invalidEmail'),
    },
  });

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const onSubmitForm = async (values: CreateUserPayload) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
    onCloseModal();
  };

  return (
    <ModalBase
      title={t('userMgmt.users.table.actions.createUsers.modal.title')}
      opened={opened}
      onClose={onCloseModal}
      data-testid={testIds.modal}
    >
      <Box component='form' onSubmit={form.onSubmit(onSubmitForm)}>
        <Stack>
          <FocusTrap active>
            <Flex gap='md' w='100%'>
              <TextInput
                classNames={{ root: 'grow' }}
                label={t('common.firstName')}
                required
                {...form.getInputProps('givenName')}
                data-testid={testIds.firstNameInput}
                data-autofocus
              />
              <TextInput
                classNames={{ root: 'grow' }}
                label={t('common.lastName')}
                required
                {...form.getInputProps('familyName')}
                data-testid={testIds.lastNameInput}
              />
            </Flex>
            <TextInput
              label={t('common.email')}
              required
              type='email'
              {...form.getInputProps('email')}
              data-testid={testIds.emailInput}
            />
          </FocusTrap>
          <Group justify='end'>
            <Button variant='subtle' disabled={loading} onClick={onCloseModal} data-testid={testIds.cancel}>
              {t('common.cancel')}
            </Button>
            <Button type='submit' loading={loading} data-testid={testIds.submit}>
              {t('common.submit')}
            </Button>
          </Group>
        </Stack>
      </Box>
    </ModalBase>
  );
};
