import { createStoreHook } from '@aiola/frontend';
import { keyBy } from 'utils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { ListDataGenerationsInput, StartDataGenerationRequest } from '../aiPlatformBackendTypes';
import { endSubscriptionStatusList } from './aiDataSources.const';
import {
  AiDataSource,
  AiDataSourceId,
  AiDataSourceStatus,
  AiDataSourceSubscriptionOptions,
} from './aiDataSources.types';
import { aiDataSourcesApi } from './api/aiDataSources.api';

interface AiDataSourcesState {
  loading: boolean;
  aiDataSources: Record<AiDataSourceId, AiDataSource>;
  subscriptions: Record<AiDataSourceId, NodeJS.Timeout>;
}

interface AiDataSourcesActions {
  fetchAiDataSources: (input: ListDataGenerationsInput) => Promise<AiDataSource[] | undefined>;
  createAiDataSource: (input: StartDataGenerationRequest) => Promise<AiDataSource | undefined>;
  subscribeToAiDataSource: (id: AiDataSourceId, options?: AiDataSourceSubscriptionOptions) => () => void;
  fetchSingleAiDataSource: (executionId: string) => Promise<AiDataSource | undefined>;
  reset: () => void;
  internal: {
    unsubscribeFromAiDataSource: (id: AiDataSourceId) => void;
  };
}

const initialState: AiDataSourcesState = {
  loading: false,
  aiDataSources: {},
  subscriptions: {},
};

export const aiDataSourcesStore = create(
  immer<AiDataSourcesState & AiDataSourcesActions>((set, get) => ({
    ...initialState,
    fetchAiDataSources: async (input: ListDataGenerationsInput) => {
      set({ loading: true });
      const aiDataSourceList = await aiDataSourcesApi.getAiDataSources(input);
      const aiDataSources = keyBy(aiDataSourceList ?? [], 'id');
      set({ aiDataSources, loading: false });
      return aiDataSourceList;
    },
    fetchSingleAiDataSource: async (executionId: string) => {
      const aiDataSource = await aiDataSourcesApi.getAiDataSource(executionId);
      set((state) => {
        if (aiDataSource) {
          state.aiDataSources[aiDataSource.id] = aiDataSource;
        }
      });
      return aiDataSource;
    },
    createAiDataSource: async (input: StartDataGenerationRequest) => {
      const response = await aiDataSourcesApi.startDataSourceGeneration(input);
      if (!response.executionId) return undefined;
      const aiDataSource = await aiDataSourcesApi.getAiDataSource(response.executionId);
      if (!aiDataSource) return undefined;
      set((state) => {
        state.aiDataSources[aiDataSource.id] = aiDataSource;
      });
      return aiDataSource;
    },
    subscribeToAiDataSource: (
      id: AiDataSourceId,
      { onComplete, unsubscribeOnFinish }: AiDataSourceSubscriptionOptions = {},
    ) => {
      const interval = aiDataSourcesApi.subscribeToAiDataSource(id, (aiDataSource) => {
        const prevStatus = get().aiDataSources[aiDataSource.id]?.status;
        const { step: currentStep, status: currentStatus } = aiDataSource;
        // When status is completed/failed, we don't expect changes, so unsubscribe
        if (unsubscribeOnFinish && endSubscriptionStatusList.includes(currentStatus)) {
          get().internal.unsubscribeFromAiDataSource(id);
        }
        if (onComplete && currentStatus === AiDataSourceStatus.COMPLETED && prevStatus !== currentStatus) {
          onComplete();
        }
        set((state) => {
          if (state.aiDataSources[aiDataSource.id].step !== currentStep) {
            state.aiDataSources[aiDataSource.id] = aiDataSource;
          }
        });
      });
      set((state) => {
        state.subscriptions[id] = interval;
      });

      return () => get().internal.unsubscribeFromAiDataSource(id);
    },
    reset: () => {
      Object.values(get().subscriptions).forEach(clearInterval);
      set(initialState);
    },
    internal: {
      unsubscribeFromAiDataSource: (id: AiDataSourceId) => {
        clearInterval(get().subscriptions[id]);
        set((state) => {
          delete state.subscriptions[id];
        });
      },
    },
  })),
);

export const useAiDataSourcesStore = createStoreHook<AiDataSourcesState & AiDataSourcesActions>({
  store: aiDataSourcesStore,
  useShallow,
});
