import { WizardStep } from 'layouts/WizardLayout/WizardLayout.steps';

export const ROUTES = {
  BACK: -1,
  ROOT: '/',
  LOGIN: '/login',
  CUSTOMERS: '/customers',
  CUSTOMER_SWAP: (customerId = ':customerId') => `/${customerId}/customers`,
  FLOWS: (customerId = ':customerId') => `/${customerId}/flows`,
  FLOW: (customerId = ':customerId', flowId = ':flowId') => `/${customerId}/flows/${flowId}`,
  WIZARD: (customerId = ':customerId', flowId = ':flowId') => `/${customerId}/flows/${flowId}/wizard`,
  WIZARD_STEP: (customerId = ':customerId', flowId = ':flowId', step: WizardStep | ':step' = ':step') =>
    `/${customerId}/flows/${flowId}/wizard/${step}`,
  AI_PLATFORM: (customerId = ':customerId', flowId = ':flowId') => ({
    ROOT: `/${customerId}/flows/${flowId}/ai-platform`,
    DATA_GENERATION: `/${customerId}/flows/${flowId}/ai-platform/data-generation`,
    TRAINING: `/${customerId}/flows/${flowId}/ai-platform/training`,
    TRAINING_PIPELINE: (modelId = ':modelId') => `/${customerId}/flows/${flowId}/ai-platform/training/${modelId}`,
  }),
  USER_MGMT: (customerId = ':customerId') => ({
    ROOT: `/${customerId}/user-management`,
    USERS: `/${customerId}/user-management/users`,
    USER: (userId = ':userId') => `/${customerId}/user-management/users/${userId}`,
    GROUPS: `/${customerId}/user-management/groups`,
    GROUP: (groupId = ':groupId') => `/${customerId}/user-management/groups/${groupId}`,
    POLICIES: `/${customerId}/user-management/policies`,
    POLICY: (policyId = ':policyId') => `/${customerId}/user-management/policies/${policyId}`,
  }),
} as const;

export type CustomerRouteParams = { customerId: string };
export type FlowRouteParams = CustomerRouteParams & { flowId: string };
export type WizardRouteParams = FlowRouteParams & { step: WizardStep };
export type AIPlatformRouteParams = FlowRouteParams;
export type DataGenerationRouteParams = AIPlatformRouteParams;
export type TrainingRouteParams = AIPlatformRouteParams;
export type TrainingPipelineRouteParams = AIPlatformRouteParams & { modelId: string };
export type UserRouteParams = CustomerRouteParams & { userId: string };
export type GroupRouteParams = CustomerRouteParams & { groupId: string };
export type PolicyRouteParams = CustomerRouteParams & { policyId: string };
export type UserMgmtRouteParams = UserRouteParams | GroupRouteParams | PolicyRouteParams;
