import { ComboboxItem, Select } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
import { NumericValidationType } from 'stores/wizard';

interface NewValidationSelectProps {
  rootClassName?: string;
  onNewValidation: (type: NumericValidationType) => void;
}

export const testIds = {
  select: 'new-validation-select',
  label: 'new-validation-select-label',
};

export const options: ComboboxItem[] = [
  {
    value: NumericValidationType.LESS_THEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.lessThan'),
  },
  {
    value: NumericValidationType.GREATER_THEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.greaterThan'),
  },
  {
    value: NumericValidationType.IS_BETWEEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.between'),
  },
  {
    value: NumericValidationType.IS_NOT_BETWEEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.notBetween'),
  },
];

export const NewValidationSelect = ({ rootClassName, onNewValidation }: NewValidationSelectProps) => {
  const { t } = useTranslation();

  const onChange = (value: string | null) => onNewValidation(value as NumericValidationType);

  return (
    <Select
      classNames={{ root: rootClassName }}
      data={options}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.placeholder')}
      value={null}
      onChange={onChange}
      data-testid={testIds.select}
    />
  );
};
