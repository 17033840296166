import { Box, ElementProps, TagsInput, TagsInputProps } from '@mantine/core';
import { forwardRef } from 'react';
import { InfoLabel } from 'components/InfoLabel/InfoLabel';
import { useTranslation } from 'react-i18next';

interface SynonymsInputProps extends TagsInputProps, ElementProps<'input', keyof TagsInputProps> {
  infoText?: string;
  withLabel?: boolean;
}

export const testIds = {
  input: 'synonyms-input',
  label: 'synonyms-input-label',
  wrapper: 'synonyms-input-wrapper',
};

export const SynonymsInput = forwardRef<HTMLInputElement, SynonymsInputProps>(
  ({ infoText, label, description, withLabel = true, ...props }: SynonymsInputProps, ref) => {
    const { t } = useTranslation();

    return (
      <TagsInput
        wrapperProps={{ 'data-testid': testIds.wrapper }}
        ref={ref}
        label={
          // eslint-disable-next-line no-nested-ternary
          withLabel ? (
            infoText ? (
              <InfoLabel infoText={infoText} data-testid={testIds.label}>
                {label ?? t('common.synonyms')}
              </InfoLabel>
            ) : (
              <Box component='span' data-testid={testIds.label}>
                {label ?? t('common.synonyms')}
              </Box>
            )
          ) : null
        }
        description={description ?? t('common.noDuplicates')}
        data-testid={testIds.input}
        {...props}
      />
    );
  },
);
