import { Box, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChartBar, IconDatabase, IconRobot, IconTools, IconWorld } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FlowRouteParams, ROUTES } from 'routes/routes.config';
import { WizardStep } from 'layouts/WizardLayout';
import { useAiPlatformFeatureVisibility, useAiPlatformSecretAccess } from 'pages/aiPlatform';
import {
  ChildNavLink,
  CollapseButton,
  ParentNavLink,
  SidebarLogo,
  childNavLinkTestIds,
  logoTestIds,
  parentNavLinkTestIds,
} from './components';
import classes from './FlowLayout.module.css';

export const testIds = {
  navbar: 'flow-sidebar',
  collapseButton: 'flow-sidebar-collapse-button',
  logo: logoTestIds,
  navLink: {
    child: childNavLinkTestIds,
    parent: parentNavLinkTestIds,
  },
  links: {
    flowCreator: 'flow-sidebar-link-flow-creator',
    aiPlatform: 'flow-sidebar-link-ai-platform',
    flowSettings: 'flow-sidebar-link-flow-settings',
    labeling: 'flow-sidebar-link-labeling',
    events: 'flow-sidebar-link-events',
    data: 'flow-sidebar-link-data',
    analytics: 'flow-sidebar-link-analytics',
    localization: 'flow-sidebar-link-localization',
    aiPlatformDataGeneration: 'flow-sidebar-link-ai-platform-data-generation',
    aiPlatformTraining: 'flow-sidebar-link-ai-platform-training',
  },
};

export const FlowSidebar = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as FlowRouteParams;
  const { pathname } = useLocation();
  const [sidebarExpanded, { toggle: toggleNavbar }] = useDisclosure(false);
  const isAIPEnabled = useAiPlatformFeatureVisibility();
  useAiPlatformSecretAccess();

  return (
    <Box component='nav' className={classes.navbar} data-expanded={sidebarExpanded} data-testid={testIds.navbar}>
      <Box>
        <ParentNavLink
          active={pathname.includes(ROUTES.WIZARD(customerId, flowId))}
          sidebarExpanded={sidebarExpanded}
          Icon={IconTools}
          label={t('navbar.flowCreator')}
          data-testid={testIds.links.flowCreator}
        >
          <ChildNavLink
            active={pathname === ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.SETTINGS)}
            label={t('flowPage.applications.flowSettings')}
            to={ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.SETTINGS)}
            data-testid={testIds.links.flowSettings}
          />
          <ChildNavLink
            active={pathname === ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.LABELING)}
            label={t('flowPage.applications.labeling')}
            to={ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.LABELING)}
            data-testid={testIds.links.labeling}
          />
          <ChildNavLink
            active={pathname === ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.EVENTS)}
            label={t('flowPage.applications.events')}
            to={ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.EVENTS)}
            data-testid={testIds.links.events}
          />
        </ParentNavLink>
        <ParentNavLink
          active={pathname.includes(ROUTES.AI_PLATFORM(customerId, flowId).ROOT)}
          sidebarExpanded={sidebarExpanded}
          Icon={IconRobot}
          label={t('navbar.aiPlatform')}
          data-testid={testIds.links.aiPlatform}
          disabled={!isAIPEnabled}
        >
          <ChildNavLink
            active={pathname === ROUTES.AI_PLATFORM(customerId, flowId).DATA_GENERATION}
            label={t('dataGenerationPage.title')}
            to={ROUTES.AI_PLATFORM(customerId, flowId).DATA_GENERATION}
            data-testid={testIds.links.aiPlatformDataGeneration}
          />
          <ChildNavLink
            active={pathname === ROUTES.AI_PLATFORM(customerId, flowId).TRAINING}
            label={t('trainingPage.title')}
            to={ROUTES.AI_PLATFORM(customerId, flowId).TRAINING}
            data-testid={testIds.links.aiPlatformTraining}
          />
        </ParentNavLink>
        <ChildNavLink label={t('navbar.data')} Icon={IconDatabase} disabled to='#' data-testid={testIds.links.data} />
        <ChildNavLink
          label={t('navbar.analytics')}
          Icon={IconChartBar}
          disabled
          to='#'
          data-testid={testIds.links.analytics}
        />
        <ChildNavLink
          label={t('navbar.localization')}
          Icon={IconWorld}
          disabled
          to='#'
          data-testid={testIds.links.localization}
        />
      </Box>
      <Stack justify='end' className='grow'>
        <CollapseButton sidebarExpanded={sidebarExpanded} onClick={toggleNavbar} />
        <SidebarLogo sidebarExpanded={sidebarExpanded} />
      </Stack>
    </Box>
  );
};
