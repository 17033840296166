import { InfoLabel, TimeSingleInput } from 'components';
import { useTranslation } from 'react-i18next';

interface DefaultValueInputProps {
  rootClassName?: string;
  value?: number;
  disabled?: boolean;
  onChange: (value?: number) => void;
}

export const testIds = {
  input: 'default-value-input',
  label: 'default-value-input-label',
};

export const DefaultValueInput = ({ rootClassName, value, disabled, onChange }: DefaultValueInputProps) => {
  const { t } = useTranslation();

  const onChangeValue = (newValue: number | null) => {
    if (!newValue) onChange(undefined);
    else {
      const parsedValue = newValue.valueOf();
      onChange(parsedValue);
    }
  };

  return (
    <TimeSingleInput
      value={value || null}
      disabled={disabled}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.title')}
        </InfoLabel>
      }
      className={rootClassName}
      onChange={onChangeValue}
      data-testid={testIds.input}
    />
  );
};
