import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';

interface InfoTipProps extends PropsWithChildren {}

export const testIds = {
  tooltip: 'info-tip-tooltip',
  icon: 'info-tip-icon',
  hoverable: 'info-tip-hoverable',
};

export const InfoTip = ({ children, ...props }: InfoTipProps) => (
  <Tooltip label={children} maw={200} multiline data-testid={testIds.tooltip}>
    <ThemeIcon data-testid={testIds.hoverable} {...props} color='dark.2' size='sm' variant='transparent'>
      <IconInfoCircle size={16} data-testid={testIds.icon} />
    </ThemeIcon>
  </Tooltip>
);
