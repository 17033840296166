import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ModalBase, modalBaseTestIds } from 'components';
import { NiceResponse } from 'consts';

interface CreateGroupModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (name: string) => Promise<NiceResponse>;
}

interface CreateGroupFormState {
  name: string;
}

export const testIds = {
  title: modalBaseTestIds.title,
  submit: 'create-group-modal-submit',
  cancel: 'create-group-modal-cancel',
  nameInput: 'create-group-modal-name-input',
};

export const CreateGroupModal = ({ opened, onClose, onSubmit }: CreateGroupModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const form = useForm<CreateGroupFormState>({ initialValues: { name: '' } });

  const closeAndReset = () => {
    onClose();
    form.reset();
  };

  const onSubmitForm = async () => {
    setLoading(true);
    const response = await onSubmit(form.values.name);
    setLoading(false);
    if (response === NiceResponse.DUPLICATE) form.setFieldError('name', t('common.noDuplicates'));
    else closeAndReset();
  };

  return (
    <ModalBase opened={opened} title={t('userMgmt.groups.table.actions.create.createModal.title')} onClose={onClose}>
      <Box component='form' onSubmit={form.onSubmit(onSubmitForm)}>
        <TextInput
          label={t('userMgmt.groups.table.actions.create.createModal.nameInput.label')}
          placeholder={t('userMgmt.groups.table.actions.create.createModal.nameInput.placeholder')}
          required
          {...form.getInputProps('name')}
          data-testid={testIds.nameInput}
          data-autofocus
        />
        <Group mt='lg' justify='end'>
          <Button disabled={loading} variant='subtle' onClick={closeAndReset} data-testid={testIds.cancel}>
            {t('common.cancel')}
          </Button>
          <Button loading={loading} type='submit' disabled={!form.getValues().name} data-testid={testIds.submit}>
            {t('common.submit')}
          </Button>
        </Group>
      </Box>
    </ModalBase>
  );
};
