import { useTranslation } from 'react-i18next';
import { InfoLabel, SynonymsInput } from 'components';

interface EventDefSynonymsInputProps {
  value: string[];
  rootClassName?: string;
  onChange: (values: string[]) => void;
}

export const testIds = {
  input: 'event-def-settings-synonyms-input',
  label: 'event-def-settings-synonyms-label',
};

export const EventDefSynonymsInput = ({ value, rootClassName, onChange }: EventDefSynonymsInputProps) => {
  const { t } = useTranslation();

  return (
    <SynonymsInput
      classNames={{ root: rootClassName, section: 'items-start mt-1', label: 'hidden' }}
      inputWrapperOrder={['label', 'input', 'description', 'error']}
      label=''
      leftSection={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.generalSettings.synonyms.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.generalSettings.synonyms.title')}
        </InfoLabel>
      }
      leftSectionWidth={140}
      variant='filled'
      w='100%'
      value={value}
      onChange={onChange}
      data-testid={testIds.input}
    />
  );
};
