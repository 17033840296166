import { Avatar, AvatarProps, ElementProps } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import { computerGroupColor } from '@aiola/frontend';
import { Group } from '@jargonic/customer-types';
import { IconUsers } from '@tabler/icons-react';

interface GroupAvatarProps extends AvatarProps, ElementProps<'div', keyof AvatarProps> {
  group: Group;
}

export const testIds = {
  avatar: 'group-avatar',
  icon: 'group-avatar-icon',
};

export const GroupAvatar = forwardRef<HTMLDivElement, GroupAvatarProps>(({ group, ...props }, ref) => {
  const color = useMemo(() => computerGroupColor(group), [group]);

  return (
    <Avatar ref={ref} color={color} data-testid={testIds.avatar} {...props}>
      <IconUsers data-testid={testIds.icon} />
    </Avatar>
  );
});
