import { nanoid } from 'nanoid';
import i18n from 'services/i18n';
import { ContainerId } from '@jargonic/containers-types';
import {
  ApiReportedEventDefinition,
  EventDefNumericValidation,
  EventDefType,
  EventValueCSVParsedPayload,
  NumericValidationType,
  PartialRangeValidationParams,
  PartialSingleBoundValidationParams,
  ReportedEventDef,
  ReportedEventDefValue,
  ReportedEventDefinitionId,
  ReportedEventsData,
} from './eventDefs.types';
import { Label, UpdateManyLabelsPayload } from '../labels';
import { ContainersData } from '../containers';
import { eventTypeElementMap } from './eventDefs.const';

const sortPredicate = (a: ReportedEventDef, b: ReportedEventDef) => a.order - b.order;

export function filterUserEvents(events: ApiReportedEventDefinition[]) {
  const userEventTypes = Object.values(EventDefType);
  return events.filter((event) => userEventTypes.some((type) => type === event.valueType));
}

function getEnumerateEventName(events: ReportedEventsData, labelId: string): string {
  const labelEvents = Object.values(events).filter((event) => event.labelId === labelId);
  return i18n.t('wizard.steps.reportedEvents.enumeratedNewName', { n: labelEvents.length + 1 });
}

export function getDefaultEventDef(
  events: ReportedEventsData,
  labelId: string,
  parentId?: string,
  containerIds: ContainerId[] = [],
  order = 0,
): ReportedEventDef {
  const parent = events[parentId ?? ''];
  return {
    id: nanoid(),
    name: getEnumerateEventName(events, labelId),
    title: i18n.t('wizard.steps.reportedEvents.newEventName'),
    labelId,
    valueType: EventDefType.APPEND_TEXT_EVENT,
    elementType: eventTypeElementMap[EventDefType.APPEND_TEXT_EVENT],
    synonyms: [],
    decimalPrecision: 0,
    containerIds,
    parentId,
    order,
    mandatory: parent?.mandatory ?? false,
    validated: parent?.validated ?? false,
    filterable: true,
    isMainEvent: false,
  };
}

export function isSelectionType(type: EventDefType): boolean {
  return type === EventDefType.LIST_OF_VALUES_SINGLE_SELECTION || type === EventDefType.LIST_OF_VALUES_MULTI_SELECTION;
}

export function getDefaultEventValue(
  name = i18n.t('wizard.steps.reportedEvents.newEventValueName'),
): ReportedEventDefValue {
  return {
    id: nanoid(),
    name,
    synonyms: [],
  };
}

export function getDefaultSingleBoundParams(): PartialSingleBoundValidationParams {
  return { value: null };
}

export function getDefaultRangeParams(): PartialRangeValidationParams {
  return { min: null, max: null };
}

export function getDefaultValidationParams(type: NumericValidationType) {
  // behold! the stupid limitations of typescript required to correctly infer union types
  type ValParams = Pick<EventDefNumericValidation, 'validationType' | 'expectedDef'>;
  return type === NumericValidationType.IS_BETWEEN || type === NumericValidationType.IS_NOT_BETWEEN
    ? ({ validationType: type, expectedDef: getDefaultRangeParams() } satisfies ValParams)
    : ({ validationType: type, expectedDef: getDefaultSingleBoundParams() } satisfies ValParams);
}

export function getDefaultEventValidation(
  type: NumericValidationType,
  containerIds: ContainerId[],
): EventDefNumericValidation {
  return {
    id: nanoid(),
    name: type,
    containerIds,
    ...getDefaultValidationParams(type),
  };
}

/** Given new type, set the type and reset relevant properties in place */
export function softResetEventDef(eventDef: ReportedEventDef, type: EventDefType): ReportedEventDef {
  const movingBetweenSelectionTypes = isSelectionType(type) && isSelectionType(eventDef.valueType);
  Object.assign(eventDef, {
    valueType: type,
    decimalPrecision: 0,
    default: movingBetweenSelectionTypes ? eventDef.default : undefined,
    elementType: movingBetweenSelectionTypes ? eventDef.elementType : eventTypeElementMap[type],
    filterable: false,
    validations: [],
    values: movingBetweenSelectionTypes ? eventDef.values : [],
    endCommandWords: undefined,
    startCommandWords: undefined,
  } satisfies Partial<ReportedEventDef>);
  return eventDef;
}

export function getLabelEvents(labelId: string, events: ReportedEventsData): ReportedEventDef[] {
  return Object.values(events).filter((event) => event.labelId === labelId);
}

export function getRootEventDefs(eventDefs: ReportedEventsData): ReportedEventDef[] {
  return Object.values(eventDefs)
    .filter((eventDef) => !eventDef.parentId)
    .sort(sortPredicate);
}

export function getSiblingEventDefs(eventDefs: ReportedEventsData, target: ReportedEventDef): ReportedEventDef[] {
  return Object.values(eventDefs)
    .filter((event) => event.parentId === target.parentId && event.labelId === target.labelId)
    .sort(sortPredicate);
}

export function getChildEventDefs(
  eventDefs: ReportedEventsData,
  parentId: ReportedEventDefinitionId,
): ReportedEventDef[] {
  return Object.values(eventDefs)
    .filter((event) => event.parentId === parentId)
    .sort(sortPredicate);
}

export function findMainEvent(eventDefs: ReportedEventsData, labelId: string): ReportedEventDef | undefined {
  return Object.values(eventDefs).find((eventDef) => eventDef.labelId === labelId && eventDef.isMainEvent);
}

function mapLabelIdsToMainEvent(
  labels: Label[],
  eventDefs: ReportedEventsData,
): Map<Label, ReportedEventDef | undefined> {
  return labels.reduce<Map<Label, ReportedEventDef | undefined>>((acc, label) => {
    const mainEvent = findMainEvent(eventDefs, label.id);
    acc.set(label, mainEvent);
    return acc;
  }, new Map());
}

export function getLabelMainEventUpdatePayload(
  labels: Label[],
  eventDefs: ReportedEventsData,
): UpdateManyLabelsPayload {
  const labelsToMainEventsMap = mapLabelIdsToMainEvent(labels, eventDefs);
  const payload: UpdateManyLabelsPayload = [];
  labelsToMainEventsMap.forEach((mainEvent, label) => {
    const mainEventDidChange = label.mainEventId !== mainEvent?.id;
    if (mainEventDidChange) payload.push([label.id, { mainEventId: mainEvent?.id }]);
    else payload.push([label.id, {}]); // TODO remove when upsert/patch can be partial
  });
  return payload;
}

export function getContainerIdsByLabel(containers: ContainersData, labelId: string): ContainerId[] {
  return Object.values(containers)
    .filter((container) => container.typeId === labelId)
    .map((container) => container.id);
}

/** Remove `idsToRemove` from `ids`. Returns the original `ids` if `idsToRemove` is empty. */
export function removeContainerIds(ids: ContainerId[], idsToRemove: ContainerId[]): ContainerId[] {
  if (!idsToRemove.length) return ids;
  const idsToRemoveSet = new Set(idsToRemove);
  return ids.filter((id) => !idsToRemoveSet.has(id));
}

/** Add `idsToAdd` to `ids`. Returns the original `ids` if `idsToAdd` is empty. */
export function addContainerIds(ids: ContainerId[], idsToAdd: ContainerId[]): ContainerId[] {
  if (!idsToAdd.length) return ids;
  return [...new Set([...ids, ...idsToAdd])];
}

enum ValidityStringValues {
  TRUE = '1',
  FALSE = '0',
}

function getIsValidFromCSVPayload(value?: string) {
  if (value === ValidityStringValues.TRUE) return true;
  if (value === ValidityStringValues.FALSE) return false;
  return undefined;
}

function getSynonymsFromCSVPayload(value?: string) {
  return value ? value.split(',') : [];
}

export function getEventValuesFromCSVPayload(payload: EventValueCSVParsedPayload[]): ReportedEventDefValue[] {
  return payload
    .filter(({ name }) => Boolean(name))
    .map(({ name, synonyms, valid }) => ({
      id: nanoid(),
      name: name!,
      synonyms: getSynonymsFromCSVPayload(synonyms),
      valid: getIsValidFromCSVPayload(valid),
    }));
}

export function updateEventDefContainers(eventDef: ReportedEventDef, containerIds: string[]) {
  eventDef.containerIds = containerIds;
  eventDef.validations?.forEach((validation) => {
    validation.containerIds = validation.containerIds.filter((id) => containerIds.includes(id));
  });
}
