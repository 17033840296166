import { Flex, NumberInput } from '@mantine/core';
import { parseNumber } from 'utils';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface NumberRangeInputProps {
  max: number | null;
  min: number | null;
  onChange: (min: number | null, max: number | null) => void;
}

export const testIds = {
  minInput: 'number-range-input-min',
  maxInput: 'number-range-input-max',
};

export const NumberRangeInput = ({ max, min, onChange }: NumberRangeInputProps) => {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);

  const onChangeMin = (value: string | number) => {
    const newValue = parseNumber(value, false);
    onChange(newValue ?? null, max);
    setDirty(true);
  };

  const onChangeMax = (value: string | number) => {
    const newValue = parseNumber(value, false);
    onChange(min, newValue ?? null);
    setDirty(true);
  };

  const isMinNull = min === null;
  const isMaxNull = max === null;

  return (
    <Flex gap='sm'>
      <NumberInput
        allowDecimal
        classNames={{ root: 'flex items-center gap-1 grow', wrapper: 'grow', label: 'shrink-0' }}
        error={dirty && isMinNull}
        labelProps={{ c: 'gray.5' }}
        label={t('common.from')}
        max={max ?? undefined}
        value={min ?? undefined}
        onChange={onChangeMin}
        data-testid={testIds.minInput}
      />
      <NumberInput
        allowDecimal
        classNames={{ root: 'flex items-center gap-1 grow', wrapper: 'grow', label: 'shrink-0' }}
        error={dirty && isMaxNull}
        labelProps={{ c: 'gray.5' }}
        label={t('common.to')}
        min={min ?? undefined}
        value={max ?? undefined}
        onChange={onChangeMax}
        data-testid={testIds.maxInput}
      />
    </Flex>
  );
};
