import { NumberInput } from '@mantine/core';
import { useState } from 'react';
import { parseNumber } from 'utils';

interface NumberSingleInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
}
export const testIds = {
  input: 'number-single-input',
};

export const NumberSingleInput = ({ value, onChange }: NumberSingleInputProps) => {
  const [dirty, setDirty] = useState(false);

  const onChangeValue = (newValue: string | number) => {
    const parsedValue = parseNumber(newValue, false);
    onChange(parsedValue ?? null);
    setDirty(true);
  };

  const isValueNull = value === null;

  return (
    <NumberInput
      allowDecimal
      error={dirty && isValueNull}
      value={value ?? undefined}
      onChange={onChangeValue}
      data-testid={testIds.input}
    />
  );
};
