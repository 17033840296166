import { Outlet, useParams } from 'react-router-dom';
import { Box, Center, Loader } from '@mantine/core';
import { useUserMgmtStore } from 'stores/userMgmt';
import { useEffect, useMemo } from 'react';
import { CustomerRouteParams } from 'routes/routes.config';
import { useFlowStore } from 'stores/flows';
import { UserMgmtHeader, testIds as headerTestIds } from './UserMgmtHeader';
import { UserMgmtNavbar, testIds as navbarTestIds } from './UserMgmtNavbar';
import classes from './UserMgmtLayout.module.css';

export const testIds = {
  main: 'user-mgmt-main',
  loader: 'user-mgmt-loader',
  header: headerTestIds,
  navbar: navbarTestIds,
};

export const UserMgmtLayout = () => {
  const { customerId } = useParams() as CustomerRouteParams;
  const {
    fetchUsers,
    fetchPolicies,
    fetchGroups,
    reset,
    users,
    policies,
    groups,
    loadingUsers,
    fetchedUsers,
    loadingPolicies,
    fetchedPolicies,
  } = useUserMgmtStore([
    'fetchUsers',
    'fetchPolicies',
    'fetchGroups',
    'reset',
    'users',
    'policies',
    'groups',
    'loadingUsers',
    'fetchedUsers',
    'loadingPolicies',
    'fetchedPolicies',
  ]);
  const { fetchFlows, reset: resetFlows } = useFlowStore(['fetchFlows', 'reset']);

  const userCount = useMemo(() => Object.keys(users).length, [users]);
  const policyCount = useMemo(() => Object.keys(policies).length, [policies]);
  const groupCount = useMemo(() => Object.keys(groups).length, [groups]);
  const loading = loadingUsers || !fetchedUsers || loadingPolicies || !fetchedPolicies;

  useEffect(() => {
    fetchUsers(customerId);
    fetchPolicies(customerId);
    fetchGroups(customerId);
    fetchFlows(customerId);

    return () => {
      reset();
      resetFlows();
    };
  }, [customerId]);

  return (
    <Box className={classes.wrapper}>
      <UserMgmtHeader />
      <UserMgmtNavbar groupCount={groupCount} policyCount={policyCount} userCount={userCount} />
      <Box component='main' className={classes.main} data-testid={testIds.main}>
        {loading ? (
          <Center h='100%'>
            <Loader type='bars' data-testid={testIds.loader} />
          </Center>
        ) : (
          <Outlet />
        )}
      </Box>
    </Box>
  );
};
