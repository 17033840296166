import i18n from 'services/i18n';
import { ValidationOopsie } from '../wizard.types';
import { Container, ContainersData } from './containers.types';

/**
 * Checks for containers that are missing names and returns validation errors.
 * @param {Container[]} containers - The list of container objects to be validated.
 * @returns {ValidationOopsie[]} An array of validation errors related to missing names.
 */
export function validateNoMissingContainerNames(containers: Container[]): ValidationOopsie[] {
  const containersWithNoName = containers.filter((container) => !container.name);
  const count = containersWithNoName.length;
  return count ? [{ text: i18n.t('wizard.steps.labeling.validations.containerMissingName', { count }) }] : [];
}

/**
 * Validates that container names are unique within each parent grouping.
 * @param {Container[]} containers - The list of container objects to be validated.
 * @returns {ValidationOopsie[]} An array of validation errors related to duplicate container names.
 */
export function validateNoDuplicateContainerNames(containers: Container[]): ValidationOopsie[] {
  const errors: ValidationOopsie[] = [];
  const nameMap = new Map<string, Set<string>>();

  containers.forEach((container) => {
    const key = `${container.parentId}`;
    if (!nameMap.has(key)) {
      nameMap.set(key, new Set());
    }
    const namesSet = nameMap.get(key);

    const nameLower = container.name.toLowerCase();

    if (namesSet?.has(nameLower)) {
      const parent = containers.filter((con) => con.id === container.parentId)[0];
      errors.push({
        text: i18n.t('wizard.steps.labeling.validations.duplicateNameInLabel', {
          name: container.name,
          parentName: parent?.name ?? i18n.t('common.main'),
        }),
      });
    } else {
      namesSet?.add(nameLower);
    }
  });

  return errors;
}

/**
 * Maps container names and synonyms by their parent ID for further validation.
 * @param {Container[]} containers - The list of containers to map.
 * @returns {Map<string, Map<string, Set<string>>>} A map of parent IDs to another map of names/synonyms to sets of container names.
 */
export function mapNamesAndSynonymsByParentGroup(containers: Container[]): Map<string, Map<string, Set<string>>> {
  const map = new Map<string, Map<string, Set<string>>>();

  containers.forEach((container) => {
    const key = `${container.parentId}`;
    if (!map.has(key)) {
      map.set(key, new Map());
    }
    const nameSynonymMap = map.get(key);

    const names = [container.name.toLowerCase(), ...(container.synonyms?.map((syn) => syn.toLowerCase()) || [])];
    names.forEach((name) => {
      if (!nameSynonymMap?.has(name)) {
        nameSynonymMap?.set(name, new Set());
      }
      nameSynonymMap?.get(name)?.add(container.name);
    });
  });

  return map;
}

/**
 * Validates the uniqueness of both container names and synonyms, ensuring no overlaps within the same parent group. It not validates the uniqueness of container names, for that use validateNoDuplicateContainerNames.
 * @param {Container[]} containers - The list of containers to validate.
 * @returns {ValidationOopsie[]} An array of validation errors related to duplicate names and synonyms.
 */
export function validateNameAndSynonymWithinParentGroup(containers: Container[]): ValidationOopsie[] {
  const errors: ValidationOopsie[] = [];
  const nameAndSynonymMap = mapNamesAndSynonymsByParentGroup(containers);

  nameAndSynonymMap.forEach((containerMap) => {
    containerMap.forEach((containersUsing, nameOrSynonym) => {
      if (containersUsing.size > 1) {
        const localContainersUsing = Array.from(containersUsing).join(', ');
        errors.push({
          text: i18n.t('wizard.steps.labeling.validations.duplicateSynonymOrNamesAcrossContainers', {
            nameOrSynonym,
            containersUsing: localContainersUsing,
          }),
        });
      }
    });
  });

  return errors;
}

/**
 * Validates name and synonym conflicts specifically between a container and its uncles (parent's siblings).
 * @param {Container[]} containers - The list of containers to validate.
 * @returns {ValidationOopsie[]} An array of validation errors related to name and synonym conflicts between uncles and nephews.
 */
export function validateNameAndSynonymCrossHierarchy(containers: Container[]): ValidationOopsie[] {
  const errors: ValidationOopsie[] = [];

  containers.forEach((container) => {
    if (!container.parentId) return;

    const parentContainer = containers.find((con) => con.id === container.parentId);
    if (!parentContainer) return;

    const uncleContainers = containers.filter(
      (con) => con.parentId === parentContainer.parentId && con.id !== container.parentId,
    );

    uncleContainers.forEach((uncle) => {
      // Name to Name Check between uncle and current container
      if (uncle.name.toLowerCase() === container.name.toLowerCase()) {
        errors.push({
          text: i18n.t('wizard.steps.labeling.validations.duplicateNameInLabel', {
            name: container.name,
            parentName: uncle.name,
          }),
        });
      }

      // Synonym to Name Check
      uncle.synonyms?.forEach((syn) => {
        if (syn.toLowerCase() === container.name.toLowerCase()) {
          errors.push({
            text: i18n.t('wizard.steps.labeling.validations.duplicateSynonymOrNamesAcrossContainers', {
              nameOrSynonym: syn,
              containersUsing: `${uncle.name}, ${container.name}`,
            }),
          });
        }
      });

      // Name to Synonym Check
      container.synonyms?.forEach((syn) => {
        if (syn.toLowerCase() === uncle.name.toLowerCase()) {
          errors.push({
            text: i18n.t('wizard.steps.labeling.validations.duplicateSynonymOrNamesAcrossContainers', {
              nameOrSynonym: syn,
              containersUsing: `${container.name}, ${uncle.name}`,
            }),
          });
        }
      });

      // Synonym to Synonym Check between uncle and current container
      container.synonyms?.forEach((containerSynonym) => {
        uncle.synonyms?.forEach((uncleSynonym) => {
          if (containerSynonym.toLowerCase() === uncleSynonym.toLowerCase()) {
            errors.push({
              text: i18n.t('wizard.steps.labeling.validations.duplicateSynonymOrNamesAcrossContainers', {
                nameOrSynonym: containerSynonym,
                containersUsing: `${container.name}, ${uncle.name}`,
              }),
            });
          }
        });
      });
    });
  });

  return errors;
}

export function validateContainers(data: ContainersData): ValidationOopsie[] {
  const containerArray = Object.values(data);

  return [
    ...validateNoMissingContainerNames(containerArray),
    ...validateNoDuplicateContainerNames(containerArray),
    ...validateNameAndSynonymWithinParentGroup(containerArray),
    ...validateNameAndSynonymCrossHierarchy(containerArray), // UncleNephewRelationships
  ];
}
