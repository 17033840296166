import { FocusEventHandler } from 'react';
import { Box, InputDescription } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SynonymsInput } from 'components';
import { timeStringToMinutes } from 'utils';

const TIME_FORMAT_WITHOUT_COLON = /^\d{4}$/;
const TIME_FORMAT_WITH_COLON = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const convertTODtoMinutes = (values: string[]) =>
  values.map(timeStringToMinutes).filter((time) => time !== undefined);

interface TimeMultipleInputProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  onBlur: (valuesInMinutes: number[]) => void;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
}

export const TimeMultipleInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholder = '',
  description = '',
  ...props
}: TimeMultipleInputProps) => {
  const { t } = useTranslation();

  const handleOnChangeTOD = (newTODvalues: string[]) => {
    let latestValueAdded = newTODvalues.at(-1) ?? '';

    if (TIME_FORMAT_WITHOUT_COLON.test(latestValueAdded)) {
      latestValueAdded = `${latestValueAdded.slice(0, 2)}:${latestValueAdded.slice(2)}`;
    }

    const isValidTime = TIME_FORMAT_WITH_COLON.test(latestValueAdded);
    const isDuplicate = value.includes(latestValueAdded);

    if (isValidTime && !isDuplicate) {
      onChange([...newTODvalues.slice(0, -1), latestValueAdded]);
    } else {
      onChange(newTODvalues.slice(0, -1));
    }
  };

  const handleOnBlurTOD: FocusEventHandler<HTMLInputElement> = () => {
    const minutesValues = convertTODtoMinutes(value);
    onBlur(minutesValues);
  };

  return (
    <Box>
      <SynonymsInput
        w='90%'
        variant='filled'
        disabled={disabled}
        withLabel={false}
        description={description}
        placeholder={placeholder ?? t('common.enterValue')}
        allowDuplicates
        value={value}
        onChange={handleOnChangeTOD}
        onBlur={handleOnBlurTOD}
        {...props}
      />
      <InputDescription size='md' mt={5}>
        {t('wizard.steps.settings.features.expiration.manyValues')}
      </InputDescription>
    </Box>
  );
};
