import { Stack } from '@mantine/core';
import { Container, EvenDefValidationId, EventDefNumericValidation, EventDefType } from 'stores/wizard';
import { ValidationItem } from './ValidationItem';

interface ValidationListProps {
  containerPool: Container[];
  rootClassName?: string;
  type?: EventDefType.NUMBER | EventDefType.DATE | EventDefType.TIME_OF_DAY;
  validations: EventDefNumericValidation[];
  onChange: (validationId: EvenDefValidationId, values: Partial<EventDefNumericValidation>) => void;
  onDelete: (validationId: EvenDefValidationId) => void;
}

export const testIds = {
  list: 'validation-list',
};

export const ValidationList = ({
  containerPool,
  rootClassName,
  type = EventDefType.NUMBER,
  validations,
  onChange,
  onDelete,
}: ValidationListProps) => {
  const handleChange = (validationId: EvenDefValidationId) => (values: Partial<EventDefNumericValidation>) =>
    onChange(validationId, values);
  const handleDelete = (validationId: EvenDefValidationId) => () => onDelete(validationId);

  return (
    <Stack classNames={{ root: rootClassName }} gap='tiny' data-testid={testIds.list}>
      {validations.map((validation) => (
        <ValidationItem
          key={validation.id}
          containerPool={containerPool}
          type={type}
          validation={validation}
          onChange={handleChange(validation.id)}
          onDelete={handleDelete(validation.id)}
        />
      ))}
    </Stack>
  );
};
