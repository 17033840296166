import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { WizardStoreActions, WizardStoreState } from '../wizard.types';
import { Container, ContainersData, MovePosition } from './containers.types';
import { containersApi } from './containers.api';
import { CONTAINER_SESSION_STORAGE_KEY } from './containers.const';
import {
  applyRecursiveDeletion,
  findIsDescendant,
  getRootContainers,
  getInsertionIndex,
  getRootInsertionIndex,
  findNextIndex,
  generateContainers,
} from './containers.utils';
import { dataFromApi } from './containers.adapters';
import { createWizardStoreSlice } from '../wizard.slice';
import { validateContainers } from './containers.validation';

interface ContainerState extends WizardStoreState<ContainersData> {}

type CreateArgs = { names: string[]; flowId: string; labelId: string; parentId?: string };
interface ContainerActions extends WizardStoreActions {
  createContainers: (args: CreateArgs) => Container[];
  updateContainer: (containerId: string, values: Partial<Container>) => void;
  repositionContainer: (containerId: string, targetContainerId: string, position: MovePosition) => boolean;
  deleteContainers: (containerIds: string[]) => void;
  internal: {
    detachFromParent: (containerId: string) => void;
    attachToParent: (containerId: string, parentId: string, index: number) => void;
    attachToRoot: (containerId: string, index: number) => void;
    reorderContainers: (orderedIds: string[]) => void;
  };
}

export const containerStore = create(
  immer<ContainerState & ContainerActions>((set, get, ...args) => ({
    ...createWizardStoreSlice<ContainersData>({
      initialData: {},
      sessionStorageKey: CONTAINER_SESSION_STORAGE_KEY,
      fetchData: async (customerId, flowId) => {
        const containers = await containersApi.getContainers(customerId, flowId);
        return containers && dataFromApi(containers);
      },
      saveData: async (customerId, flowId, data) => {
        const response = await containersApi.replaceContainers(customerId, flowId, Object.values(data));
        return Boolean(response);
      },
      validateData: (data) => validateContainers(data),
      sliceArgs: [set, get, ...args],
    }),
    createContainers: ({ names, labelId, parentId }) => {
      const containers = get().data;
      const startingIndex = findNextIndex(containers, parentId);
      const newContainers = generateContainers(names, labelId, startingIndex, parentId);

      set((state) => {
        newContainers.forEach((container) => {
          state.data[container.id] = container;
        });
        const parent = state.data[parentId!];
        if (parent) {
          parent.childrenIds.push(...newContainers.map((container) => container.id));
          // TODO replace with internal refresh order action
          parent.childrenIds.forEach((id, index) => {
            state.data[id].order = index;
          });
        }
        state.dirty = true;
      });
      return newContainers;
    },
    updateContainer: (containerId, values) => {
      set((state) => {
        const container = state.data[containerId];
        Object.assign(container, values);
        state.dirty = true;
      });
    },
    repositionContainer: (movingContainerId, targetContainerId, position) => {
      const { data, internal } = get();
      const isDescendant = findIsDescendant(data, targetContainerId, movingContainerId);
      if (isDescendant) return false;

      internal.detachFromParent(movingContainerId);
      const targetContainer = data[targetContainerId];
      const targetSibling = position === 'inside' ? data[targetContainer.childrenIds.at(-1)!] : targetContainer;
      const targetParent = position === 'inside' ? targetContainer : data[targetSibling!.parentId!];

      const shouldBeRoot = !targetSibling?.parentId && position !== 'inside';

      if (shouldBeRoot) {
        const targetIndex = getRootInsertionIndex(data, position, targetSibling?.id);
        internal.attachToRoot(movingContainerId, targetIndex);
      } else {
        const targetIndex = getInsertionIndex(targetParent!, position, targetSibling?.id);
        internal.attachToParent(movingContainerId, targetParent!.id, targetIndex);
      }
      set({ dirty: true });
      return true;
    },
    deleteContainers: (containerIds) => {
      set({ data: applyRecursiveDeletion(structuredClone(get().data), containerIds), dirty: true });
    },
    internal: {
      detachFromParent: (containerId) => {
        const container = get().data[containerId];
        if (!container.parentId) return;
        const updatedChildren = get().data[container.parentId].childrenIds.filter((id) => id !== containerId);
        set((state) => {
          state.data[container.parentId!].childrenIds = updatedChildren;
        });
        get().internal.reorderContainers(get().data[container.parentId].childrenIds);
      },
      attachToParent: (containerId, parentId, index) => {
        set((state) => {
          state.data[parentId].childrenIds.splice(index, 0, containerId);
          state.data[containerId].parentId = parentId;
        });
        const { data, internal } = get();
        internal.reorderContainers(data[parentId].childrenIds);
      },
      attachToRoot: (containerId, index) => {
        const { data, internal } = get();
        const rootContainers = getRootContainers(data);

        // if target is already a root container, remove it from the list
        const existingRootContainerIndex = rootContainers.findIndex((child) => child.id === containerId);
        if (existingRootContainerIndex !== -1) rootContainers.splice(existingRootContainerIndex, 1);

        rootContainers.splice(index, 0, get().data[containerId]);
        set((state) => {
          state.data[containerId].parentId = undefined;
        });

        internal.reorderContainers(rootContainers.map((child) => child.id));
      },
      reorderContainers: (orderedIds) => {
        set((state) => {
          orderedIds.forEach((id, i) => {
            state.data[id].order = i;
          });
        });
      },
    },
  })),
);

export const useContainerStore = createStoreHook({ store: containerStore, useShallow });
