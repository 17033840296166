import { Badge, Card, Flex, Input, LoadingOverlay } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { ActionButton, Stick, SynonymsInput } from 'components';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'stores/wizard';

interface EditableDynamicLabelProps {
  label: Label;
  onDelete: () => Promise<void>;
  onEdit: (label: Partial<Label>) => Promise<boolean>;
}

export const testIds = {
  getCardTestId: (labelId: string) => `editable-dynamic-label-card-${labelId}`,
  loader: 'editable-dynamic-label-loader',
  nameInput: 'editable-dynamic-label-name-input',
  badge: 'editable-dynamic-label-badge',
  deleteButton: {
    button: 'editable-dynamic-label-delete-button',
    icon: 'editable-dynamic-label-delete-icon',
  },
  synonyms: 'editable-dynamic-label-synonyms',
};

export const EditableDynamicLabel = ({ label, onDelete, onEdit }: EditableDynamicLabelProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState(label.name);
  const [synonyms, setSynonyms] = useState<string[]>(label.synonyms);
  const [editing, setEditing] = useState(false);

  const onClickDelete = async () => {
    setEditing(true);
    await onDelete();
    setEditing(false);
  };

  const onChangeName: ChangeEventHandler<HTMLInputElement> = (event) => setName(event.target.value);

  const onBlurNameInput = async () => {
    setEditing(true);
    const response = await onEdit({ name });
    if (!response) setName(label.name);
    setEditing(false);
  };

  const onChangeSynonyms = (newSynonyms: string[]) => setSynonyms(newSynonyms);

  const onBlurSynonyms = async () => {
    setEditing(true);
    await onEdit({ synonyms });
    setEditing(false);
  };

  return (
    <Card p='tn' data-testid={testIds.getCardTestId(label.id)}>
      <Flex align='center' gap='sm' mb='md'>
        <Stick color={label.attributes.color} />
        <Input
          className='grow'
          value={name}
          variant='unstyled'
          onChange={onChangeName}
          onBlur={onBlurNameInput}
          data-testid={testIds.nameInput}
        />
        <Badge color='gray' size='sm' variant='light' data-testid={testIds.badge}>
          {t('common.dynamic')}
        </Badge>
        <ActionButton label={t('common.delete')} onClick={onClickDelete} data-testid={testIds.deleteButton.button}>
          <IconTrash height={18} data-testid={testIds.deleteButton.icon} />
        </ActionButton>
      </Flex>
      <SynonymsInput
        value={synonyms}
        variant='filled'
        onBlur={onBlurSynonyms}
        onChange={onChangeSynonyms}
        data-testid={testIds.synonyms}
      />
      <LoadingOverlay pos='absolute' visible={editing} data-testid={testIds.loader} />
    </Card>
  );
};
