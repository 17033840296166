import { MRT_TableOptions } from 'mantine-react-table';
import { ReportedEventDef } from 'stores/wizard';
import { testIds } from './EventDefTable.testIds';
import classes from '../EventDefTable.module.css';

export const tableStaticDefs = {
  getRowId: (eventDef) => eventDef.id,
  enablePagination: false,
  enableRowOrdering: true,
  enableExpanding: true,
  enableRowSelection: true,
  mantineExpandButtonProps: ({ row }) => ({
    opacity: row.subRows?.length === 0 ? 0 : 1,
  }),
  enableColumnActions: false,
  enableBottomToolbar: false,
  enableTableHead: false,
  enableSorting: false,
  enableExpandAll: false,
  layoutMode: 'grid',
  mantinePaperProps: { pos: 'relative', h: '100%', w: '100%', shadow: 'zero', withBorder: false, radius: 0 },
  mantineTableContainerProps: {
    className: classes.container,
    pos: 'absolute',
    top: 'cal(var(--mrt-top-toolbar-height) * 1px)',
    left: 0,
    right: 0,
    mah: 'calc(100% - var(--mrt-top-toolbar-height) * 1px)',
    w: '100%',
  },
  mantineTableProps: {
    horizontalSpacing: 'tn',
    verticalSpacing: 'tn',
    w: '100%',
    // @ts-ignore
    'data-testid': testIds.table,
  },
  displayColumnDefOptions: {
    'mrt-row-drag': { header: '', size: 0, minSize: 25, mantineTableBodyCellProps: { pr: 0, pl: 'teeny' } },
    'mrt-row-expand': {
      header: '',
      size: 28,
      minSize: 28,
      mantineTableBodyCellProps: {
        px: 0,
        // @ts-ignore
        'data-testid': testIds.expandCell,
      },
    },
  },
} satisfies Partial<MRT_TableOptions<ReportedEventDef>>;
