import { DatePickerInput, DateValue } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { useState } from 'react';

interface DateSingleInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
}
export const testIds = {
  input: 'date-single-input',
};

export const DateSingleInput = ({ value, onChange }: DateSingleInputProps) => {
  const [dirty, setDirty] = useState(false);

  const onChangeValue = (newValue: DateValue) => {
    onChange(newValue ? newValue.getTime() : null);
    setDirty(true);
  };

  const isValueNull = value === null;
  const date = isValueNull ? null : new Date(value);

  return (
    <DatePickerInput
      error={dirty && isValueNull}
      value={date}
      leftSection={<IconCalendar />}
      onChange={onChangeValue}
      data-testid={testIds.input}
    />
  );
};
