import { CustomerId } from 'stores/customers';
import { parseCSV } from 'utils';
import { userMgmtApi } from './users.api';
import {
  UpsertUserArgs,
  UserMgmtSlice,
  UsersParsedCSVPayload,
  UsersSliceActions,
  UsersSliceState,
} from '../userMgmt.slices.types';
import { UserCSVColumnName, UserMgmtCSVResponse } from '../../userMgmt.types';
import { assertCSVColumns, getUsersFromCSVPayload } from '../../userMmgt.utils';
import { createUserToApi } from './users.adapters';

export const usersInitialState: UsersSliceState = {
  fetchedUsers: false,
  loadingUsers: false,
  users: {},
};

async function upsertUser(customerId: CustomerId, { action, userId, payload }: UpsertUserArgs) {
  switch (action) {
    case 'create': {
      const [user] = (await userMgmtApi.createUsers(customerId, createUserToApi([payload]))) ?? [];
      return user;
    }
    case 'update':
      return userMgmtApi.updateUser(customerId, userId, payload);
    default:
      return undefined;
  }
}

export const usersSlice: UserMgmtSlice<UsersSliceState, UsersSliceActions> = (set, get) => ({
  ...usersInitialState,
  fetchUsers: async (customerId) => {
    if (get().fetchedUsers) return true;
    set({ loadingUsers: true });
    const users = await userMgmtApi.getUsers(customerId);
    if (users) {
      const usersMap = users.reduce((acc, user) => ({ ...acc, [user.userId]: user }), {});
      set({ users: usersMap });
    }
    set({ loadingUsers: false, fetchedUsers: true });
    return !!users;
  },
  upsertUser: async (customerId, payload) => {
    const user = await upsertUser(customerId, payload);
    if (user)
      set(({ users }) => {
        users[user.userId] = user;
      });
    return user;
  },
  createUsersFromFile: async (customerId, file) => {
    try {
      const parsed = await parseCSV<UsersParsedCSVPayload>(file);
      if (!assertCSVColumns(UserCSVColumnName, parsed.meta.fields)) return UserMgmtCSVResponse.MISSING_COLUMNS;

      const newUsers = getUsersFromCSVPayload(parsed.data);
      const existingEmailSet = new Set(Object.values(get().users).map((user) => user.email));
      const usersToCreate = newUsers.filter((user) => !existingEmailSet.has(user.email));
      const createdUsers = await userMgmtApi.createUsers(customerId, createUserToApi(usersToCreate));
      if (!createdUsers) return UserMgmtCSVResponse.API_ERROR;

      const usersMap = createdUsers.reduce((acc, user) => ({ ...acc, [user.userId]: user }), {});
      set((state) => {
        state.users = { ...state.users, ...usersMap };
      });
      const hasDuplicateUsers = newUsers.length !== usersToCreate.length;
      return hasDuplicateUsers ? UserMgmtCSVResponse.DUPLICATE_VALUES : UserMgmtCSVResponse.OK;
    } catch {
      return UserMgmtCSVResponse.CSV_ERROR;
    }
  },
  deleteUser: async (customerId, userId) => {
    const success = await userMgmtApi.deleteUser(customerId, userId);
    if (success)
      set(({ users }) => {
        delete users[userId];
      });
    return success;
  },
});
